import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useListenChattingMessage from '~/pages/message/chat/hooks/useListenChattingMessage.ts';
import useKintStore from '~kint/store';
import useSafeArea from '~/hooks/useSafeArea.ts';
import Page from '~/components/Page';
import IconButton from '~/components/IconButton.tsx';
import Typography from '~/components/Typography.tsx';
import Message from './components/Message';
import MessageForm from '~/pages/message/chat/components/MessageForm.tsx';
import readMessages from '~kint/controllers/readMessages.ts';
import RequestChatNotificationPermissionDialog from '~/pages/message/chat/components/NotificationPermissionDialog.tsx';
import { ChattingContextProvider } from '~/pages/message/contexts/ChattingContext.tsx';
import dayjs from 'dayjs';
import Box from '~/components/Box';
import Divider from '~/components/Divider.tsx';
import PromotionBanner from '~/pages/message/chat/components/PromotionBanner.tsx';
import Logo from '~/components/Logo';
// import {useTranslation} from "react-i18next";

export default function MessagesChatPage() {
  const { chattingId } = useParams() as { chattingId: string };
  const { participant } = useKintStore((state) => ({ participant: state.participant }));
  // const {i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const fromEntry = location?.state?.fromEntry;
  const messagesRef = useRef<HTMLDivElement>(null);
  const [chatLanguage, setChatLanguage] = useState<'en' | 'cn' | 'tw' | null>(null); // chat language 에는 언어 미선택이 있을 수 있음

  const handleTranslate = () => {
    if (!chatLanguage) {
      setChatLanguage('en');
      return;
    }
    if (chatLanguage === 'en') {
      setChatLanguage('cn');
      return;
    }
    if (chatLanguage === 'cn') {
      setChatLanguage('tw');
      return;
    }
    if (chatLanguage === 'tw') {
      setChatLanguage(null);
      return;
    }
  };

  const { messages, chatting, loading, initialized } = useListenChattingMessage(
    chattingId,
    (error) => {
      switch (error) {
        case 'ACCESS EXPIRED':
        case 'INVALID RESERVATION':
        case 'UNEXPECTED ERROR':
          alert('Please check your reservation.');
          navigate('/reservation');
      }
    }
  );

  const { viewportHeight } = useSafeArea();

  useEffect(() => {
    const scrollEl = messagesRef.current;
    if (!scrollEl || messages.length <= 2) return;
    // if (fromEntry) return;  최초 진입시에 채팅 스크롤 안하기 - 입력시에도 안되는 문제 있어
    window.scrollTo(0, scrollEl.scrollHeight);
    const images = scrollEl.querySelectorAll<HTMLImageElement>('.MuiImageList-root li img');
    if (images.length) {
      images[images.length - 1].loading = 'eager';
      images[images.length - 1].addEventListener('load', () => {
        window.scrollTo(0, scrollEl.scrollHeight);
      });
    }
  }, [messages.length, chattingId, participant?.id]);

  useEffect(() => {
    const updateRead = () => {
      if (participant && chattingId) {
        readMessages(chattingId, participant.id).catch(console.error);
      }
    };
    updateRead();
    return () => {
      updateRead(); // 페이지 나갈 떄 처리
    };
  }, [participant, chattingId]);

  if (!initialized || loading) return null;
  if (!chatting) return null;

  return (
    <ChattingContextProvider>
      <Page
        type={'page'}
        backgroundColor={'paper'}
        navigationBarProps={{
          lead: (
            <IconButton
              badge={fromEntry}
              touchArea={'16px'}
              iconProps={{ width: '20px' }}
              sx={{
                animation:
                  location.key === 'default' || fromEntry
                    ? '1s infinite color-change-primary'
                    : 'none',
              }}
              color={'typography.tertiary'}
              onClick={() => {
                if (location.key === 'default' || fromEntry) {
                  navigate('/');
                } else {
                  navigate(-1);
                }
              }}
            >
              {location.key === 'default' || fromEntry ? 'home' : 'back'}
            </IconButton>
          ),
          center: (
            <Link
              to={'/message'}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Logo color={'typography.tertiary'} height={'20px'}>
                chat
              </Logo>
            </Link>
          ),
          trail: (
            <IconButton
              touchArea={'16px'}
              iconProps={{
                width: '20px',
              }}
              color={'typography.secondary'}
              onClick={handleTranslate}
            >
              {chatLanguage === 'en'
                ? 'english'
                : chatLanguage === 'tw'
                  ? 'chinese_traditional'
                  : chatLanguage === 'cn'
                    ? 'chinese_simplified'
                    : 'translate'}
            </IconButton>
          ),
        }}
        fixedBottomPosition={'sticky'}
        fixedBottom={<MessageForm chatting={chatting} />}
      >
        <PromotionBanner timeout={10} beginAt={new Date()} />
        <div
          ref={messagesRef}
          style={{
            minHeight:
              (messagesRef?.current?.scrollHeight ?? 0) < viewportHeight
                ? viewportHeight - 96
                : undefined,
          }}
        >
          {messages
            .filter((m) => {
              const messageType = m.type.toLowerCase();
              return !(
                messageType === 'enter' ||
                messageType === 'exit' ||
                messageType === 'date' ||
                messageType === 'system'
              );
            })
            .map((m, idx, messages) => {
              const mine = m.sender.id === participant?.id;
              const prevMessage = messages[idx - 1];
              const currentMessageDate = dayjs(m.date.toDate()).format('YY-MM-DD');
              const prevMessageDate = dayjs(prevMessage?.date?.toDate() ?? new Date()).format(
                'YY-MM-DD'
              );
              const showDate = currentMessageDate !== prevMessageDate;
              if (showDate)
                return (
                  <>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      px={'20px'}
                      pt={idx === 0 ? '24px' : '20px'}
                      pb={'10px'}
                      gap={'20px'}
                    >
                      <Box sx={{ flex: 1, width: '100%' }}>
                        <Divider type={'line'} />
                      </Box>
                      <Typography color={'typography.tertiary'}>
                        {dayjs(m.date.toDate()).format('MMM DD')}
                      </Typography>
                      <Box sx={{ flex: 1, width: '100%' }}>
                        <Divider type={'line'} />
                      </Box>
                    </Box>
                    <Message key={m.id} message={m} mine={mine} language={chatLanguage} />
                  </>
                );
              return <Message key={m.id} message={m} mine={mine} language={chatLanguage} />;
            })}
        </div>
      </Page>
      <RequestChatNotificationPermissionDialog />
    </ChattingContextProvider>
  );
}
