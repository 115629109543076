const removeAt = <T>(
  prev: T[],
  indexOrFinder: number | ((exists: T, index: number) => boolean)
): T[] => {
  const deleteIndex =
    typeof indexOrFinder === 'number'
      ? indexOrFinder
      : prev.findIndex((p, i) => indexOrFinder(p, i));
  return [...prev.slice(0, deleteIndex), ...prev.slice(deleteIndex + 1)];
};

export default removeAt;
