import axios from 'axios';
import { getCookie } from '~common/api/cookies.ts';

const api = axios.create({
  baseURL: import.meta.env.VITE_KUP_REST_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getCookie('Authorization');
    const locale = getCookie('Kup-Locale');
    if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
    if (locale) config.headers['Kup-Locale'] = locale;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
