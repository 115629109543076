import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // 필요한 스타일 임포트
import { default as MuiBox } from '@mui/material/Box';
import ImageView from '~/components/ImageView.tsx';

type CarouselProps = {
  images: string[];
  height?: string;
  interval?: number;
};

const CarouselComponent: React.FC<CarouselProps> = (props) => {
  const { images, height = '375px', interval = 3000 } = props;
  return (
    <MuiBox>
      <Carousel
        infiniteLoop
        swipeable
        autoPlay
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        interval={interval}
      >
        {images.map((url) => (
          <ImageView key={url} src={url} borderRadius={'0px'} width={'100%'} height={height} />
        ))}
      </Carousel>
    </MuiBox>
  );
};

export default CarouselComponent;
