import { useKeepState } from '~/hooks/useKeepState.ts';
import { DiscoveryCategory, DiscoverySimple } from '~kup/models/Discovery.ts';
import useDiscoveryCategories from '~/pages/discovery/hooks/useDiscoveryCategories.ts';
import useDiscoveries from '~/pages/discovery/hooks/useDiscovries.ts';

import Page from '~/components/Page';
import Container from '~/components/Container.tsx';
import Tabs from '~/components/Tabs.tsx';
import Box from '~/components/Box';
import Typography from '~/components/Typography.tsx';
import { Link } from 'react-router-dom';
import Spotlight from '~/components/Spotlight.tsx';
import Icon from '~/components/Icon';
import NaverMap from '~/components/NaverMap.tsx';
import Logo from '~/components/Logo';
import Carousel from '~/components/Carousel.tsx';
import { useTranslation } from 'react-i18next';

export default function DiscoveryMainPage() {
  const [category, setCategory] = useKeepState<string>(
    'category',
    DiscoveryCategory.CATEGORY_DEFAULT
  );
  const { categories } = useDiscoveryCategories();
  const { discoveries } = useDiscoveries(category, { pageSize: 8 });
  return (
    <Page
      type={'main'}
      navigationBarProps={{
        lead: (
          <Logo color={'typography.tertiary'} height={'20px'}>
            discovery
          </Logo>
        ),
      }}
    >
      <Container pb={'4px'}>
        <Tabs
          scrollable
          value={category}
          onChange={(_, tab) => {
            setCategory(tab);
          }}
          sx={{
            px: '20px',
          }}
          tabs={[...categories.map((category) => ({ label: category.name, value: category.slug }))]}
        ></Tabs>
      </Container>
      {discoveries.length === 0 ? (
        <DiscoveryConstruction />
      ) : (
        <Container
          py={'8px'}
          stackProps={{
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {discoveries.map((discovery, idx) => (
            <DiscoveryCard key={discovery.id + idx} discovery={discovery} />
          ))}
        </Container>
      )}
    </Page>
  );
}

function DiscoveryConstruction() {
  const { i18n } = useTranslation();
  return (
    <Container px={'20px'}>
      <Spotlight
        textAlign={'center'}
        lead={
          <Icon size={'48px'} type={'circle'} color={'typography.primary'}>
            construction
          </Icon>
        }
        headline={i18n.t('discovery-main-thankYouForPatience')}
        description={i18n.t('discovery-main-contentUnderPreparation')}
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
      />
    </Container>
  );
}

function DiscoveryCard(props: { discovery: DiscoverySimple }) {
  const { discovery } = props;
  const markers = (discovery?.places ?? []).map(({ latlng }, idx) => ({
    label: idx + 1 + '',
    lat: latlng[0],
    lng: latlng[1],
  }));
  return (
    <Box
      px={'20px'}
      component={Link}
      to={`/discovery/contents/${discovery.id}`}
      sx={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      {markers.length > 0 ? (
        <NaverMap
          zoom={13}
          markers={markers}
          center={markers[0]}
          placeName={markers[0].label}
          centerMarker={false}
          disableTouch={true}
          containerStyles={{ width: '100%', height: '240px' }}
        />
      ) : (
        <Carousel images={discovery.images} />
      )}
      <Box pb={'16px'} pt={'8px'}>
        <Typography variant={'TitleS'} fontWeight={'medium'} sx={{ display: 'block' }}>
          {discovery.title}
        </Typography>
        <Typography
          sx={{ whiteSpace: 'pre-wrap', display: 'block' }}
          variant={'BaseS'}
          color={'typography.secondary'}
        >
          {discovery.description}
        </Typography>
      </Box>
    </Box>
  );
}
