import { ReactNode } from 'react';
import Area, { AreaProps } from '~/components/Area';
import ProductItem from '~/components/ProductItem';
import Container from '~/components/Container';
import Button from '~/components/Button';
import Stack from '~/components/Stack';
import Box from '~/components/Box';
import { Product } from '~kup/models/Product';
import useScrollIn from '~/hooks/useScrollIn.ts';
import { useTranslation } from 'react-i18next';

type ListProps = {
  title?: ReactNode;
  trail?: ReactNode;
  OverlapBadge?: ReactNode;
  products: Product[];
  background?: AreaProps<'div'>['background'];
  moreProduct?: string;
  onClickLinkMore?: () => void;
  onInfiniteScroll?: () => void;
};

export type ProductListProps = ListProps & {
  type: 'horizontal' | 'vertical' | 'pair' | 'simple';
};
export default function ProductList(props: ProductListProps) {
  const { type, ...listProps } = props;
  return type === 'pair' ? (
    <PairList {...listProps} />
  ) : type === 'horizontal' ? (
    <HorizontalList {...listProps} />
  ) : type === 'simple' ? (
    <HorizontalList simple {...listProps} />
  ) : (
    <VerticalList {...listProps} />
  );
}

function VerticalList(props: ListProps) {
  const { i18n } = useTranslation();
  const {
    title,
    trail,
    OverlapBadge,
    onClickLinkMore,
    products,
    onInfiniteScroll,
    background = 'paper',
    moreProduct = i18n.t('component-productList-moreProducts'),
  } = props;

  const observerRef = useScrollIn(onInfiniteScroll);
  return (
    <Area
      title={title}
      trail={trail}
      containerProps={{
        stackProps: {
          gap: '8px',
        },
      }}
      background={background}
    >
      {products.map((product) => (
        <ProductItem key={product.id} product={product} OverlapBadge={OverlapBadge} />
      ))}
      <div ref={observerRef} style={{ position: 'relative', bottom: '25vh' }} />
      {onClickLinkMore && (
        <Container py={'8px'} px={'20px'}>
          <Button
            fullWidth
            size={'medium'}
            color={'fill'}
            variant={'contained'}
            onClick={onClickLinkMore}
          >
            {moreProduct}
          </Button>
        </Container>
      )}
    </Area>
  );
}

function PairList(props: ListProps) {
  const { i18n } = useTranslation();
  const {
    title,
    trail,
    onClickLinkMore,
    products,
    onInfiniteScroll,
    background = 'paper',
    moreProduct = i18n.t('component-productList-moreProducts'),
  } = props;
  const tuples = products.reduce(
    (result, value, index, src) => {
      if (index % 2 === 0) {
        result.push([value, src[index + 1]]);
      }
      return result;
    },
    [] as [Product, Product | undefined][]
  );

  const observerRef = useScrollIn(onInfiniteScroll);
  return (
    <Area background={background} title={title} trail={trail}>
      <Stack column>
        {tuples.map(([productA, productB]) => (
          <Container key={productA.id + productB?.id} py={'4px'} px={'20px'}>
            <Stack row justifyContent={'space-evenly'} gap={'16px'}>
              <ProductItem
                type={'small'}
                sx={{
                  flex: 1,
                }}
                product={productA}
              />
              {productB && (
                <ProductItem
                  type={'small'}
                  sx={{
                    flex: 1,
                  }}
                  product={productB}
                />
              )}
            </Stack>
          </Container>
        ))}
      </Stack>

      <div ref={observerRef} style={{ position: 'relative', bottom: '25vh' }} />

      {onClickLinkMore && (
        <Container py={'8px'} px={'20px'}>
          <Button
            fullWidth
            size={'medium'}
            color={'fill'}
            variant={'contained'}
            onClick={onClickLinkMore}
          >
            {moreProduct}
          </Button>
        </Container>
      )}
    </Area>
  );
}

type HorizontalListProps = ListProps & {
  simple?: boolean;
};

function HorizontalList(props: HorizontalListProps) {
  const { simple, background = 'paper' } = props;
  const { i18n } = useTranslation();
  const {
    title,
    trail,
    onClickLinkMore,
    products,
    moreProduct = i18n.t('component-productList-moreProducts'),
  } = props;
  return (
    <Area background={background} title={title} trail={trail}>
      <Box
        sx={{
          overflowY: 'hidden',
        }}
      >
        <Stack row gap={'8px'} px={'20px'} pb={'8px'}>
          {products.map((product) => (
            <ProductItem
              key={product.id}
              type={simple ? 'simple' : 'small'}
              sx={{
                minWidth: '150px',
                maxWidth: '150px',
              }}
              product={product}
            />
          ))}
        </Stack>
      </Box>
      {onClickLinkMore && (
        <Container py={'8px'} px={'20px'}>
          <Button
            fullWidth
            size={'medium'}
            color={'fill'}
            variant={'contained'}
            onClick={onClickLinkMore}
          >
            {moreProduct}
          </Button>
        </Container>
      )}
    </Area>
  );
}
