import { kintFunctions } from '~kint/firebase/functions';
import Reservation, { ReservationBase } from '~kint/models/Reservation';

export default async function callReservation(reservationKey: string) {
  const reservationBase = await kintFunctions.call<ReservationBase>('callReservation', {
    reservationKey,
  });
  if (!reservationBase || (Array.isArray(reservationBase) && reservationBase.length === 0))
    return null;
  return new Reservation(reservationBase);
}
