export default function Kup(props: { height?: string; width?: string }) {
  const { height, width } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={'inherit'}
    >
      <path
        d="M39.1262 10.0945C39.7756 10.0223 40.2807 9.72466 40.6415 9.2015C41.0203 8.66031 41.2098 8.01088 41.2098 7.25321C41.2098 6.51358 41.0203 5.92728 40.6415 5.49433C40.2807 5.04334 39.7756 4.86294 39.1262 4.95314L35.5272 5.49433V10.4192L39.1262 10.0945ZM31.9824 3.00484L39.1262 1.65186C39.4509 1.59774 39.7846 1.57068 40.1274 1.57068C41.0113 1.57068 41.8231 1.79618 42.5627 2.24717C43.3204 2.68013 43.9157 3.29348 44.3487 4.08723C44.7816 4.88098 44.9981 5.78297 44.9981 6.79319C44.9981 7.9297 44.7275 8.99404 44.1863 9.98623C43.6451 10.9604 42.9235 11.7631 42.0216 12.3945C41.1196 13.0079 40.1544 13.3416 39.1262 13.3957L35.5272 13.6122V16.8053C35.5272 17.2743 35.3559 17.6712 35.0131 17.9959C34.6884 18.3386 34.2915 18.51 33.8225 18.51H31.9824V3.00484Z"
        fill={'currentColor'}
      />
      <path
        d="M29.822 16.399C29.822 16.9041 29.6326 17.3461 29.2538 17.725C28.893 18.1038 28.451 18.2932 27.9278 18.2932H26.3584V16.7779C25.4384 17.9324 24.2207 18.5097 22.7053 18.5097C19.6205 18.5097 18.0781 16.4622 18.0781 12.3671V6.38696L21.217 6.06224V12.2048C21.217 13.197 21.4155 13.9637 21.8124 14.5049C22.2273 15.046 22.7775 15.3166 23.463 15.3166C24.1485 15.3166 24.7709 15.0731 25.3301 14.586C25.8893 14.099 26.2321 13.4405 26.3584 12.6107V5.52105L29.822 5.14221V16.399Z"
        fill={'currentColor'}
      />
      <path
        d="M13.3368 18.5103L8.60135 12.2865L6.68011 14.8572V16.9679C6.68011 17.4008 6.52678 17.7616 6.2201 18.0503C5.91342 18.3569 5.55263 18.5103 5.13771 18.5103H3V3.00509L6.68011 2.59919V9.20175L12.0109 2.00388L16.6651 1.48975L10.6579 9.52646L18.0993 18.5103H13.3368Z"
        fill={'currentColor'}
      />
    </svg>
  );
}
