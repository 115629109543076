import { useLayoutEffect, useState } from 'react';
import { Order } from '~kup/models/Order.ts';
import { getOrder } from '~kup/controllers/order.ts';

export default function useOrder(orderId: string | undefined): Order | null {
  const [order, setOrder] = useState<Order | null>(null);
  useLayoutEffect(() => {
    if (orderId) getOrder(orderId).then((Order) => setOrder(Order));
  }, [orderId]);
  return order;
}
