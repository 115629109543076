import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Page from '~/components/Page';
import Spotlight from '~/components/Spotlight.tsx';
import Button from '~/components/Button.tsx';
import Icon from '~/components/Icon';
import useSyncBasket from '~/pages/shop/hooks/useSyncBasket.ts';

import { useTranslation } from 'react-i18next';

export default function ShopOrderTransactionPage() {
  useSyncBasket();
  const { i18n } = useTranslation();
  const [remainTime, setRemainTime] = useState<number>(3);
  const basketLink = '/shop/basket';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRemainTime((prevState) => prevState - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [remainTime]);

  if (remainTime <= 0) return <Navigate to={basketLink} replace />;

  return (
    <Page
      type={'modal'}
      name={
        i18n.t('shop-order-order') // Order
      }
      backgroundColor={'paper'}
    >
      <Spotlight
        textAlign={'center'}
        lead={
          <Icon
            type={'circle'}
            size={'40px'}
            width={'48px'}
            backgroundColor={'typography.tertiary'}
          >
            priority_high
          </Icon>
        }
        headline={
          i18n.t('shop-order-failed') // Your order failed
        }
        description={
          i18n.t('shop-order-failDescription') // 결제 실패로 주문을 처리할 수 없습니다. 3초뒤 장바구니로 돌아갑니다.
        }
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
        trail={
          <Button
            component={Link}
            to={basketLink}
            size="small"
            variant="contained"
            color="fill"
            sx={{
              minWidth: '126px',
            }}
          >
            {
              i18n.t('shop-order-backToBasket') // Go Back to Order
            }
          </Button>
        }
      />
    </Page>
  );
}
