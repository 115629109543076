import Container from '~/components/Container.tsx';
import Stack from '~/components/Stack.tsx';
import Button from '~/components/Button.tsx';
import FillBox from '~/components/FillBox.tsx';
import ImageView from '~/components/ImageView.tsx';
import Headline from '~/components/Headline.tsx';
import Popover from '~/components/ModalPopover.tsx';
import { BasketProductItem } from '~kup/models/Basket.ts';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type OutOfStockPopoverProps = {
  open?: boolean;
  outOfStockItems: BasketProductItem[];
};

export default function OutOfStockPopover(props: OutOfStockPopoverProps) {
  const { open, outOfStockItems } = props;
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <Popover
      open={open}
      title={
        i18n.t('shop-order-productsOutOfStock') // Some of the products are out of stock :(
      }
      description={
        i18n.t('shop-order-reorderCheckInventory') // Please reorder after checking inventory
      }
      onClose={() => {}}
      fixedBottomPosition={'sticky'}
      fixedBottom={
        <Container
          pb={'28px'}
          pt={'12px'}
          px={'20px'}
          background={'paper'}
          sx={() => ({ zIndex: 1 })}
        >
          <Stack column gap={'8px'}>
            <Button
              size={'medium'}
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                navigate('/shop/basket');
              }}
            >
              {
                i18n.t('shop-order-backToCart') // Back to my cart
              }
            </Button>
          </Stack>
        </Container>
      }
    >
      <Container px={'20px'} pb={'8px'}>
        <Stack column gap={'8px'}>
          {outOfStockItems.map((item) => {
            const key = 'stock' + item.key;
            return (
              <FillBox
                key={key}
                color={'fill'}
                lead={
                  item.thumbnail && (
                    <ImageView borderRadius={'8px'} width={'60px'} src={item.thumbnail} />
                  )
                }
                layoutBoxProps={{ gap: '16px' }}
              >
                <Headline subHeadline={item.productName} headline={item.optionSelectionName} />
              </FillBox>
            );
          })}
        </Stack>
      </Container>
    </Popover>
  );
}
