export type LogoBedProps = {
  width?: string;
  height?: string;
};
export default function IconBed(props: LogoBedProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11C7 9.34315 8.34315 8 10 8H30C31.6569 8 33 9.34315 33 11V16H7V11Z"
        fill="#E4CDAE"
      />
      <path
        d="M4 19C4 17.3431 5.34315 16 7 16H33C34.6569 16 36 17.3431 36 19V28H4V19Z"
        fill="#C0A383"
      />
      <path
        d="M9 13.5C9 12.6716 9.67157 12 10.5 12H17.5C18.3284 12 19 12.6716 19 13.5V16H9V13.5Z"
        fill="#FFF8EE"
      />
      <path
        d="M21 13.5C21 12.6716 21.6716 12 22.5 12H29.5C30.3284 12 31 12.6716 31 13.5V16H21V13.5Z"
        fill="#FFF8EE"
      />
      <path d="M4 28H36V32H4V28Z" fill="#FFCD00" />
      <path d="M4 28H36V32H4V28Z" fill="#6C583D" />
    </svg>
  );
}
