import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import ErrorFallBackView from '~/components/ErrorFallBack/ErrorFallBackView.tsx';

const FallbackComponent: React.FC<FallbackProps> = ({ error }) => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return <ErrorFallBackView />;
};

export default FallbackComponent;
