import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Container from '~/components/Container';
import Button from '~/components/Button';
import Spotlight from '~/components/Spotlight.tsx';
import Icon from '~/components/Icon';
import { useTranslation } from 'react-i18next';

export default function ShopMyOrderCancel() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <Page
      type={'modal'}
      name={
        i18n.t('shop-myOrder-cancellation') // Cancellation
      }
      fixedBottom={
        <Container bottom pt={'8px'} px={'20px'} background={'paper'}>
          <Button
            fullWidth
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              navigate('/shop/orders', { replace: true });
            }}
          >
            {
              i18n.t('shop-myOrder-goBackToOrders') // Go Back to My Orders
            }
          </Button>
        </Container>
      }
    >
      <Container px={'20px'}>
        <Spotlight
          lead={
            <Icon
              type={'circle'}
              width={'50px'}
              size={'32px'}
              backgroundColor={'typography.quaternary'}
            >
              check
            </Icon>
          }
          size={'medium'}
          textAlign={'center'}
          headline={
            i18n.t('shop-myOrder-orderCanceled') // Your order has been canceled
          }
          description={
            i18n.t('shop-myOrder-cancellationTimeframe') // Depending on the payment method, you can check the cancellation amount in 3 to 7 days
          }
        />
      </Container>
    </Page>
  );
}
