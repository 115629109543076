import { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  ProductOptionSelection,
  OptionStock,
  ProductOptionItem,
  Product,
} from '~kup/models/Product.ts';

import Button from '~/components/Button';
import Badge from '~/components/Badge';
import Carousel from '~/components/Carousel';
import Container from '~/components/Container';
import Page from '~/components/Page';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import Divider from '~/components/Divider';
import FillBox from '~/components/FillBox';
import Icon from '~/components/Icon';
import ImageView from '~/components/ImageView.tsx';
import Logo from '~/components/Logo/';

import isEquivalentSetArray from '~/utils/isEquivalentSetArray.ts';
import { ProductContext } from '~/pages/shop/product/contexts/ProductContext.tsx';
import useProduct from '~/pages/shop/hooks/useProduct.ts';
import IconButton from '~/components/IconButton.tsx';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import ProductList from '~/components/ProductList.tsx';
import Headline from '~/components/Headline.tsx';
import Collapse from '~/components/Collapse.tsx';
import Tabs from '~/components/Tabs';
import { useSnackBar } from '~/contexts/SnackBarContext.tsx';

import OptionPopup from './components/OptionPopup.tsx';
import SelectionPopup from './components/SelectionPopup.tsx';
import ImagePreviewer from '~/components/ImagePreviewer.tsx';
import PromotionTimeLimitBanner from '~/pages/shop/product/components/PromotionTimeLimitBanner.tsx';
import { useTranslation } from 'react-i18next';
import useRelatedProducts from '~/hooks/useRelatedProducts.ts';

const DETAIL_LOCALES = [
  { label: 'Translated (AI)', value: 'locale' },
  { label: 'Original (Korean)', value: 'original' },
];

export default function ShopProductPage() {
  const { productId } = useParams();
  const { relatedProducts } = useRelatedProducts(productId!);
  const { showSnackBar } = useSnackBar();
  const { i18n } = useTranslation();
  const [openOption, setOpenOption] = useState<boolean>(false);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [productSelections, setProductSelections] = useState<ProductOptionSelection[]>([]);
  const { product, loading, error } = useProduct(productId!);
  const productOptions = product?.productOptions ?? [];
  const productStock = product?.stocks ?? null;
  const isSoldOut =
    !product?.stocks.quantity || !!(product.purchasableCount && product.purchasableCount < 1);
  const isLimitedPurchase = product?.purchasableCount === 0;

  const navigate = useNavigate();

  const handleMoveToBasket = () => {
    navigate('/shop/basket');
  };

  const handleOptionOpen = () => {
    setOpenOption(true);
    setOpenSelection(false);
  };

  const handleSelectionOpen = () => {
    setOpenOption(false);
    setOpenSelection(true);
  };

  const handleOptionClose = () => {
    setOpenOption(false);
  };

  const handleSelectionClose = () => {
    setOpenSelection(false);
  };

  const handleProductOptionSelectionsCommit = (optionItemSelections: ProductOptionItem[]) => {
    if (!productStock) return;
    if (!productOptions?.length || !productStock) return;
    const slugList: string[] = optionItemSelections.map((item) => item.slug);
    const stock: OptionStock | null = productStock.getExactOptionStock(slugList);

    if (!stock || stock.quantity === 0) {
      showSnackBar({
        message: i18n.t('shop-product-optionOutOfStock'), // The option is out of stock.
        lift: true,
      });
      setOpenOption(false);
      return;
    }

    const isExists = !!productSelections.find(({ slugs }) => isEquivalentSetArray(slugs, slugList));
    if (isExists) {
      handleSelectionOpen();
      showSnackBar({
        message: i18n.t('shop-product-optionAlreadySelected'), // The option is already selected.
        lift: true,
      });
      return;
    }

    const newProductSelection: ProductOptionSelection = new ProductOptionSelection(
      stock,
      optionItemSelections
    );
    setProductSelections((prev) =>
      stock.isInPromotion ? [newProductSelection] : [...prev, newProductSelection]
    );
    handleSelectionOpen();
  };

  const selectionTotalSum = productSelections.reduce((result, selection) => {
    const { stock, quantity } = selection;
    const sum = stock.price * quantity;
    return result + sum;
  }, 0);

  const productContext: ProductContext = {
    product,
    productOptions,
    productStock,
    productSelections,
    total: selectionTotalSum,
    onProductOptionSelectionsCommit: handleProductOptionSelectionsCommit,
    setProductSelections: setProductSelections,
  };

  if (error) {
    return <Navigate to={'/shop'} />;
  }

  if (loading) {
    return null;
  }

  return (
    <ProductContext.Provider value={productContext}>
      <Page
        type={'page'}
        key={product?.id}
        navigationBarProps={{
          center: (
            <Link
              to={'/shop'}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Logo color={'typography.tertiary'} height={'20px'}>
                shop
              </Logo>
            </Link>
          ),
          trail: (
            <IconButton
              touchArea={'16px'}
              color={'typography.quaternary'}
              onClick={handleMoveToBasket}
              sx={{
                opacity: '0.6',
              }}
              iconProps={{
                width: '20px',
              }}
            >
              shopping_basket
            </IconButton>
          ),
        }}
        fixedBottomPosition={'sticky'}
        fixedBottom={
          <Container bottom background={'paper'} px={'20px'} pt={'16px'}>
            <Button
              fullWidth
              disabled={isSoldOut || isLimitedPurchase}
              color={isSoldOut || isLimitedPurchase ? 'fill' : 'primary'}
              variant={'contained'}
              onClick={handleOptionOpen}
            >
              {
                isSoldOut
                  ? i18n.t('shop-product-soldOut') // Sold Out
                  : isLimitedPurchase
                    ? i18n.t('shop-product-orderLimitReached') // Order Limit Reached
                    : i18n.t('shop-product-buy') // Buy
              }
            </Button>
          </Container>
        }
      >
        {product?.isInPromotion && <PromotionTimeLimitBanner />}
        <Container>
          <Carousel images={product?.imageUrls ?? []} />
        </Container>
        <Container background={'paper'} py={'10px'}>
          <Container px={'20px'} py={'12px'}>
            <Headline size={'medium'} headline={product?.name ?? ''} subHeadline={product?.brand} />
          </Container>
          <Container
            px={'20px'}
            stackProps={{
              row: true,
              gap: '8px',
              marginBottom: '8px',
            }}
          >
            {product &&
            product?.price !== product?.originalPrice &&
            product?.price < product?.originalPrice ? (
              <Typography component={'span'} variant={'TitleL_B'} color={'red'} lineHeight={1}>
                {(
                  ((product?.originalPrice - product?.price) / product?.originalPrice) *
                  100
                ).toFixed(0)}
                %
              </Typography>
            ) : null}
            <Stack row alignItems={'center'} flexWrap={'wrap'} gap={'6px'}>
              <Typography component={'span'} variant={'TitleL_B'} lineHeight={1}>
                {product?.price.toLocaleString()} KRW
              </Typography>
              {product &&
              product?.price !== product?.originalPrice &&
              product?.price < product?.originalPrice ? (
                <Typography
                  strike
                  lineHeight={1}
                  component={'span'}
                  variant={'BaseM'}
                  color={'typography.quaternary'}
                >
                  {product?.originalPrice.toLocaleString()} KRW
                </Typography>
              ) : null}
            </Stack>
          </Container>
          <Container px={'20px'} py={'8px'}>
            <Stack row gap={'6px'}>
              {product?.best && (
                <Badge color={'warning'} size={'small'}>
                  {
                    i18n.t('shop-product-best') // BEST}
                  }
                </Badge>
              )}
              {isSoldOut && (
                <Badge color={'error'} size={'small'}>
                  {
                    i18n.t('shop-product-soldOut') // Sold Out
                  }
                </Badge>
              )}
              {!isSoldOut && product?.isInPromotion && (
                <Badge color={'error'} size={'small'}>
                  {
                    i18n.t('shop-product-hotDeal') // 🔥 HOT DEAL
                  }
                </Badge>
              )}
              {product?.delivery && (
                <Badge color={'primary'} size={'small'}>
                  <Logo
                    height={'14px'}
                    // width={'98px'}
                    color={'inherit'}
                  >
                    delivery
                  </Logo>
                </Badge>
              )}
            </Stack>
          </Container>
          <Container px={'20px'} py={'8px'}>
            <DeliveryInstruction />
          </Container>
          {product?.isInPromotion && (
            <Container px={'20px'} py={'8px'}>
              <PromotionInstruction />
            </Container>
          )}
          {/*<Container*/}
          {/*    px={"20px"}*/}
          {/*>*/}
          {/*  <FillBox*/}
          {/*      type={"border"}*/}
          {/*      trail={*/}
          {/*        <Icon*/}
          {/*            size={"0.9em"}*/}
          {/*            backgroundColor={"typography.quaternary"}*/}
          {/*        >*/}
          {/*          question_mark*/}
          {/*        </Icon>*/}
          {/*      }*/}
          {/*  >*/}
          {/*    <Typography*/}
          {/*        variant={"FootNote"}*/}
          {/*        color={"typography.primary"}*/}
          {/*    >*/}
          {/*      Packaged for Easy airplane carrying*/}
          {/*    </Typography>*/}

          {/*  </FillBox>*/}
          {/*</Container>*/}
        </Container>
        <Divider type={'box'} color={'fill'} />
        <Container background={'paper'}>
          <ProductList
            type={'simple'}
            title={
              i18n.t('shop-product-dontMissThis') // Don't miss this product!
            }
            products={relatedProducts}
          />
        </Container>
        <Divider type={'box'} color={'fill'} />
        <ProductDetailView product={product} />
      </Page>
      {openOption && <OptionPopup open={openOption} onClose={handleOptionClose} />}
      {openSelection && <SelectionPopup open={openSelection} onClose={handleSelectionClose} />}
    </ProductContext.Provider>
  );
}

function ProductDetailView(props: { product?: Product | null }) {
  const { product } = props;
  const { i18n } = useTranslation();
  const [detailLocale, setDetailLocale] = useState<'locale' | 'original'>(
    DETAIL_LOCALES[0].value as 'locale' | 'original'
  );
  if (!product) return;
  const translated = product.detailImageUrls ?? [];
  const originals = product.metaDetailImageUrls ?? [];
  if (translated.length === 0 || originals.length === 0) return;

  const images = detailLocale === 'locale' ? translated : originals;
  return (
    <Container background={'paper'}>
      <Container py={'12px'} px={'20px'}>
        <Typography variant={'BaseS_B'}>{i18n.t('shop-product-productDetail')}</Typography>
        <Typography whiteSpace={'pre-wrap'}>{product?.description}</Typography>
      </Container>
      <Divider type={'box'} color={'fill'} />
      <Container>
        {/*  <Container>*/}
        {/*  <ValueList*/}
        {/*      pairs={[*/}
        {/*        {key: "size", pair: ["Size", "300x80(mm)"]},*/}
        {/*        {key: "color", pair: ["Color", "black, white, red, violet (4color)"]},*/}
        {/*        {*/}
        {/*          key: "notes",*/}
        {/*          pair: ["Color", "Because of the nature of handicrafts, each product may have different colors, thickness, etc."],*/}
        {/*        },*/}
        {/*      ]}*/}
        {/*  />*/}
        {/*</Container>*/}

        <Container px={'20px'} pb={'20px'}>
          <Tabs
            value={detailLocale}
            onChange={(_, tab) => setDetailLocale(tab)}
            tabs={DETAIL_LOCALES.map((DL) => ({
              ...DL,
              label: i18n.t(`shop-product-${DL.label}`),
            }))}
          />
        </Container>
        {detailLocale === 'locale' && (
          <Container pb={'20px'} px={'20px'}>
            <FillBox
              type={'border'}
              color={'error'}
              py={'12px'}
              px={'16px'}
              lead={
                <Icon width={'20px'} type={'circle'} size={'1.25em'} color={'error'}>
                  translate
                </Icon>
              }
            >
              <Typography variant={'FootNote_B'} whiteSpace={'pre-wrap'}>
                {
                  i18n.t('shop-product-aiTranslationDisclaimer') // Images translated by AI for Clarity.\nAI can make mistakes. Please verify important information.
                }
              </Typography>
            </FillBox>
          </Container>
        )}
        <Container px={'20px'}>
          <DetailImages images={images} />
        </Container>
      </Container>
      <Divider type={'box'} color={'fill'} />
    </Container>
  );
}

function DetailImages(props: { images: string[] }) {
  const { images } = props;
  const [image, ...otherImages] = images;
  const { i18n } = useTranslation();
  const [openPreviewImg, setPreviewImg] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const openImagePreviewer = (fileUrl: string = '') => {
    setPreviewImg(true);
    setFileUrl(fileUrl);
  };
  const closeImagePreviewer = () => {
    setPreviewImg(false);
    setFileUrl('');
  };
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpenButton = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <Container
        stackProps={{
          column: true,
          alignItems: 'center',
        }}
      >
        <ImageView
          src={image}
          minWidth={'100%'}
          maxWidth={'100%'}
          backgroundColor={'fill'}
          onClick={() => {
            openImagePreviewer(image);
          }}
        />
        {otherImages && otherImages.length > 0 && (
          <>
            <Collapse unmountOnExit in={open} sx={{ width: '100%' }}>
              {otherImages.map((url) => (
                <Container key={url}>
                  <ImageView
                    src={url}
                    width={'100%'}
                    minWidth={'100%'}
                    maxWidth={'100%'}
                    backgroundColor={'fill'}
                    onClick={() => {
                      openImagePreviewer(url);
                    }}
                  />
                </Container>
              ))}
            </Collapse>
            <Button
              fullWidth
              onClick={toggleOpenButton}
              variant={'outlined'}
              size={'medium'}
              sx={{ alignSelf: 'center', my: '20px' }}
            >
              {
                open
                  ? i18n.t('shop-product-hideDetails') // Hide Details
                  : i18n.t('shop-product-viewMoreDetails') // View More Details
              }
            </Button>
          </>
        )}
      </Container>
      <ImagePreviewer
        key={fileUrl}
        open={openPreviewImg}
        fileUrl={fileUrl}
        onClose={closeImagePreviewer}
      />
    </>
  );
}

function DeliveryInstruction() {
  const { cutOffRemains, deliveryDayExpression, deliveryHoursKST } = useDeliveryCutOff();
  const { i18n } = useTranslation();
  return (
    <FillBox type={'fill'} color={'fill'} justifyContent={'center'}>
      <Typography
        variant={'BaseS_B'}
        textAlign={'center'}
        whiteSpace={'pre'}
        color={'typography.primary'}
      >
        {
          i18n.t('shop-product-orderWithinReceiveToday', {
            timeUntilCutOff: cutOffRemains.text,
            deliveryDay: deliveryDayExpression,
            deliveryHour: deliveryHoursKST,
          }) // Order within {deliveryCutOff.timeUntilCutOff.text} to receive Delivery before 11pm Today
        }
      </Typography>
    </FillBox>
  );
}

function PromotionInstruction() {
  const { i18n } = useTranslation();
  return (
    <FillBox type={'fill'} color={'error'} justifyContent={'center'}>
      <Typography variant={'BaseS_B'} textAlign={'center'} color={'typography.warning'}>
        {
          i18n.t('shop-product-oneItemPerCustomerDeal') // Only one item per customer is available for this deal 🔥
        }
      </Typography>
    </FillBox>
  );
}
