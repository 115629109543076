export type LogoKakaoTalkProps = {
  width?: string;
  height?: string;
};
export default function IconKakaoTalk(props: LogoKakaoTalkProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_477_17101)">
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z"
          fill="#FEE500"
        />
        <path
          d="M19.9918 10C13.6303 10 8.5 14.1042 8.5 19.0867C8.5 22.3208 10.6342 25.1527 13.8355 26.7698L12.752 30.8165C12.7315 30.8772 12.7284 30.9423 12.7429 31.0046C12.7574 31.067 12.7889 31.124 12.834 31.1695C12.8998 31.2274 12.9844 31.2595 13.0721 31.2597C13.1448 31.2539 13.2138 31.2252 13.2691 31.1777L17.9315 28.0339C18.6197 28.1289 19.3135 28.1782 20.0082 28.1816C26.3615 28.1816 31.5 24.0774 31.5 19.0867C31.5 14.096 26.3451 10 19.9918 10Z"
          fill="#392020"
        />
        <path
          d="M12.5217 17.65H11.2658C11.1101 17.6545 10.9564 17.6146 10.8225 17.5351C10.7631 17.4978 10.712 17.4487 10.6725 17.3908C10.6329 17.3329 10.6058 17.2674 10.5927 17.1985C10.5887 17.1548 10.5887 17.1109 10.5927 17.0672C10.5888 16.9855 10.6047 16.904 10.639 16.8296C10.6733 16.7553 10.725 16.6904 10.7897 16.6404C10.9328 16.5367 11.1055 16.482 11.2822 16.4844H15.0663C15.2232 16.4788 15.378 16.5219 15.5096 16.6075C15.5704 16.6431 15.6226 16.6918 15.6623 16.75C15.7021 16.8082 15.7284 16.8745 15.7394 16.9441C15.7434 16.985 15.7434 17.0263 15.7394 17.0672C15.7433 17.1501 15.7274 17.2328 15.6932 17.3084C15.6589 17.384 15.6073 17.4504 15.5424 17.5022C15.4028 17.6057 15.2317 17.6579 15.0581 17.65H13.8432V21.9676C13.8482 22.0596 13.8339 22.1516 13.8014 22.2378C13.7689 22.324 13.7189 22.4025 13.6545 22.4683C13.5927 22.5313 13.5184 22.5806 13.4364 22.6131C13.3544 22.6456 13.2665 22.6606 13.1784 22.6571C13.0242 22.6615 12.8733 22.6122 12.7515 22.5176C12.6356 22.4252 12.5569 22.294 12.5299 22.1482C12.5218 22.0883 12.5218 22.0276 12.5299 21.9676L12.5217 17.65Z"
          fill="#FEE500"
        />
        <path
          d="M16.1018 16.8051C16.1379 16.67 16.2227 16.553 16.3398 16.4767C16.4647 16.4059 16.6069 16.3718 16.7503 16.3782H17.0622C17.2143 16.3727 17.3649 16.4097 17.4972 16.4849C17.6374 16.5792 17.7371 16.7228 17.7763 16.8871L19.418 21.5249C19.4606 21.6459 19.4962 21.7693 19.5247 21.8943C19.5275 21.9463 19.5275 21.9983 19.5247 22.0502C19.5275 22.1302 19.5128 22.2099 19.4816 22.2836C19.4504 22.3573 19.4035 22.4234 19.3441 22.4771C19.2866 22.536 19.2176 22.5825 19.1413 22.6136C19.065 22.6446 18.9832 22.6596 18.9009 22.6577C18.7743 22.6703 18.6471 22.6399 18.5399 22.5714C18.4327 22.5029 18.3516 22.4003 18.3099 22.2801L17.9651 21.2705H15.8063L15.4615 22.2801C15.4208 22.4027 15.3388 22.5072 15.2295 22.5761C15.1202 22.645 14.9905 22.6738 14.8623 22.6577C14.7235 22.6613 14.5876 22.6179 14.4765 22.5345C14.3683 22.4485 14.2954 22.3259 14.2713 22.1898C14.267 22.1434 14.267 22.0967 14.2713 22.0502C14.2572 21.9688 14.2572 21.8855 14.2713 21.8041C14.2713 21.7138 14.3288 21.6152 14.3616 21.5249L16.1018 16.8051ZM16.9226 17.8804L16.1428 20.3429H17.6942L16.9226 17.8804Z"
          fill="#FEE500"
        />
        <path
          d="M19.7126 17.0677C19.7043 16.8835 19.7692 16.7035 19.8932 16.567C19.9562 16.5034 20.0319 16.4537 20.1153 16.4212C20.1988 16.3887 20.2881 16.374 20.3775 16.3781C20.5313 16.3763 20.6813 16.4254 20.8043 16.5177C20.9184 16.614 20.9941 16.7479 21.0178 16.8953C21.0258 16.9524 21.0258 17.0105 21.0178 17.0677V21.3853H23.2751C23.4315 21.3833 23.5853 21.4259 23.7183 21.5084C23.7781 21.5452 23.8294 21.5943 23.869 21.6523C23.9086 21.7103 23.9356 21.7759 23.9482 21.845C23.9482 21.845 23.9482 21.9271 23.9482 21.9681C23.9521 22.0499 23.9362 22.1313 23.9019 22.2056C23.8676 22.2799 23.8159 22.3449 23.7512 22.395C23.6081 22.4986 23.4353 22.5533 23.2587 22.5509H20.4842C20.3075 22.5622 20.1319 22.5163 19.9835 22.4196C19.8508 22.3204 19.7624 22.173 19.7373 22.0092C19.7312 21.919 19.7312 21.8284 19.7373 21.7383L19.7126 17.0677Z"
          fill="#FEE500"
        />
        <path
          d="M24.0961 17.0676C24.0912 16.8839 24.1557 16.7052 24.2767 16.5669C24.3997 16.4551 24.5575 16.3891 24.7235 16.3801C24.8895 16.3712 25.0535 16.4197 25.1878 16.5176C25.3043 16.613 25.3829 16.7469 25.4095 16.8952C25.4135 16.9526 25.4135 17.0102 25.4095 17.0676V19.0786L27.4862 16.6817C27.5662 16.6 27.6512 16.5233 27.7406 16.452C27.8249 16.4018 27.9216 16.3761 28.0197 16.378C28.1632 16.3772 28.3035 16.4202 28.4219 16.5012C28.4777 16.5388 28.5254 16.5872 28.5621 16.6437C28.5988 16.7001 28.6237 16.7633 28.6354 16.8295C28.6387 16.8458 28.6387 16.8626 28.6354 16.8788C28.6314 16.8978 28.6314 16.9173 28.6354 16.9362C28.6351 17.0226 28.6125 17.1075 28.5697 17.1825C28.5261 17.262 28.4738 17.3363 28.4137 17.4041L27.0511 18.8981L28.6928 21.4427V21.5247C28.7781 21.6424 28.8445 21.7726 28.8898 21.9106V21.9516C28.8993 22.0467 28.8856 22.1427 28.8499 22.2313C28.8141 22.32 28.7574 22.3986 28.6846 22.4605C28.5477 22.564 28.3802 22.6189 28.2085 22.6165C28.0945 22.6212 27.9812 22.5957 27.8802 22.5426C27.7709 22.4692 27.6808 22.3707 27.6175 22.2553L26.0908 19.7928L25.3602 20.5562V21.9269C25.3651 22.1106 25.3006 22.2894 25.1796 22.4276C25.1147 22.491 25.0376 22.5406 24.9529 22.573C24.8682 22.6055 24.7777 22.6203 24.6871 22.6165C24.5356 22.6197 24.3877 22.5703 24.2685 22.4769C24.1525 22.3845 24.0738 22.2534 24.0469 22.1076C24.0387 22.0477 24.0387 21.9869 24.0469 21.9269L24.0961 17.0676Z"
          fill="#FEE500"
        />
      </g>
      <defs>
        <clipPath id="clip0_477_17101">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
