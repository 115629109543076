export type IconChineseSimplifiedProps = {
  width?: string;
  height?: string;
};
export default function IconChineseSimplified(props: IconChineseSimplifiedProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88889 0.5H31.1111C35.7442 0.5 39.5 4.25583 39.5 8.88889V31.1111C39.5 35.7442 35.7442 39.5 31.1111 39.5H8.88889C4.25583 39.5 0.5 35.7442 0.5 31.1111V8.88889C0.5 4.25583 4.25583 0.5 8.88889 0.5Z"
        stroke="currentColor"
      />
      <text
        x="49%"
        y="57%"
        fontFamily="Arial, sans-serif"
        fontSize="28"
        fill="currentColor"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        简
      </text>
    </svg>
  );
}
