import { FirebaseApp } from 'firebase/app';
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  OrderByDirection,
  query,
  QueryConstraint,
  serverTimestamp,
  Unsubscribe,
  updateDoc,
  where,
  FieldValue,
  WhereFilterOp,
} from 'firebase/firestore';

export type PathSegments = string[];

export type QueryOption = {
  orderBy?: [string, OrderByDirection];
  where?: [string, WhereFilterOp, unknown];
};

export class FFirestore {
  app: FirebaseApp;
  firestore: Firestore;
  static serverTimestamp = serverTimestamp;

  constructor(app: FirebaseApp) {
    this.app = app;
    this.firestore = getFirestore(app);
  }

  serverTimestamp() {
    return FFirestore.serverTimestamp();
  }

  private buildQuery(pathSegments: PathSegments, queryOption: QueryOption) {
    const [path, ...restPathSegments] = pathSegments;
    const collectionRef = collection(this.firestore, path, ...restPathSegments);
    const queryOptionParams: QueryConstraint[] = [];
    if (queryOption.orderBy) {
      queryOptionParams.push(orderBy(...queryOption.orderBy));
    }
    if (queryOption.where) {
      queryOptionParams.push(where(...queryOption.where));
    }
    return query(collectionRef, ...queryOptionParams);
  }

  listenCollection<T>(
    callback: (dataList: (T & { id: string })[], dataMap: { [id: string]: T }) => void,
    pathSegments: PathSegments,
    queryOption: QueryOption = {}
  ): Unsubscribe {
    return onSnapshot(this.buildQuery(pathSegments, queryOption), (snapshot) => {
      const dataTuples = snapshot.docs.map<[string, T]>((doc) => [doc.id, doc.data() as T]);
      const dataList = dataTuples.map(([id, data]) => ({ id, ...data }));
      const dataMap = Object.fromEntries(dataTuples);
      callback(dataList, dataMap);
    });
  }

  async readCollection<T>(
    pathSegments: PathSegments,
    queryOption: QueryOption = {}
  ): Promise<{
    list: (T & { id: string })[];
    map: { [id: string]: T };
  }> {
    const snapshot = await getDocs(this.buildQuery(pathSegments, queryOption));
    const dataTuples = snapshot.docs.map<[string, T]>((doc) => [doc.id, doc.data() as T]);
    const dataList = dataTuples.map(([id, data]) => ({ id, ...data }));
    const dataMap = Object.fromEntries(dataTuples);
    return { list: dataList, map: dataMap };
  }

  async countDocs(pathSegments: PathSegments, queryOption: QueryOption = {}): Promise<number> {
    const countReturn = await getCountFromServer(this.buildQuery(pathSegments, queryOption));
    return countReturn.data().count;
  }

  async readDoc<T>(path: string, ...pathSegments: PathSegments): Promise<T | null> {
    const docRef = doc(this.firestore, path, ...pathSegments);
    const read = await getDoc(docRef);
    if (!read) return null;
    return read.data() as T;
  }

  async pushDoc(pathSegments: PathSegments, data: unknown) {
    const [path, ...restPathSegments] = pathSegments;
    const colRef = collection(this.firestore, path, ...restPathSegments);
    return await addDoc(colRef, data);
  }

  async putDoc(
    pathSegments: PathSegments,
    data: { [x: string]: FieldValue | Partial<unknown> | undefined }
  ) {
    const [path, ...restPathSegments] = pathSegments;
    const docRef = doc(this.firestore, path, ...restPathSegments);
    await updateDoc(docRef, data);
  }
}
