import { useNavigate } from 'react-router-dom';
import Box, { BoxProps } from '~/components/Box';
import Badge from '~/components/Badge';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import Logo from '~/components/Logo';
import ImageView from '~/components/ImageView.tsx';

import { Product } from '~kup/models/Product';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type ProductProps = BoxProps & {
  product: Product;
  OverlapBadge?: ReactNode;
};
export type ProductItemProps = ProductProps & {
  type?: 'large' | 'small' | 'simple';
};

const CURRENCY_SYMBOLS = {
  KRW: '₩',
} as { [currency: string]: string };

export default function ProductItem(props: ProductItemProps) {
  const { type = 'large', ...productProps } = props;
  return type === 'large' ? (
    <ProductItemLarge {...productProps} />
  ) : type === 'small' ? (
    <ProductItemSmall {...productProps} />
  ) : (
    <ProductSimpleItem {...productProps} />
  );
}

export function ProductItemLarge(props: ProductProps) {
  const { sx, product, OverlapBadge } = props;
  const { id } = product;
  const {
    name,
    imageUrls,
    price,
    originalPrice,
    discountRate,
    best,
    delivery,
    currency = 'KRW',
    stocks,
  } = product;
  const { i18n } = useTranslation();
  const { deliveryDayExpression, deliveryHoursKST } = useDeliveryCutOff();

  const navigate = useNavigate();

  const isSoldOut = !stocks.quantity;
  const isInPromotion = product.isInPromotion;

  return (
    <Box
      sx={sx}
      py={'4px'}
      onClick={() => {
        navigate(`/shop/product/${id}`);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '400px',
          width: '100%',
        }}
      >
        <ImageView borderRadius={'0px'} src={imageUrls[0]} height={'100%'} width={'100%'} />
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          {OverlapBadge}
        </Box>
      </Box>
      <Box py={'12px'} px={'20px'}>
        <Stack gap={'4px'}>
          <Typography variant={'BaseS'}>{name}</Typography>
          <Stack row alignItems={'center'} gap={'4px'}>
            {price !== originalPrice && price < originalPrice ? (
              <Typography component={'span'} variant={'TitleS_B'} fontWeight={'bold'} color={'red'}>
                {(discountRate * 100).toFixed(0)}%
              </Typography>
            ) : null}
            <Typography component={'span'} variant={'TitleS_B'} fontWeight={'bold'}>
              {price.toLocaleString()} {currency}
            </Typography>

            {price !== originalPrice && price < originalPrice ? (
              <Typography
                component={'span'}
                variant={'FootNote'}
                fontWeight={'bold'}
                color={'typography.quaternary'}
                sx={{
                  textDecoration: 'line-through',
                }}
              >
                {originalPrice.toLocaleString()} {currency}
              </Typography>
            ) : null}
          </Stack>
          <Stack row gap={'6px'}>
            {isSoldOut && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-soldOut')}
              </Badge>
            )}
            {!isSoldOut && best && (
              <Badge color={'warning'} size={'small'}>
                {i18n.t('component-productItem-best')}
              </Badge>
            )}
            {!isSoldOut && isInPromotion && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-hotDeal')}
              </Badge>
            )}
            {!isSoldOut && delivery && (
              <>
                <Badge color={'primary'} size={'small'}>
                  <Logo height={'12px'} color={'inherit'}>
                    delivery
                  </Logo>
                </Badge>
                <Typography variant={'FootNote'} color={'typography.tertiary'}>
                  {i18n.t('component-productItem-freeDelivery', {
                    deliveryDay: deliveryDayExpression,
                    deliveryHour: deliveryHoursKST,
                  })}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export function ProductItemSmall(props: ProductProps) {
  const { sx } = props;
  const { product } = props;
  const {
    id,
    name,
    imageUrls,
    price,
    originalPrice,
    discountRate,
    best,
    delivery,
    currency = 'KRW',
    stocks,
  } = product;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isSoldOut = !stocks.quantity;
  const isInPromotion = product.isInPromotion;

  return (
    <Box
      sx={sx}
      onClick={() => {
        navigate(`/shop/product/${id}`);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '160px',
          width: '100%',
        }}
      >
        <ImageView src={imageUrls[0]} height={'100%'} width={'100%'} borderRadius={'8px'} />
      </Box>
      <Box py={'10px'}>
        <Stack gap={'4px'}>
          <Typography variant={'FootNote'}>{name}</Typography>
          <Stack row gap={'4px'}>
            {price !== originalPrice && price < originalPrice ? (
              <Typography
                component={'span'}
                variant={'BaseM_B'}
                fontWeight={'bold'}
                color={'error'}
              >
                {(discountRate * 100).toFixed(0)}%
              </Typography>
            ) : null}
            <Typography component={'span'} variant={'BaseM_B'} fontWeight={'bold'}>
              {price.toLocaleString()}
              {CURRENCY_SYMBOLS[currency] ?? ''}
            </Typography>
          </Stack>
          <Stack row gap={'6px'}>
            {isSoldOut && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-soldOut')}
              </Badge>
            )}
            {!isSoldOut && best && (
              <Badge color={'warning'} size={'small'}>
                {i18n.t('component-productItem-best')}
              </Badge>
            )}
            {!isSoldOut && isInPromotion && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-hotDeal')}
              </Badge>
            )}
            {!isSoldOut && !isInPromotion && delivery && (
              <>
                <Badge color={'primary'} size={'small'}>
                  <Logo height={'12px'} color={'inherit'}>
                    delivery
                  </Logo>
                </Badge>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export function ProductSimpleItem(props: ProductProps) {
  const { product } = props;
  const { id, name, price, imageUrls, currency = 'KRW' } = product;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100px',
      }}
      onClick={() => {
        navigate(`/shop/product/${id}`);
      }}
    >
      <ImageView src={imageUrls[0]} width={'100px'} height={'100px'} borderRadius={'8px'} />
      <Box
        sx={{
          pb: '8px',
        }}
      >
        <Typography
          variant={'Caption'}
          sx={{
            display: 'block',
            maxLines: 2,
            textOverflow: 'ellipsis',
          }}
        >
          {name}
        </Typography>
        <Typography
          variant={'FootNote'}
          fontWeight={'bold'}
          sx={{
            display: 'block',
          }}
        >
          {price.toLocaleString()}
          {CURRENCY_SYMBOLS[currency]}
        </Typography>
      </Box>
    </Box>
  );
}
