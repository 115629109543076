import {
  default as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import Radio, { RadioProps } from '~/components/Radio.tsx';

export type RadioGroupProps = MuiRadioGroupProps & {
  radios: RadioProps[];
};

export default function RadioGroup(props: RadioGroupProps) {
  const { radios, ...radioGroupProps } = props;
  return (
    <MuiRadioGroup {...radioGroupProps}>
      {radios.map((radio) => (
        <Radio key={radio.value} {...radio} />
      ))}
    </MuiRadioGroup>
  );
}
