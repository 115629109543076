import api from '~kup/api';
import { IIssuedCoupon } from '~kup/models/Coupon.ts';

const COUPON_PATH = {
  USABLE_COUPONS: () => ['coupons', 'issued', 'usable'].join('/'),
};

export async function getUsableCoupons(): Promise<IIssuedCoupon[]> {
  const resp = await api.get(COUPON_PATH.USABLE_COUPONS());
  return resp.data.data;
}
