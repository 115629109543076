import Page from '~/components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '~/components/IconButton.tsx';
import Carousel from '~/components/Carousel.tsx';
import usePlace from '~/pages/discovery/hooks/usePlace.ts';
import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import { useLayoutEffect, useState } from 'react';
import Divider from '~/components/Divider.tsx';
import HTML from '~/components/HTML.tsx';
import { DiscoveryPlace, DiscoveryRecommendation } from '~kup/models/Discovery.ts';
import Area from '~/components/Area.tsx';
import ValueList from '~/components/ValueList.tsx';
import NaverMap from '~/components/NaverMap.tsx';
import Button from '~/components/Button.tsx';
import ListItem from '~/components/ListItem.tsx';
import ImageView from '~/components/ImageView.tsx';
import Stack from '~/components/Stack.tsx';
import ButtonBase from '~/components/ButtonBase';
import { useTranslation } from 'react-i18next';

export default function DiscoveryPlacePage() {
  const { placeId } = useParams();
  const navigate = useNavigate();
  const place = usePlace(placeId ?? '');

  useLayoutEffect(() => {
    if (!placeId) navigate(-1);
  }, [placeId]);

  if (!place) return null;
  return (
    <Page
      type={'modal'}
      navigationBarProps={{
        lead: (
          <>
            <IconButton
              size={'large'}
              iconProps={{
                width: '24px',
              }}
              color={'typography.primary'}
              sx={(theme) => ({ backgroundColor: theme.palette.background.paper, p: '4px' })}
              onClick={() => navigate(-1)}
            >
              close
            </IconButton>
          </>
        ),
        background: 'transparent',
        position: { position: 'absolute', top: 0, left: 0, right: 0 },
      }}
    >
      <PlaceHeader
        images={place.images}
        priceLevel={place.priceLevel}
        tags={place.tags}
        name={place.name}
        description={place.description}
      />
      <Divider type={'box'} />
      <PlaceHTML html={place.html ?? ''} />
      <Divider type={'box'} />
      {place.recommendations.length > 0 && (
        <>
          <Container>
            <PlaceRecommendations recommendations={place.recommendations} />
          </Container>
          <Divider type={'box'} />
        </>
      )}
      <Container
        bottom
        stackProps={{
          gap: '16px',
        }}
      >
        <PlaceLocation location={place.location} name={place.name} />
        <PlaceInformation information={place.information} />
      </Container>
    </Page>
  );
}

function PlaceHeader(props: {
  images?: string[];
  priceLevel?: number;
  tags?: string[];
  name: string;
  description: string;
}) {
  const { images = [], priceLevel = 0, tags = [], name = '', description = '' } = props;

  return (
    <Container>
      <Container>
        <Carousel images={images ?? []} />
      </Container>
      <Container py={'16px'} px={'20px'}>
        <Typography variant={'Caption'} color={'text.secondary'}>
          {[new Array(priceLevel ?? 0).fill('$').join('')]
            .concat(tags ?? [])
            .filter((s) => !!s)
            .join(' • ')}
        </Typography>
        <Typography variant={'TitleM_B'} display={'block'}>
          {name}
        </Typography>
        <Typography variant={'BaseS'} color={'text.secondary'}>
          {description}
        </Typography>
      </Container>
    </Container>
  );
}

function PlaceHTML(props: { html: string }) {
  return (
    <Container py={'16px'} px={'20px'}>
      <HTML>{props.html}</HTML>
    </Container>
  );
}

function PlaceRecommendations(props: { recommendations: DiscoveryRecommendation[] }) {
  const { recommendations } = props;
  const { i18n } = useTranslation();
  const categories = [...new Set(recommendations.map((r) => r.category)).values()];
  const categoryTabs = categories.map((c) => ({ label: c, value: c }));
  const [tab, setTab] = useState<string>(categoryTabs[0]?.value ?? '');
  const categoryRecommendations = recommendations.filter((r) => r.category === tab);
  const handleSelectTabBuilder = (tab: string) => () => setTab(tab);

  if (recommendations.length === 0) return null;
  return (
    <Area title={i18n.t('discovery-place-recommendations')}>
      {categoryTabs.length > 1 && (
        <Stack flexDirection={'row'} gap={'12px'} px={'20px'}>
          {categoryTabs.map((c) => (
            <ButtonBase
              onClick={handleSelectTabBuilder(c.value)}
              sx={(theme) => {
                const isSelected = c.value === tab;
                const color = isSelected
                  ? theme.palette.primary.main
                  : theme.palette.typography.tertiary;
                const background = isSelected ? theme.palette.primary.light : 'none';
                return {
                  color,
                  borderRadius: '20px',
                  px: '12px',
                  py: '8px',
                  border: `solid 1px`,
                  background,
                };
              }}
            >
              {c.label}
            </ButtonBase>
          ))}
        </Stack>
      )}
      {categoryRecommendations.map((recommendation) => (
        <ListItem
          disableRipple
          type={'headline'}
          headline={recommendation.name}
          subHeadline={recommendation.subheader ?? ''}
          description={[recommendation.price, recommendation.description]
            .filter((str) => !!str)
            .join(', ')}
          trail={
            recommendation.image ? (
              <ImageView
                width={'80px'}
                height={'80px'}
                src={recommendation.image}
                borderRadius={'8px'}
              />
            ) : null
          }
        />
      ))}
    </Area>
  );
}

function PlaceLocation(props: { name: string; location: DiscoveryPlace['location'] }) {
  const { i18n } = useTranslation();
  const { name, location } = props;
  const center = { lat: location.latlng[0], lng: location.latlng[1] };
  return (
    <Area
      title={i18n.t('discovery-place-location')}
      containerProps={{
        px: '20px',
      }}
    >
      <NaverMap
        placeName={name}
        center={center}
        containerStyles={{ width: '100%', height: '240px' }}
      />
      <Container pt={'8px'} pb={'16px'}>
        <ValueList
          pairs={[
            { key: 'address', pair: ['Address', location.address] },
            { key: 'subway', pair: ['Subway', location.subway] },
            { key: 'nearBy', pair: ['Near By', location.nearBy] },
          ]}
        />
      </Container>
      <Button
        fullWidth
        color={'fill'}
        size={'small'}
        component={'a'}
        href={
          location.googleMap ??
          `https://www.google.com/maps/search/?api=1&query=${center?.lat},${center?.lng}`
        }
        target={'_blank'}
      >
        {i18n.t('discovery-place-findOnGoogleMap')}
      </Button>
    </Area>
  );
}

function PlaceInformation(props: { information: DiscoveryPlace['information'] }) {
  const { i18n } = useTranslation();
  if (props.information.length === 0) return null;
  return (
    <Area
      title={i18n.t('discovery-place-information')}
      containerProps={{
        px: '20px',
      }}
    >
      <ValueList
        pairs={props.information.map((i) => ({ key: i.value, pair: [i.label, i.value] }))}
      />
    </Area>
  );
}
