import { FirebaseApp } from 'firebase/app';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';

interface Response<T> {
  success: boolean;
  result?: T;
  data: T;
  message: string;
  error?: string;
}

export class FFunctions {
  app: FirebaseApp;
  functions: Functions;

  #timeout = 540000;

  constructor(app: FirebaseApp) {
    this.app = app;
    this.functions = getFunctions(app);
  }

  async call<ResponseDataType extends object, RequestDataType = unknown>(
    name: string,
    data: RequestDataType
  ): Promise<ResponseDataType | null> {
    const callable = httpsCallable<RequestDataType, Response<ResponseDataType> | ResponseDataType>(
      this.functions,
      name,
      { timeout: this.#timeout }
    );
    const result = await callable(data);
    const isbareResponse = (
      respData: ResponseDataType | Response<ResponseDataType>
    ): respData is ResponseDataType => {
      return !('data' in respData) && !('result' in respData);
    };
    if (!result.data) throw new Error('No Response');
    if (isbareResponse(result.data)) return result.data;
    // if(!result.data.success) throw new Error('Is Fail');
    return result.data.result ?? result.data.data; //기존 kint와 호환성
  }
}
