import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useKeepStateContext } from '~/contexts/KeepStateContext.tsx';

type Dispatch<A> = (value: A) => void;
type StateSetter<S> = (prevState: S) => S;
type SetStateAction<S> = S | StateSetter<S>;

export function useKeepState<S>(key: string, initialState: S): [S, Dispatch<SetStateAction<S>>] {
  const location = useLocation();
  const locationUri = `${location.pathname}${location.hash}${location.search}`;

  const keepStateContext = useKeepStateContext();
  if (keepStateContext === null) throw new Error('KeepState is not initialized');

  const [_get, _set] = keepStateContext;
  const setter: Dispatch<SetStateAction<S>> = useCallback(
    (s: SetStateAction<S>) => _set(locationUri, key, s),
    [locationUri, key, _set]
  );
  const value = (_get(locationUri, key, initialState) as S) ?? initialState;
  return [value, setter];
}
