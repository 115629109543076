import { Address } from '~kup/models/types.ts';
import { useCallback, useEffect, useState } from 'react';
import { searchAddress } from '~kup/controllers/search.ts';
import debounce from 'lodash/debounce';

export default function useSearchAddressWithDebounce(
  search: string,
  debounceInterval?: number
): Address[] {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const debouncedSearch = useCallback(
    debounce((search) => {
      searchAddress(search).then(setAddresses);
    }, debounceInterval ?? 300),
    []
  );

  useEffect(() => {
    search && debouncedSearch(search);
  }, [search]);
  return addresses;
}
