import Page from '~/components/Page';
import Container from '~/components/Container';
import Headline from '~/components/Headline';
import { Navigate, useParams } from 'react-router-dom';
import useKupNotification from '~/hooks/useKupNotification.ts';
import Typography from '~/components/Typography.tsx';

export default function MessageNotificationPage() {
  const { notificationId } = useParams();
  const { notification } = useKupNotification(notificationId);
  if (!notificationId) return <Navigate to={'/message/notification'} replace />;
  return (
    <Page type={'modal'}>
      <Container py={'16px'} px={'20px'}>
        <Headline headline={notification?.title ?? notificationId + ''} />
      </Container>
      <Container px={'20px'}>
        <Typography variant={'BaseS'} color={'typography.secondary'}>
          {notification?.description ?? notificationId}
        </Typography>
      </Container>
    </Page>
  );
}
