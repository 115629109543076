import { default as MuiStack, StackProps as MuiStackProps } from '@mui/material/Stack';

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export type StackProps = {
  row?: boolean;
  column?: boolean;
  reverse?: boolean;
  direction?: Direction;
  inline?: boolean;
} & MuiStackProps;

export default function Stack(props: StackProps) {
  const {
    row,
    column,
    reverse,
    direction,
    inline,
    gap,
    justifyContent,
    alignItems,
    flex,
    display,
    ...restProps
  } = props;
  const { children } = props;

  const alignProps = {
    display: display ?? (inline ? 'inline-flex' : 'flex'),
    flexDirection: direction ?? (!row || column ? 'column' : 'row') + (reverse ? '-reverse' : ''),
    gap,
    justifyContent,
    alignItems,
    flex,
  } as MuiStackProps;
  return (
    <MuiStack {...alignProps} {...restProps}>
      {' '}
      {children}{' '}
    </MuiStack>
  );
}
