export type TimeRemains = {
  hours: number;
  minutes: number;
  seconds: number;
};

export default function getTimeRemains(endDate: Date): TimeRemains {
  const now = new Date();
  const millisecondsUntilTenPM = endDate.getTime() - now.getTime();
  const seconds = Math.floor((millisecondsUntilTenPM / 1000) % 60);
  const minutes = Math.floor((millisecondsUntilTenPM / 1000 / 60) % 60);
  const hours = Math.floor((millisecondsUntilTenPM / (1000 * 60 * 60)) % 24);

  return {
    hours,
    minutes,
    seconds,
  };
}
