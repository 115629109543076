export type IconEnglishProps = {
  width?: string;
  height?: string;
};
export default function IconEnglish(props: IconEnglishProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88889 0.5H31.1111C35.7442 0.5 39.5 4.25583 39.5 8.88889V31.1111C39.5 35.7442 35.7442 39.5 31.1111 39.5H8.88889C4.25583 39.5 0.5 35.7442 0.5 31.1111V8.88889C0.5 4.25583 4.25583 0.5 8.88889 0.5Z"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5895 9.83611C22.2598 6.64579 17.7401 6.64577 16.4103 9.83611L8.54704 28.7019C7.95683 30.118 8.62631 31.7444 10.0424 32.3346C11.4584 32.9248 13.0848 32.2553 13.675 30.8393L15.6866 26.0131C15.8053 26.0286 15.9263 26.0366 16.0493 26.0366H23.9505C24.0734 26.0366 24.1945 26.0286 24.3133 26.0131L26.3248 30.8393C26.915 32.2553 28.5414 32.9248 29.9575 32.3346C31.3735 31.7444 32.043 30.118 31.4528 28.7019L23.5895 9.83611ZM22.0075 20.481L19.9999 15.6644L17.9923 20.481H22.0075Z"
        fill="currentColor"
      />
    </svg>
  );
}
