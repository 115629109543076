// import api from "~kup/api";
import {
  Discovery,
  DiscoveryCategory,
  DiscoveryCategoryResponse,
  DiscoveryPlace,
  DiscoveryPlaceResponse,
  DiscoveryResponse,
  DiscoverySimple,
  DiscoverySimpleResponse,
} from '~kup/models/Discovery.ts';
import { DISCOVERIES, DISCOVERY, DISCOVERY_CATEGORIES, DISCOVERY_PLACE } from '~/_mock';

// const DISCOVERY_PATH = {
//   CATEGORIES: () => '/discovery-categories',
// }

export async function getDiscoveryCategories(): Promise<DiscoveryCategory[]> {
  // const resp = await api.get(DISCOVERY_PATH.CATEGORIES());
  const discoveryCategoryResponses: DiscoveryCategoryResponse[] = DISCOVERY_CATEGORIES;
  return discoveryCategoryResponses.map((r) => new DiscoveryCategory(r));
}

type DiscoveriesReturns = {
  discoveries: DiscoverySimple[];
  hasNextPage: boolean;
  meta: { totalCount: number };
};

export async function getDiscoveries({
  category,
}: {
  category: string;
  page: number;
  pageSize: number;
}): Promise<DiscoveriesReturns> {
  const discoveriesResponses: DiscoverySimpleResponse[] = DISCOVERIES[category];
  return {
    discoveries: discoveriesResponses.map((r) => new DiscoverySimple(r)),
    hasNextPage: false,
    meta: { totalCount: discoveriesResponses.length },
  };
}

export async function getDiscovery(discoveryId: string): Promise<Discovery | null> {
  const discoveryResponse: DiscoveryResponse | undefined = DISCOVERY(discoveryId);
  return discoveryResponse ? new Discovery(discoveryResponse) : null;
}

export async function getPlace(placeId: string): Promise<DiscoveryPlace | null> {
  const placeResponse: DiscoveryPlaceResponse | undefined = DISCOVERY_PLACE(placeId);
  return placeResponse ? new DiscoveryPlace(placeResponse) : null;
}
