import Container from '~/components/Container.tsx';
import Stack from '~/components/Stack.tsx';
import Button from '~/components/Button.tsx';
import Popover from '~/components/ModalPopover.tsx';
import Typography from '~/components/Typography.tsx';
import useLoading from '~/hooks/useLoading.tsx';
import { useTranslation } from 'react-i18next';

export type CancelPopover = {
  open?: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export default function CancelPopover(props: CancelPopover) {
  const { open, onClose, onCancel } = props;
  const { i18n } = useTranslation();
  const { startLoading, loading } = useLoading();

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Popover
      open={open}
      title={
        i18n.t('shop-myOrder-cancelOrderPrompt') // Would you like to cancel the order?
      }
      onClose={onClose}
      fixedBottomPosition={'sticky'}
      fixedBottom={
        <Container
          pb={'28px'}
          pt={'12px'}
          px={'20px'}
          background={'paper'}
          sx={() => ({ zIndex: 1 })}
        >
          <Stack column gap={'8px'}>
            <Button
              loading={loading}
              variant={'contained'}
              color={'error'}
              onClick={() => {
                startLoading();
                handleCancel();
              }}
              size={'medium'}
            >
              {
                i18n.t('shop-myOrder-cancelOrder') // Cancel Order
              }
            </Button>
            <Button
              disabled={loading}
              variant={'contained'}
              color={'fill'}
              size={'medium'}
              onClick={onClose}
            >
              {
                i18n.t('shop-myOrder-return') // Return
              }
            </Button>
          </Stack>
        </Container>
      }
    >
      <Container px={'20px'} pb={'8px'}>
        <Typography whiteSpace={'pre-wrap'}>
          {
            i18n.t('shop-myOrder-outOfStockCancelPrompt') // If the item is out of stock, it may be difficult to reorder.\nWould you like to cancel your order?
          }
        </Typography>
      </Container>
    </Popover>
  );
}
