import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ShopSlice, persistShopSlice, createShopSlice } from '~kup/store/slice/shopState';
import { AuthSlice, persistAuthSlice, createAuthSlice } from '~kup/store/slice/authSlice';
import {
  createNotificationSlice,
  persistNotificationSlice,
  NotificationSlice,
} from '~kup/store/slice/notificationSlice.ts';
import {
  createCommonSlice,
  persistCommonSlice,
  CommonSlice,
} from '~kup/store/slice/commonSlice.ts';

export const useKupStore = create<ShopSlice & AuthSlice & NotificationSlice & CommonSlice>()(
  immer(
    persist(
      (...args) => ({
        ...createShopSlice(...args),
        ...createAuthSlice(...args),
        ...createNotificationSlice(...args),
        ...createCommonSlice(...args),
      }),
      {
        name: 'kup-storage',
        partialize: (state) => ({
          ...persistShopSlice(state),
          ...persistAuthSlice(state),
          ...persistNotificationSlice(state),
          ...persistCommonSlice(state),
        }),
      }
    )
  )
);

export default useKupStore;
