import { useLayoutEffect, useState } from 'react';
import getTimeRemains from '~/utils/getTimeRemains.ts';
import { useTranslation } from 'react-i18next';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Remains = {
  hours: number;
  minutes: number;
  seconds: number;
  text: string;
};

type UseDeliveryCutOff = {
  cutOffDate: Date;
  deliveryDate: Date;
  cutOffHoursKST: number;
  deliveryHoursKST: number;
  cutOffRemains: Remains;
  deliveryRemains: Remains;
  deliveryDayExpression: string;
  isPassedTodayCutOff: boolean;
};

const calcRemains = (targetDate: Date): Remains => {
  const { hours, seconds, minutes } = getTimeRemains(targetDate);
  return {
    hours,
    minutes,
    seconds,
    text: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
  };
};

export default function useDeliveryCutOff(): UseDeliveryCutOff {
  const { i18n } = useTranslation();
  const expressionToday = i18n.t('shop-constants-today'); // today
  const expressionTomorrow = i18n.t('shop-constants-tomorrow'); // tomorrow

  const now = dayjs().tz('Asia/Seoul');
  const nowHour = now.hour();

  let cutOffDayjs: Dayjs = now;
  let deliveryDayjs: Dayjs = now;
  let cutOffHoursKTS: number = 19;
  let deliveryHoursKTS: number = 23;
  let willDeliveryTomorrow: boolean = false;

  if (nowHour < 7) {
    // 0, 1,2, 3, 4, 5, 6 / 10시 today
    cutOffHoursKTS = 7;
    deliveryHoursKTS = 11;
    cutOffDayjs = now.set('hour', cutOffHoursKTS).set('minute', 0).set('seconds', 0);
    deliveryDayjs = now.set('hour', deliveryHoursKTS).set('minute', 0).set('seconds', 0);
  } else if (nowHour < 19) {
    // 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18 / 22시 today
    cutOffHoursKTS = 19;
    deliveryHoursKTS = 23;
    cutOffDayjs = now.set('hour', cutOffHoursKTS).set('minute', 0).set('seconds', 0);
    deliveryDayjs = now.set('hour', deliveryHoursKTS).set('minute', 0).set('seconds', 0);
  } else {
    // 19, 20, 21, 22, 23 / 10시 tomorrow
    willDeliveryTomorrow = true;
    cutOffHoursKTS = 7;
    deliveryHoursKTS = 11;
    const tomorrow = now.add(1, 'day');
    cutOffDayjs = tomorrow.set('hour', cutOffHoursKTS).set('minute', 0).set('seconds', 0);
    deliveryDayjs = tomorrow.set('hour', deliveryHoursKTS).set('minute', 0).set('seconds', 0);
  }

  const [cutOffRemains, setCutOffRemains] = useState<Remains>(calcRemains(cutOffDayjs.toDate()));
  const [deliveryRemains, setDeliveryRemains] = useState<Remains>(
    calcRemains(deliveryDayjs.toDate())
  );

  useLayoutEffect(() => {
    const interval = () => {
      setCutOffRemains(calcRemains(cutOffDayjs.toDate()));
      setDeliveryRemains(calcRemains(deliveryDayjs.toDate()));
    };

    const intervalId: ReturnType<typeof setInterval> = setInterval(interval, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [cutOffDayjs.toDate().getTime(), deliveryDayjs.toDate().getTime()]);

  return {
    cutOffDate: cutOffDayjs.toDate(),
    deliveryDate: deliveryDayjs.toDate(),
    cutOffHoursKST: cutOffHoursKTS,
    deliveryHoursKST: deliveryHoursKTS,
    cutOffRemains: cutOffRemains,
    deliveryRemains: deliveryRemains,
    deliveryDayExpression: willDeliveryTomorrow ? expressionTomorrow : expressionToday,
    isPassedTodayCutOff: willDeliveryTomorrow,
  };
}
