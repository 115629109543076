import {
  Product,
  OptionStock,
  ProductOptionItem,
  ProductOptionItemResponse,
  ProductResponse,
  OptionStockResponse,
  ProductOptionResponse,
} from '~kup/models/Product.ts';
import replaceAt from '~/utils/replaceAt.ts';
import isEquivalentSetArray from '~/utils/isEquivalentSetArray.ts';
import removeAt from '~/utils/removeAt.ts';

import { getBasket, putBasketItem } from '~kup/controllers/order.ts';
import { PromotionResponse } from '~kup/models/Promotion.ts';
import { Contact, DeliveryAddress } from './types';

export interface BasketResponse {
  orderId?: string;
  merchantOrderId?: string;
  items: BasketProductItemResponse[];
  inputAddress?: string;
  orderAddress?: DeliveryAddress | null;
  customerContact?: Contact | null;
  customerName?: string;
  customerEmail?: string;
}

// model에서 컨트롤러 호툴하는 것이 적절할까...
export interface BasketProductItemResponse {
  id?: ProductOptionResponse['id'];
  productId: ProductResponse['id'];
  productName: ProductResponse['name'];
  productBrand: ProductResponse['brand'];
  productImageUrls: ProductResponse['imageUrls'];
  stock: OptionStockResponse;
  optionItemSelections: ProductOptionItemResponse[];
  quantity: number;
  promotionId?: PromotionResponse['id'] | undefined;
  isInPromotion?: boolean | undefined;
}

function isBasketProductItem(
  item: BasketProductItem | BasketProductItemResponse
): item is BasketProductItem {
  return !!(item as BasketProductItem).slugs;
}

export default class Basket {
  static EMPTY_BASKET = new Basket({ items: [] });

  static async renewBasket(raw: BasketResponse): Promise<Basket> {
    const renewed = await putBasketItem(raw);
    return new Basket(renewed);
  }

  static async retrieve(): Promise<Basket> {
    const basketResponse = await getBasket();
    if (!basketResponse || !basketResponse.items) return Basket.EMPTY_BASKET;
    return new Basket(basketResponse);
  }

  static combineItems(replacedItems: BasketProductItem[]) {
    const combineDuplicated = replacedItems.reduce(
      (result, item) => {
        if (result[item.key]) {
          const combinedQuantity = item.quantity + result[item.key].quantity;
          result[item.key] = result[item.key].setSafeQuantity(combinedQuantity);
          return result;
        }
        result[item.key] = item;
        return result;
      },
      {} as { [key: string]: BasketProductItem }
    );
    return Object.values(combineDuplicated);
  }

  static combineRawItems(replacedItems: BasketProductItemResponse[]) {
    const combineDuplicated = replacedItems.reduce(
      (result, item) => {
        const itemKey = item.productId + item.optionItemSelections.map((i) => i.slug).join();
        if (result[itemKey]) {
          const combinedQuantity = item.quantity + result[itemKey].quantity;
          result[itemKey].quantity = Math.min(result[itemKey].stock.quantity, combinedQuantity);
          return result;
        }
        result[itemKey] = item;
        return result;
      },
      {} as { [key: string]: BasketProductItemResponse }
    );
    return Object.values(combineDuplicated);
  }

  orderId?: string;
  merchantOrderId?: string;
  inputAddress?: string;
  orderAddress?: DeliveryAddress | null;
  customerContact?: Contact | null;
  customerName?: string;
  customerEmail?: string;
  raw: BasketResponse;
  public items: BasketProductItem[];
  private constructor(raw: BasketResponse) {
    this.orderId = raw.orderId;
    this.merchantOrderId = raw.merchantOrderId;
    this.items = raw.items.map((p) => new BasketProductItem(p));
    this.inputAddress = raw.inputAddress;
    this.orderAddress = raw.orderAddress;
    this.customerContact = raw.customerContact;
    this.customerName = raw.customerName;
    this.customerEmail = raw.customerEmail;
    this.raw = raw;
    Object.freeze(this.items);
  }

  public async sync(): Promise<Basket> {
    return Basket.retrieve();
  }

  public async addItem(item: BasketProductItem | BasketProductItemResponse): Promise<Basket> {
    const newItem = isBasketProductItem(item) ? item : new BasketProductItem(item);

    const exist = this.find(newItem.slugs);
    if (exist) {
      return this.replaceMatchingItem(exist.addQuantity(newItem.quantity));
    }

    const newProductItems = [...this.items, newItem];
    return Basket.renewBasket({ ...this.raw, items: newProductItems.map((i) => i.toRaw()) });
  }

  public async addItems(items: BasketProductItemResponse[]): Promise<Basket> {
    const newProductItems = items.map((i) =>
      isBasketProductItem(i) ? i : new BasketProductItem(i)
    );
    const overlapItems = newProductItems.filter((item) => this.find(item.slugs));
    const brandNewItems = newProductItems.filter((item) => !this.find(item.slugs));

    let replaceWithNew = this.items.map((exist) => {
      const found = overlapItems.find((newItem) => exist.isSameOptionItem(newItem));
      if (found && !found.isInPromotion) return exist.addQuantity(found.quantity);
      return exist;
    });

    this.items.map((exist) => {
      const found = newProductItems.find((newItem) => exist.isSameOptionItemInPromotion(newItem));
      if (found && !found.isSameOptionItem(exist))
        replaceWithNew = removeAt(this.items, (exists) => exists.isSameOptionItem(exist));
    });

    const combinedItems = [...replaceWithNew, ...brandNewItems];
    return Basket.renewBasket({ ...this.raw, items: combinedItems.map((i) => i.toRaw()) });
  }

  public async replaceMatchingItem(
    item: BasketProductItem | BasketProductItemResponse
  ): Promise<Basket> {
    const replaceItem = isBasketProductItem(item) ? item : new BasketProductItem(item);
    const newProductItems = replaceAt(this.items, replaceItem, (exists) =>
      exists.isSameOptionItem(replaceItem)
    );
    const combinedItems = Basket.combineItems(newProductItems);

    return Basket.renewBasket({ ...this.raw, items: combinedItems.map((i) => i.toRaw()) });
  }

  public async replaceAllMatchingItems(items: BasketProductItem[] | BasketProductItemResponse[]) {
    const separator = '/';
    const replaceItemMap = new Map(
      items
        .map((i) => (isBasketProductItem(i) ? i : new BasketProductItem(i)))
        .map((i) => [i.slugs.join(separator), i])
    );
    const replacedItems = this.items.map((i) => replaceItemMap.get(i.slugs.join(separator)) ?? i);
    const combinedItems = Basket.combineItems(replacedItems);

    return Basket.renewBasket({ ...this.raw, items: combinedItems.map((i) => i.toRaw()) });
  }

  public async replaceItem(
    prev: BasketProductItem | string[] | ((item: BasketProductItem, index: number) => boolean),
    next: BasketProductItem | BasketProductItemResponse
  ): Promise<Basket> {
    const replaceIndex = this.items.findIndex((item, index) => {
      if (typeof prev === 'function') {
        return prev(item, index);
      }
      if (Array.isArray(prev)) {
        return isEquivalentSetArray(item.slugs, prev);
      }
      return isEquivalentSetArray(item.slugs, prev.slugs);
    });

    return this.replaceItemAt(replaceIndex, next);
  }

  public async replaceItemAt(
    index: number,
    next: BasketProductItem | BasketProductItemResponse
  ): Promise<Basket> {
    const replaceable = isBasketProductItem(next) ? next : new BasketProductItem(next);
    const replacedItems = this.items.map((prev, idx) => (index === idx ? replaceable : prev));
    const combinedItems = Basket.combineItems(replacedItems);

    return Basket.renewBasket({
      ...this.raw,
      items: combinedItems.map((i) => i.toRaw()),
    });
  }

  public async removeItem(item: BasketProductItem): Promise<Basket> {
    const removedProductItems = removeAt(this.items, (exists) => exists.isSameOptionItem(item));
    return Basket.renewBasket({ ...this.raw, items: removedProductItems.map((i) => i.toRaw()) });
  }

  public async removeItems(items: BasketProductItem[]): Promise<Basket> {
    const slugs = items.map((i) => i.slugs);
    const removedProductItems = removeAt(
      this.items,
      (exists) => slugs.findIndex((slugs) => isEquivalentSetArray(slugs, exists.slugs)) >= 0
    );
    return Basket.renewBasket({ ...this.raw, items: removedProductItems.map((i) => i.toRaw()) });
  }

  // Method to clear the basket
  public async clearBasket(): Promise<Basket> {
    return Basket.renewBasket({ ...this.raw, items: [] });
  }

  public get amount() {
    return this.items
      .map(({ quantity, stock }) => stock.price * quantity)
      .reduce((a, b) => a + b, 0);
  }

  public get quantity() {
    return this.items.map(({ quantity }) => quantity).reduce((a, b) => a + b, 0);
  }

  public get availableAmount() {
    return this.items
      .map(({ availableQuantity, stock }) => stock.price * availableQuantity)
      .reduce((a, b) => a + b, 0);
  }

  public get availableQuantity() {
    return this.items.map(({ availableQuantity }) => availableQuantity).reduce((a, b) => a + b, 0);
  }

  public get count() {
    return this.items.length;
  }

  public get productCount() {
    return new Set(this.items.map(({ productId }) => productId)).size;
  }

  public find(slugs: string[]) {
    return this.items.find((item) => item.isSameOptionItem(slugs));
  }

  public findByProduct(productId: Product['id']) {
    return this.items.filter((item) => item.productId === productId);
  }

  public toRaw() {
    return this.raw;
  }
}

export class BasketProductItem {
  id: BasketProductItemResponse['id'];
  productId: Product['id'];
  productBrand: Product['brand'];
  productName: Product['name'];
  productImageUrls: Product['imageUrls'];
  stock: OptionStock;
  optionItemSelections: ProductOptionItem[];
  promotionId?: string;
  isInPromotion?: boolean | undefined;
  quantity: number;
  raw: BasketProductItemResponse;

  constructor(raw: BasketProductItemResponse) {
    this.id = raw.id;
    this.productId = raw.productId;
    this.productBrand = raw.productBrand;
    this.productName = raw.productName;
    this.productImageUrls = raw.productImageUrls;
    this.stock = new OptionStock(raw.stock);
    this.optionItemSelections = raw.optionItemSelections.map((os) => new ProductOptionItem(os));
    this.promotionId = raw.promotionId;
    this.isInPromotion = raw.isInPromotion;
    this.quantity = raw.quantity;
    this.raw = raw;
    Object.freeze(this);
  }

  get key(): string {
    return this.productId + this.slugs.join();
  }

  get thumbnail(): string {
    return (
      this.optionItemSelections
        .map((item) => item.thumbnail)
        .filter((thumbnail) => !!thumbnail)
        .at(-1) ?? this.productImageUrls[0]
    );
  }

  get slugs(): string[] {
    return this.optionItemSelections.map((item) => item.slug);
  }

  get optionSelectionNames(): string[] {
    return this.optionItemSelections.map((item) => `${item.name}`);
  }

  get optionSelectionName(): string {
    return this.optionSelectionNames.join(', ');
  }

  get amount(): number {
    return this.stock.price * this.quantity;
  }

  get availableQuantity(): number {
    return Math.min(this.stock.quantity, this.quantity);
  }

  get availableAmount(): number {
    return this.availableQuantity * this.stock.price;
  }

  toRaw(): BasketProductItemResponse {
    return this.raw;
  }

  toRequest(): BasketProductItemResponse {
    return this.toRaw();
  }

  setQuantity(quantity: number) {
    return new BasketProductItem({ ...this.raw, quantity });
  }

  setSafeQuantity(quantity: number) {
    return new BasketProductItem({
      ...this.raw,
      quantity: Math.min(quantity, this.stock.quantity),
    });
  }

  isSameOptionItem(itemOrSlugs: BasketProductItem | string[]) {
    const compareSlugs = Array.isArray(itemOrSlugs) ? itemOrSlugs : itemOrSlugs.slugs;
    return isEquivalentSetArray(this.slugs, compareSlugs);
  }

  isSameOptionItemInPromotion(newOne: BasketProductItem) {
    if (!newOne.promotionId) return false;
    return this.productId === newOne.productId && this.promotionId === newOne.promotionId;
  }

  addQuantity(quantity: number) {
    const addUpQuantity = Math.min(this.stock.quantity, this.quantity + quantity);
    return this.setQuantity(addUpQuantity);
  }
}
