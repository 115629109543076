import { Slice } from '~common/store';
import { IChatting, IChattingParticipant } from '~kint/models/Chatting.ts';

export interface ChattingSlice {
  isNotificationPermissionRequested: boolean;
  participant: IChattingParticipant | null;
  tourChatting: IChatting | null;
  token: string | null;
  stopChatRequestNotificationPermission: number;
  isChatNotificationDisabled: boolean;
  setIsChatNotificationDisabled: (isChatNotificationDisabled: boolean) => void;
  setStopChatRequestNotificationPermission: (stopChatRequestNotificationPermission: number) => void;
  setToken: (token: string | null) => void;
  setParticipant: (participant: ChattingSlice['participant']) => void;
  setTourChatting: (tourChatting: ChattingSlice['tourChatting']) => void;
  setIsNotificationPermissionRequested: (isNotificationPermissionRequested: boolean) => void;
}

export interface PersistedChattingSlice {
  isNotificationPermissionRequested: boolean;
  participant: IChattingParticipant | null;
  stopChatRequestNotificationPermission: number;
  isChatNotificationDisabled: boolean;
}

export const persistChattingSlice: (state: ChattingSlice) => PersistedChattingSlice = (
  state: ChattingSlice
) => ({
  isNotificationPermissionRequested: state.isNotificationPermissionRequested,
  participant: state.participant,
  stopChatRequestNotificationPermission: state.stopChatRequestNotificationPermission,
  isChatNotificationDisabled: state.isChatNotificationDisabled,
});

export const createChattingSlice: Slice<ChattingSlice, ChattingSlice> = (set) => ({
  isNotificationPermissionRequested: false,
  participant: null,
  tourChatting: null,
  token: null,
  stopChatRequestNotificationPermission: 0,
  isChatNotificationDisabled: false,
  setIsChatNotificationDisabled: (isChatNotificationDisabled: boolean) =>
    set({ isChatNotificationDisabled }),
  setStopChatRequestNotificationPermission: (stopChatRequestNotificationPermission: number) =>
    set({ stopChatRequestNotificationPermission }),
  setToken: (token: string | null) => set({ token }),
  setParticipant: (participant: ChattingSlice['participant']) => set({ participant }),
  setTourChatting: (tourChatting: ChattingSlice['tourChatting']) => set({ tourChatting }),
  setIsNotificationPermissionRequested: (isRequested: boolean) =>
    set({ isNotificationPermissionRequested: isRequested }),
});
