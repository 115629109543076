import { Slice } from '~common/store';

export interface CommonSlice {
  currentPage: { pathname: string; hash?: string; query?: string } | null;
  setCurrentPage: (pathname: string, hash?: string, query?: string) => void;
}

export interface PersistedCommonSlice {
  // currentPage는 Persist하지 않음, 이전 페이지 Referrer의 조회목적
}

export const persistCommonSlice: (state: CommonSlice) => PersistedCommonSlice = () => ({});

export const createCommonSlice: Slice<CommonSlice, CommonSlice> = (setState) => ({
  currentPage: null,
  setCurrentPage: (pathname: string, hash?: string, query?: string) => {
    const currentPage = { pathname, hash: hash, query: query };
    setState(() => ({ currentPage }));
  },
});
