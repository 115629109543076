import ModalPopup from '~/components/ModalPopup.tsx';
import FillBox from '~/components/FillBox.tsx';
import ButtonBase from '~/components/ButtonBase';
import Typography from '~/components/Typography.tsx';
import Icon from '~/components/Icon';
import Container from '~/components/Container.tsx';
import Button from '~/components/Button.tsx';
import useKintStore from '~kint/store';
import RecallCsChatting from '~/components/RecallCsChatting.tsx';
import { useTranslation } from 'react-i18next';

export type CSPopupProps = {
  open: boolean;
  onClose: () => void;
  enableChat?: boolean;
  email: string;
};

export default function CSPopup(props: CSPopupProps) {
  const { open, onClose, email, enableChat = false } = props;
  const { i18n } = useTranslation();
  const { participant } = useKintStore((state) => ({ participant: state.participant }));
  const handleCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).catch((err) => {
        console.error(err);
      });
    } else {
      // navigator.clipboard가 지원되지 않는 경우
      fallbackCopyTextToClipboard(email);
    }
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // 화면에서 사라지지 않도록 고정
    textArea.style.left = '-9999px'; // 화면에 보이지 않도록 위치 설정
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error(err);
    }
    document.body.removeChild(textArea);
  };

  const sendEmail = () => {
    if (!participant) return;
    const subject = `Inquiry - ${participant.nameEn}${participant.id}`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };

  return (
    <ModalPopup open={open} onClose={onClose} title={i18n.t('common-help-doYouNeedHelp')}>
      <Container
        px={'20px'}
        stackProps={{
          column: true,
          gap: '8px',
        }}
      >
        <Typography variant={'BaseS'} color={'typography.secondary'}>
          {i18n.t('component-csPopup-welcomeMessage')}
        </Typography>
        <ButtonBase sx={{ width: '100%' }} onClick={handleCopyClick}>
          <FillBox
            fullWidth
            color={'fill'}
            lead={<Icon color={'primary'}>content_copy</Icon>}
            sx={{
              width: '100%',
            }}
          >
            <Typography textAlign={'left'}>{email}</Typography>
          </FillBox>
        </ButtonBase>
      </Container>
      <Container
        py={'20px'}
        px={'20px'}
        stackProps={{
          gap: '8px',
        }}
      >
        <Button fullWidth disabled={!participant} onClick={sendEmail} size={'medium'}>
          {i18n.t('component-csPopup-sendEmail')}
        </Button>
        {enableChat && (
          <RecallCsChatting service={email.includes('kup') ? 'KUP' : 'CS'} color={'fill'} />
        )}
      </Container>
    </ModalPopup>
  );
}
