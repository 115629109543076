import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type StateSetter<S> = (prevState: S) => S;
type SetStateAction<S> = S | StateSetter<S>;
type KeepStateSetter = (uri: string, key: string, actionValue: SetStateAction<unknown>) => void;
type KeepStateGetter = (uri: string, key: string, ifNot?: unknown) => unknown;

type PathState = {
  [key: string]: unknown;
};

type GlobalState = {
  [uri: string]: PathState;
};

type KeepState = [KeepStateGetter, KeepStateSetter, GlobalState];

export const KeepStateContext = createContext<KeepState | null>(null);

type KeepStateProviderProps = { children: ReactNode };

export function KeepStateProvider(props: KeepStateProviderProps) {
  const { children } = props;
  const [globalState, setGlobalState] = useState<GlobalState>({});

  const setter: KeepStateSetter = useCallback(
    (uri, key, actionValue) => {
      setGlobalState((prevState) => ({
        ...prevState,
        [uri]: {
          ...(prevState[uri] ?? {}),
          [key]: isStateSetter(actionValue) ? actionValue(prevState[uri]?.[key]) : actionValue,
        },
      }));
    },
    [setGlobalState]
  );

  const getter: KeepStateGetter = (uri, key, ifNot) => {
    if (uri in globalState && key in globalState[uri]) return globalState[uri][key];
    return ifNot;
  };

  return (
    <KeepStateContext.Provider value={[getter, setter, globalState]}>
      {children}
    </KeepStateContext.Provider>
  );
}

export function useKeepStateContext() {
  return useContext(KeepStateContext);
}

function isStateSetter<S>(action: SetStateAction<S>): action is StateSetter<S> {
  return typeof action === 'function';
}
