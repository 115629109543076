import { useCallback, useLayoutEffect } from 'react';
import { DiscoverySimple } from '~kup/models/Discovery.ts';
import { getDiscoveries } from '~kup/controllers/discovery.ts';
import { useKeepState } from '~/hooks/useKeepState.ts';

type PageNumber = number; // 1 보다 크거나 같은 정수;

type UseDiscoveries = {
  discoveries: DiscoverySimple[];
  hasNextPage: boolean;
  page: number;
  next: (targetPage?: number) => void;
  prev: () => void;
  total: number;
};

type DiscoveriesPagination = {
  startPage?: PageNumber;
  pageSize?: number;
  continuous?: boolean;
};

export default function useDiscoveries(
  category: string,
  pagination: DiscoveriesPagination
): UseDiscoveries {
  const { startPage = 1, pageSize = 6, continuous = true } = pagination;
  const [currentPage, setCurrentPage] = useKeepState<PageNumber>(
    `discoveries-${category}-currentPage`,
    startPage
  );
  const [discoveries, setDiscoveries] = useKeepState<DiscoverySimple[]>(
    `discoveries-${category}`,
    []
  );
  const [hasNextPage, setHasNextPage] = useKeepState<boolean>(
    `discoveries-${category}-hasNextPage`,
    false
  );
  const [total, setTotal] = useKeepState<number>(`discoveries-${category}-total`, 0);

  const callDiscoveries = useCallback(
    (category: string, page: PageNumber, pageSize: number, continuous: boolean) => {
      getDiscoveries({ category, pageSize, page }).then(({ discoveries, hasNextPage, meta }) => {
        setDiscoveries((prev) => {
          if (continuous && page !== startPage) {
            return [...prev, ...discoveries];
          }
          return [...discoveries];
        });
        setHasNextPage(hasNextPage);
        setCurrentPage(page);
        setTotal(meta.totalCount);
      });
    },
    [startPage, setCurrentPage, setDiscoveries, setTotal, setHasNextPage]
  );

  const next = useCallback(
    (targetPage?: number) => {
      if (targetPage) {
        callDiscoveries(category, targetPage, pageSize, continuous);
        return;
      }
      if (!hasNextPage) return;
      callDiscoveries(category, currentPage + 1, pageSize, continuous);
    },
    [hasNextPage, callDiscoveries, category, currentPage, pageSize, continuous]
  );

  const prev = useCallback(() => {
    const hasPrev = currentPage - 1 > 1;
    if (!hasPrev) return;
    callDiscoveries(category, currentPage - 1, pageSize, continuous);
  }, [currentPage, callDiscoveries, category, pageSize, continuous]);

  useLayoutEffect(() => {
    if (currentPage === startPage)
      //최초 초기화 콜 무조건 실행할 경우 keepState가 무의미함
      callDiscoveries(category, startPage, pageSize, continuous);
  }, [category, startPage, pageSize, continuous, callDiscoveries, currentPage]);

  return {
    discoveries,
    hasNextPage,
    page: currentPage,
    next,
    prev,
    total,
  };
}
