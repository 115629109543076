import { FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
// https://firebase.google.com/docs/web/setup#available-libraries

export class FAnalytics {
  app: FirebaseApp;
  analytics: Analytics;

  constructor(app: FirebaseApp) {
    this.app = app;
    this.analytics = getAnalytics(app);
  }
}
