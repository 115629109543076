import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
  ReservationSlice,
  persistReservationSlice,
  createReservationSlice,
  mergeReservationPersistedSlice,
} from '~kint/store/slice/reservationSlice.ts';
import { TourSlice, persistTourSlice, createTourSlice } from '~kint/store/slice/tourSice.ts';
import {
  ChattingSlice,
  persistChattingSlice,
  createChattingSlice,
} from '~kint/store/slice/chattingSlice';

export const useKintStore = create<ReservationSlice & TourSlice & ChattingSlice>()(
  immer(
    persist(
      (...args) => ({
        ...createReservationSlice(...args),
        ...createTourSlice(...args),
        ...createChattingSlice(...args),
      }),
      {
        name: 'kint-storage',
        partialize: (state) => ({
          ...persistReservationSlice(state),
          ...persistTourSlice(state),
          ...persistChattingSlice(state),
        }),
        merge: (persistedState, currentState) => ({
          ...currentState,
          ...(persistedState ?? {}),
          ...mergeReservationPersistedSlice(persistedState, currentState),
        }),
      }
    )
  )
);

export default useKintStore;
