import { Address } from '~kup/models/types.ts';
import { useCallback, useState } from 'react';
import { searchAddress } from '~kup/controllers/search.ts';

type useSearchAddress = {
  loading: boolean;
  fetchAddresses: (search: string) => Promise<Address[]>;
};

export default function useSearchAddress(): useSearchAddress {
  const [loading, setLoading] = useState(false);

  const fetchAddresses = useCallback(async (search: string) => {
    setLoading(true);
    try {
      return await searchAddress(search);
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    fetchAddresses,
  };
}
