import { ReactNode } from 'react';
import ModalPopover, { ModalPopoverProps } from '~/components/ModalPopover.tsx';
import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button';

export type DialogProps = Omit<ModalPopoverProps<'div'>, 'children'> & {
  title?: string;
  children: ReactNode;
  cancel?: string;
  onCancel?: () => void;
  confirm?: string;
  onConfirm?: () => void;
};

export default function Dialog(props: DialogProps) {
  const {
    title,
    children,
    open,
    onClose,
    cancel = 'Cancel',
    onCancel,
    confirm = 'Confirm',
    onConfirm,
    ...popoverProps
  } = props;
  const childrenElement =
    typeof children === 'string' ? (
      <Typography variant={'FootNote'} color={'typography.secondary'}>
        {children}
      </Typography>
    ) : (
      children
    );
  return (
    <ModalPopover
      open={open}
      onClose={onClose}
      title={title}
      fixedBottomPosition={'sticky'}
      fixedBottom={
        <Container
          py={'28px'}
          px={'20px'}
          background={'paper'}
          sx={() => ({ zIndex: 1 })}
          stackProps={{
            row: true,
            justifyContent: 'stretch',
            gap: '14px',
          }}
        >
          {onCancel && (
            <Button
              fullWidth
              sx={{ flex: 0, whiteSpace: 'nowrap' }}
              variant={'text'}
              color={'error'}
              onClick={onCancel}
              size={'medium'}
            >
              {cancel}
            </Button>
          )}
          {onConfirm && (
            <Button
              fullWidth
              sx={{ flex: 2 }}
              onClick={onConfirm}
              variant={'contained'}
              color={'primary'}
              size={'medium'}
            >
              {confirm}
            </Button>
          )}
        </Container>
      }
      {...popoverProps}
    >
      <Container maxWidth={false}>{childrenElement}</Container>
    </ModalPopover>
  );
}
