import { FirebaseApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  MessagePayload,
  Messaging,
  onMessage,
  Unsubscribe,
} from 'firebase/messaging';

export class FMessaging {
  app: FirebaseApp;
  messaging: Messaging | null;
  private rn: boolean;

  constructor(app: FirebaseApp) {
    this.app = app;
    this.messaging = null;
    this.initMessaging().catch(console.error);
    this.rn = !!window?.ReactNativeWebView;
  }

  private async initMessaging(): Promise<void> {
    if (this.rn) return; // RN에 위임
    const supported = await isSupported();
    if (supported) {
      this.messaging = getMessaging(this.app);
    }
  }

  private async ensureMessagingInitialized(): Promise<boolean> {
    if (this.rn) return true; // RN에 위임
    if (!this.messaging) {
      await this.initMessaging();
    }
    return this.messaging !== null;
  }

  async isSupported(): Promise<boolean> {
    if (this.rn) return true; // RN에 위임
    return await isSupported();
  }

  private async requestPermissionRN(): Promise<boolean> {
    if (this.rn && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{ granted: boolean }>(
        'FCM_REQUEST_PERMISSION'
      );
      return jobResult.granted ?? false;
    }
    return false;
  }

  private async getTokenRn(): Promise<string | null> {
    if (this.rn && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{ token: string | null }>(
        'FCM_GET_TOKEN'
      );
      return jobResult.token ?? null;
    }
    return null;
  }

  private async requestTokenRn(): Promise<string | null> {
    if (this.rn && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{ token: string | null }>(
        'FCM_REQUEST_TOKEN'
      );
      return jobResult.token ?? null;
    }
    return null;
  }

  async isPermissionGranted(): Promise<boolean> {
    if (this.rn) return await this.requestPermissionRN(); // RN에 위임
    return window?.Notification?.permission === 'granted';
  }

  async requestPermission(): Promise<boolean> {
    alert('request');
    if (this.rn) {
      return await this.requestPermissionRN(); // RN에 위임
    }

    if (!(await this.isSupported())) return false;
    if (await this.isPermissionGranted()) return true;

    try {
      return 'granted' === (await Notification.requestPermission());
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getToken(): Promise<string | null> {
    if (this.rn) {
      return await this.getTokenRn(); // RN에 위임
    }

    const messagingInitialized = await this.ensureMessagingInitialized();
    if (!messagingInitialized || !(await this.isPermissionGranted())) {
      return null;
    }
    try {
      if (!this.messaging) return null;
      return await getToken(this.messaging, {
        vapidKey: import.meta.env.VITE_VITE_FIREBASE_VAPID_KEY,
      });
    } catch (e) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          if (this.messaging)
            getToken(this.messaging, {
              vapidKey: import.meta.env.VITE_VITE_FIREBASE_VAPID_KEY,
            }).then(resolve);
        }, 500);
      });
    }
  }

  async requestToken(): Promise<string | null> {
    if (this.rn) {
      return await this.requestTokenRn();
    }

    if (!(await this.ensureMessagingInitialized())) {
      return null;
    }
    if (!(await this.isPermissionGranted())) {
      const isGranted = await this.requestPermission();
      if (!isGranted) return null;
    }
    return await this.getToken();
  }

  onMessage(listener: (payload: MessagePayload) => void): Unsubscribe {
    if (this.rn) {
      window.addEventListener('message', console.log); //webview message;
    }
    if (!this.messaging) {
      return () => {};
    }
    return onMessage(this.messaging, listener);
  }
}
