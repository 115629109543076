import { Discovery } from '~kup/models/Discovery.ts';
import { useEffect, useState } from 'react';
import { getDiscovery } from '~kup/controllers/discovery.ts';

export type UseDiscovery = {
  discovery: Discovery | null;
};

export default function useDiscovery(discoveryId: string): UseDiscovery {
  const [discovery, setDiscovery] = useState<Discovery | null>(null);
  useEffect(() => {
    getDiscovery(discoveryId).then((discovery) => setDiscovery(discovery));
  }, []);
  return {
    discovery,
  };
}
