import { Fragment, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PAYMENT_METHOD } from '~/pages/shop/constants';
import Page from '~/components/Page';
import Area from '~/components/Area.tsx';
import Card from '~/components/Card';
import Stack from '~/components/Stack.tsx';
import Headline from '~/components/Headline.tsx';
import Button, { ButtonProps } from '~/components/Button.tsx';
import Typography from '~/components/Typography.tsx';
import RadioGroup from '~/components/RadioGroup.tsx';
import useKupStore from '~kup/store';
import OrderItem from '~/pages/shop/order/components/OrderItem.tsx';
import Container, { ContainerProps } from '~/components/Container.tsx';
import useDelivery from '~/pages/shop/hooks/useDelivery.ts';
import Divider from '~/components/Divider.tsx';
import Checkbox from '~/components/Checkbox.tsx';
import Icon from '~/components/Icon';
import { useSnackBar } from '~/contexts/SnackBarContext.tsx';
import TextField, { TextFieldProps } from '~/components/TextField.tsx';
import { OrderItemState } from '~/pages/shop/types';
import OutOfStockPopover from '~/pages/shop/order/components/OutOfStockPopover.tsx';
import useLoading from '~/hooks/useLoading.tsx';
import { OrderPostData } from '~kup/models/Order.ts';
import useUsableCoupons from '~/hooks/useUsableCoupons.ts';
import { useTranslation } from 'react-i18next';
import { initialPayment } from '~/services/kup/controllers/portone';
import Basket from '~/services/kup/models/Basket';

export default function ShopOrderPage() {
  const location = useLocation();
  const {
    currency,
    basket,
    contact,
    customerName,
    customerEmail,
    paymentMethod,
    inputAddress,
    deliveryAddress,
    appliedCoupon,
    setDeliveryAddress,
    setCustomerName,
    setCustomerEmail,
    setPaymentMethod,
    setAppliedCoupon,
  } = useKupStore((state) => ({
    currency: state.currency,
    basket: state.basket,
    contact: state.contact,
    customerName: state.customerName,
    customerEmail: state.customerEmail,
    paymentMethod: state.paymentMethod,
    inputAddress: state.inputAddress,
    deliveryAddress: state.deliveryAddress,
    appliedCoupon: state.appliedCoupon,
    setDeliveryAddress: state.setDeliveryAddress,
    setCustomerName: state.setCustomerName,
    setCustomerEmail: state.setCustomerEmail,
    setPaymentMethod: state.setPaymentMethod,
    setAppliedCoupon: state.setAppliedCoupon,
  }));
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const orderItemState = (location?.state as OrderItemState) ?? {
    itemKeys: [],
  };
  const orderItems = basket.items.filter((i) => orderItemState.orderItemKeys.includes(i.key));
  const orderAmount = orderItems.map((i) => i.availableAmount).reduce((a, b) => a + b, 0);

  const { i18n } = useTranslation();

  const delivery = useDelivery(orderAmount);
  const { usableCoupons } = useUsableCoupons();

  const addressRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const customerRef = useRef<HTMLDivElement>(null);

  const [checked, setChecked] = useState(false);
  const { loading } = useLoading();

  const totalAmount = orderAmount + delivery.fee - (appliedCoupon?.applicableDiscountAmount ?? 0);

  const isQualified =
    deliveryAddress && contact && checked && orderItems.length > 0 && customerName && customerEmail;

  const outOfStockItemList = orderItems.filter((i) => i.stock.quantity < i.quantity);

  useEffect(() => {
    if (contact) {
      Basket.renewBasket({
        ...basket.raw,
        customerContact: contact,
        customerName,
        customerEmail,
        inputAddress: inputAddress ?? undefined,
        orderAddress: deliveryAddress ?? undefined,
      });
    }
  }, []);

  const handelCustomerName: TextFieldProps['onChange'] = (e) => {
    setCustomerName(e.target.value);
  };

  const handleCustomerEmail: TextFieldProps['onChange'] = (e) => {
    setCustomerEmail(e.target.value);
  };

  const handlePlaceOrder: ButtonProps<'button'>['onClick'] = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (inputAddress && deliveryAddress && contact) {
      const orderRequest = {
        orderId: basket.orderId,
        inputAddress,
        deliveryAddress,
        contact,
        customerName,
        customerEmail,
        paymentMethod,
        orderItems,
        orderAmount,
        deliveryFee: delivery.fee,
        totalAmount,
        appliedCoupon,
      } as OrderPostData;

      //Todo popup 권한 Pre-check 알림
      await initialPayment(orderRequest);
    }
  };

  const handleChangeDeliveryRequest: TextFieldProps['onChange'] = (e) => {
    if (deliveryAddress) {
      setDeliveryAddress({
        ...deliveryAddress,
        request: e.target.value ?? '',
      });
    }
  };
  const handleChangeAddressDetail: TextFieldProps['onChange'] = (e) => {
    if (deliveryAddress) {
      setDeliveryAddress({
        ...deliveryAddress,
        detail: e.target.value ?? '',
      });
    }
  };

  const handleClickButtonBack: ContainerProps<'div'>['onClick'] = () => {
    if (!deliveryAddress) {
      const moveToAddress = () => {
        if (addressRef.current) {
          addressRef.current?.scrollIntoView({ behavior: 'smooth' });
          addressRef.current.style.animation = 'vibration 3s';
          setTimeout(() => {
            if (addressRef?.current) addressRef.current.style.animation = '';
          }, 3000);
        }
      };
      showSnackBar({
        message: i18n.t('shop-order-enterAddress'), // Please enter your address.
        lift: 120,
      });
      moveToAddress();
      return;
    }

    if (!contact) {
      const moveToContact = () => {
        if (contactRef.current) {
          contactRef.current?.scrollIntoView({ behavior: 'smooth' });
          contactRef.current.style.animation = 'vibration 3s';
          setTimeout(() => {
            if (contactRef?.current) contactRef.current.style.animation = '';
          }, 3000);
        }
      };
      showSnackBar({
        message: i18n.t('shop-order-enterContact'), // Please enter your contact.
        lift: 120,
      });
      moveToContact();
      return;
    }

    if (!customerName || !customerEmail) {
      const moveToCustomer = () => {
        if (customerRef.current) {
          customerRef.current?.scrollIntoView({ behavior: 'smooth' });
          customerRef.current.style.animation = 'vibration 3s';
          setTimeout(() => {
            if (customerRef?.current) customerRef.current.style.animation = '';
          }, 3000);
        }
      };
      showSnackBar({
        message: i18n.t('shop-order-enterNameEmail'), // Please enter your name and email.
        lift: 120,
      });
      moveToCustomer();
      return;
    }

    if (!checked) {
      showSnackBar({
        lift: 120,
        message: i18n.t('shop-order-confirmCondition'), // Please confirm the condition.
      });
      return;
    }
  };

  if (!orderItems?.length) return <Navigate to={'/shop/basket'} replace />;

  const availableCouponCount = usableCoupons.filter((coupon) => coupon.isAvailable).length;
  return (
    <>
      <Page
        type={'page'}
        name={
          i18n.t('shop-order-order') // Order
        }
        backgroundColor={'fill'}
        fixedBottomPosition={'sticky'}
        fixedBottom={
          <Container bottom background={'paper'} sx={() => ({ zIndex: 1 })}>
            <Container py={'12px'} px={'20px'}>
              <Checkbox
                disableRipple
                checked={checked}
                onChange={(_, checked) => {
                  setChecked(checked);
                }}
                sx={{
                  zIndex: 999,
                }}
                width={'28px'}
                type={'line'}
                label={
                  <Typography variant={'caption'} color={'typography.secondary'}>
                    {
                      i18n.t('shop-order-agreeToTerms') // I agree to the
                    }
                    <a href="/orderagreements" target={'_blank'} style={{ color: 'inherit ' }}>
                      {
                        i18n.t('shop-order-agreements') // agreements
                      }
                    </a>
                  </Typography>
                }
              />
            </Container>
            <Container pb={'12px'} px={'20px'} onClick={handleClickButtonBack}>
              <Button
                fullWidth
                disabled={!isQualified}
                loading={loading}
                variant={'contained'}
                onClick={handlePlaceOrder}
              >
                {
                  i18n.t('shop-order-placeOrder') // Place Order
                }
              </Button>
            </Container>
          </Container>
        }
      >
        <Stack column>
          <div id={'delivery-address'} ref={addressRef} style={{ paddingTop: '16px' }}>
            <Area
              title={
                i18n.t('shop-order-deliveryInfo') // Delivery Info
              }
              background={'transparent'}
              containerProps={{
                px: '20px',
                stackProps: {
                  gap: '12px',
                },
              }}
            >
              <Card>
                {deliveryAddress ? (
                  <Stack column gap={'8px'}>
                    <Stack row gap={'4px'}>
                      <Headline
                        flex={1}
                        headline={deliveryAddress.address.displayName.text}
                        description={deliveryAddress.address.formattedAddress}
                      />

                      <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'small'}
                        onClick={() => {
                          navigate('/shop/address');
                        }}
                      >
                        {
                          i18n.t('shop-order-modify') // Modify
                        }
                      </Button>
                    </Stack>
                    <Container
                      py={'8px'}
                      stackProps={{
                        gap: '8px',
                      }}
                    >
                      <TextField
                        fullWidth
                        size={'small'}
                        value={deliveryAddress.detail}
                        onChange={handleChangeAddressDetail}
                        placeholder={
                          i18n.t('shop-order-detailAddress') // Detail address information
                        }
                      />
                      <TextField
                        fullWidth
                        size={'small'}
                        value={deliveryAddress.request}
                        onChange={handleChangeDeliveryRequest}
                        placeholder={
                          i18n.t('shop-order-instructionRequest') // Instruction or request for delivery
                        }
                      />
                    </Container>
                  </Stack>
                ) : (
                  <Stack column gap={'8px'}>
                    <Stack row>
                      <Headline
                        flex={1}
                        headline={
                          i18n.t('shop-order-hotelStay') // Which hotel are you stay?
                        }
                        description={
                          i18n.t('shop-order-letMeKnow') // Let me know for delivery
                        }
                      />

                      <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'small'}
                        onClick={() => {
                          navigate('/shop/address');
                        }}
                      >
                        {
                          i18n.t('shop-order-search') // Search
                        }
                      </Button>
                    </Stack>

                    <Typography variant={'caption'} color={'typography.tertiary'}>
                      {
                        i18n.t('shop-order-checkAddress') // Please make sure if the address is correct.
                      }
                    </Typography>
                  </Stack>
                )}
              </Card>
            </Area>
          </div>
          <div id={'contact-info'} ref={contactRef} style={{ paddingTop: '16px' }}>
            <Area
              title={
                i18n.t('shop-order-contactInfo') // Contact Info
              }
              background={'transparent'}
              containerProps={{ px: '20px' }}
            >
              <Card id={'contact-info'}>
                <Stack row alignItems={'center'} gap={'14px'}>
                  {contact ? (
                    <>
                      <Icon width={'24px'}>{contact.channel}</Icon>
                      <Headline
                        flex={1}
                        size={'small'}
                        headline={contact.identifier}
                        subHeadline={contact.channel}
                      />
                    </>
                  ) : (
                    <Typography flex={1} variant={'BaseM_B'}>
                      {
                        i18n.t('shop-order-reachYou') // How can we reach you?
                      }
                    </Typography>
                  )}
                  <Button
                    size={'small'}
                    color={contact !== null ? 'fill' : 'primary'}
                    onClick={() => {
                      navigate('/shop/contact');
                    }}
                  >
                    {
                      contact
                        ? i18n.t('shop-order-modify') // Modify
                        : i18n.t('shop-order-setting') // Setting
                    }
                  </Button>
                </Stack>
              </Card>
            </Area>
          </div>

          <div id={'customer-info'} ref={customerRef} style={{ paddingTop: '16px' }}>
            <Area
              title={
                i18n.t('shop-order-orderInfo') // Order Info
              }
              background={'transparent'}
              containerProps={{
                px: '20px',
              }}
            >
              <Card>
                <Container
                  py={'8px'}
                  stackProps={{
                    gap: '8px',
                  }}
                >
                  <TextField
                    fullWidth
                    size={'small'}
                    value={customerName}
                    onChange={handelCustomerName}
                    placeholder={
                      i18n.t('shop-order-receiverName') // Name to receive order info
                    }
                  />
                  <TextField
                    fullWidth
                    size={'small'}
                    value={customerEmail}
                    onChange={handleCustomerEmail}
                    placeholder={
                      i18n.t('shop-order-receiverEmail') // Email to receive order info
                    }
                  />
                  <Typography variant={'caption'} color={'typography.tertiary'}>
                    {
                      i18n.t('shop-order-invoiceEmail') // After confirmation, the invoice will be emailed to you
                    }
                  </Typography>
                </Container>
              </Card>
            </Area>
          </div>

          <Area
            title={
              i18n.t('shop-order-payment') // Payment
            }
            background={'transparent'}
            containerProps={{
              px: '20px',
            }}
          >
            <Card>
              <RadioGroup
                value={paymentMethod}
                onChange={(_, value) => {
                  setPaymentMethod(value);
                }}
                radios={PAYMENT_METHOD.map((PM) => ({
                  ...PM,
                  headline: i18n.t(`shop-constants-${PM.headline}`),
                }))}
              />
            </Card>
          </Area>

          <Area
            title={
              i18n.t('shop-order-coupon') // Coupon
            }
            background={'transparent'}
            containerProps={{
              px: '20px',
            }}
          >
            <Card>
              <TextField
                fullWidth
                placeholder={
                  appliedCoupon
                    ? i18n.t('shop-order-appliedCoupon', {
                        appliedCouponName: appliedCoupon.coupon.name,
                      }) // {appliedCoupon.coupon.name} is applied
                    : i18n.t('shop-order-availableCoupons', {
                        availableCouponCount: availableCouponCount,
                      }) // {availableCouponCount} coupon{availableCouponCount > 1 ? 's' : ''} available for use
                }
                onFocus={() => {
                  navigate('/shop/coupon', { state: { usableCoupons } });
                }}
                onClear={() => {
                  setAppliedCoupon(null);
                }}
              />
            </Card>
          </Area>

          <Area
            title={i18n.t('shop-order-totalItems', {
              totalItems: orderItems.length,
            })}
            containerProps={{
              px: '20px',
              pb: '20px',
            }}
          >
            <Card
              contentProps={{
                sx: { py: '4px', px: 0 },
              }}
            >
              <Container
                stackProps={{
                  column: true,
                }}
              >
                {orderItems.map((oi, idx, list) => (
                  <Fragment key={oi.key}>
                    <OrderItem item={oi} />
                    {list.length - 1 !== idx ? <Divider type={'line'} color={'fill'} /> : null}
                  </Fragment>
                ))}
              </Container>
            </Card>
          </Area>

          <Area
            title={
              i18n.t('shop-order-toBePaid') // To be paid
            }
            background={'paper'}
            pt={'8px'}
          >
            <Container>
              <Stack column>
                <Container
                  px={'20px'}
                  py={'12px'}
                  stackProps={{
                    row: true,
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Typography flex={1} textAlign={'left'} color={'typography.secondary'}>
                    {
                      i18n.t('shop-order-itemCost') // Item Cost
                    }
                  </Typography>
                  <Typography flex={1} variant={'BaseS_B'} textAlign={'right'}>
                    {orderAmount.toLocaleString()} {currency}
                  </Typography>
                </Container>

                <Container
                  px={'20px'}
                  py={'12px'}
                  stackProps={{
                    justifyContent: 'end',
                  }}
                >
                  <Container
                    stackProps={{
                      row: true,
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Typography flex={1} textAlign={'left'} color={'typography.secondary'}>
                      {
                        i18n.t('shop-order-deliveryFee') // Delivery Fee
                      }
                    </Typography>
                    <Typography variant={'BaseS_B'} textAlign={'right'}>
                      {delivery.fee === 0
                        ? i18n.t('shop-order-free')
                        : `${delivery.fee.toLocaleString()}${currency}`}
                    </Typography>
                  </Container>
                  {delivery.fee > 0 && (
                    <Typography
                      variant={'caption'}
                      color={'typography.tertiary'}
                      textAlign={'right'}
                    >
                      {
                        i18n.t('shop-order-refundLimit', { currency }) // Refund delivery would be more than 100,000 {currency}
                      }
                    </Typography>
                  )}
                </Container>
                {appliedCoupon?.applicableDiscountAmount && (
                  <Container
                    px={'20px'}
                    py={'12px'}
                    stackProps={{
                      justifyContent: 'end',
                    }}
                  >
                    <Container
                      stackProps={{
                        row: true,
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Typography flex={1} textAlign={'left'} color={'typography.secondary'}>
                        {
                          i18n.t('shop-order-couponLabel') // Coupon
                        }
                      </Typography>
                      <Typography variant={'BaseS_B'} textAlign={'right'}>
                        - {appliedCoupon.applicableDiscountAmount.toLocaleString()} {currency}
                      </Typography>
                    </Container>
                    <Typography
                      variant={'caption'}
                      color={'typography.tertiary'}
                      textAlign={'right'}
                    >
                      {
                        i18n.t('shop-order-appliedCouponMessage', {
                          appliedCouponName: appliedCoupon.coupon.name,
                        }) // A {appliedCoupon.coupon.name} coupon is applied
                      }
                    </Typography>
                  </Container>
                )}
                <Divider type={'line'} color={'fill'} />
              </Stack>
              <Container
                py={'12px'}
                px={'20px'}
                stackProps={{
                  row: true,
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant={'BaseM_B'}
                  flex={1}
                  textAlign={'left'}
                  color={'typography.secondary'}
                >
                  {
                    i18n.t('shop-order-total') // TOTAL
                  }
                </Typography>
                <Typography variant={'BaseM_B'} color={'primary'} flex={1} textAlign={'right'}>
                  {totalAmount.toLocaleString()} {currency}
                </Typography>
                <Divider type={'box'} color={'fill'} />
              </Container>
            </Container>
          </Area>
        </Stack>
      </Page>
      <OutOfStockPopover
        open={outOfStockItemList.length > 0}
        outOfStockItems={outOfStockItemList}
      />
    </>
  );
}
