import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '~/components/Box.tsx';
import usePromotions from '~/hooks/usePromotions.ts';
import Slide from '~/components/Slide';

export default function PromotionBanner({ timeout, beginAt }: { timeout: number; beginAt: Date }) {
  const [index, setIndex] = useState<number>(0);
  const timeoutMillsecs = timeout * (index + 1) * 1000;
  const endAt = new Date(beginAt.getTime() + timeoutMillsecs);

  const { promotions } = usePromotions({ isHighlight: true });
  const [remainTimeout, setRemainTimeout] = useState<number>(timeoutMillsecs);

  const [closeTransitionEnd, setCloseTransitionEnd] = useState<boolean>(false);

  const promotionImages =
    promotions
      ?.map((p) => p.promotionImages)
      .flat(1)
      .filter((i) => i.type.toUpperCase() === 'CHAT') ?? [];
  const promotionImage = promotionImages[index];

  const hidden = !promotionImage;

  useEffect(() => {
    if (endAt.getTime() - Date.now() < 0) setIndex(promotionImages.length); // end
    const intervalId = setInterval(() => {
      const remainTime = endAt.getTime() - Date.now();
      setRemainTimeout(remainTime);
      if (remainTime <= 0) {
        setIndex((idx) => idx + 1);
      }
    }, 1000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [endAt.getTime(), promotionImages.length]);

  if (!promotionImage) return null;
  if (!promotionImage.imageUrl || !promotionImage.link) return null;
  if (closeTransitionEnd) return null;

  return (
    <Slide
      in={!hidden}
      appear={false}
      timeout={1000}
      onTransitionEnd={() => {
        if (hidden) {
          setCloseTransitionEnd(true);
        }
      }}
    >
      <Box
        sx={(theme) => ({
          position: 'sticky',
          top: '52px',
          zIndex: 500,
          width: '100%',
          display: closeTransitionEnd ? 'none' : 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme.palette.background.paper,
          padding: '4px',
        })}
      >
        <Link to={promotionImage.link} style={{ position: 'relative' }}>
          <img src={promotionImage.imageUrl} width={'100%'} style={{ maxHeight: '80px' }} />
          <span
            style={{
              position: 'absolute',
              zIndex: 501,
              right: '8px',
              top: '8px',
              width: '18px',
              height: '18px',
              borderRadius: '18px',
              fontSize: '10px',
              color: 'white',
              border: 'solid 2px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0.8,
            }}
          >
            {Math.ceil(Math.abs(remainTimeout / 1000))}
          </span>
        </Link>
      </Box>
    </Slide>
  );
}
