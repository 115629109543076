export interface NotificationResponse {
  id: string;
  title: string;
  description: string;
  thumbnail?: string;
  imgUrls?: string[];
  payload?: { [key: string]: unknown };
  unread: boolean;
}

export class Notification {
  id: string;
  title: string;
  description: string;
  thumbnail?: string;
  imgUrls?: string[];
  payload?: { [key: string]: unknown };
  unread: boolean;

  constructor(raw: NotificationResponse) {
    this.id = raw.id;
    this.title = raw.title;
    this.description = raw.description;
    this.thumbnail = raw.thumbnail;
    this.imgUrls = raw.imgUrls;
    this.payload = raw.payload;
    this.unread = raw.unread;
  }
}
