export default function Tour(props: { width?: string; height?: string }) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1114 6.44345L38.1169 5.8173V7.26997C38.4843 6.71896 39.0353 6.23474 39.7699 5.8173C40.5046 5.39987 41.2477 5.12436 41.999 4.99078V8.6475H41.974C41.7402 8.61411 41.5733 8.59741 41.4731 8.59741C40.8219 8.59741 40.154 8.84787 39.4694 9.34879C39.0854 9.63265 38.7681 9.94154 38.5176 10.2755C38.2839 10.6094 38.1503 10.8599 38.1169 11.0269V16.0862C38.1169 16.5203 37.9583 16.8876 37.641 17.1882C37.3405 17.5054 36.9731 17.6641 36.539 17.6641H35.1114V6.44345Z"
        fill={'currentColor'}
      />
      <path
        d="M33.1091 15.7105C33.1091 16.178 32.9338 16.5871 32.5831 16.9377C32.2492 17.2884 31.8401 17.4637 31.3559 17.4637H29.9032V16.0611C29.0517 17.1297 27.9246 17.6641 26.522 17.6641C23.6668 17.6641 22.2391 15.7689 22.2391 11.9786V6.44344L25.1445 6.14288V11.8283C25.1445 12.7467 25.3281 13.4563 25.6955 13.9572C26.0795 14.4582 26.5888 14.7086 27.2233 14.7086C27.8578 14.7086 28.4338 14.4832 28.9515 14.0324C29.4691 13.5816 29.7863 12.9721 29.9032 12.204V5.64196L33.1091 5.29132V15.7105Z"
        fill={'currentColor'}
      />
      <path
        d="M15.4482 8.6976C14.6968 8.6976 14.0623 8.98981 13.5447 9.57421C13.0438 10.1586 12.7933 10.9434 12.7933 11.9285C12.7933 12.8302 13.002 13.5315 13.4194 14.0324C13.8536 14.5333 14.4046 14.7838 15.0725 14.7838C15.8239 14.7838 16.45 14.4999 16.9509 13.9322C17.4519 13.3645 17.7023 12.5797 17.7023 11.5779C17.7023 10.6595 17.4853 9.9499 17.0511 9.44898C16.6337 8.94806 16.0994 8.6976 15.4482 8.6976ZM9.86292 12.1289C9.86292 10.9267 10.0883 9.86642 10.5392 8.94806C11.0067 8.02971 11.6412 7.29503 12.4427 6.74401C13.2441 6.193 14.1541 5.84236 15.1727 5.69208C15.5066 5.64199 15.8155 5.61694 16.0994 5.61694C17.0678 5.61694 17.9361 5.83401 18.7042 6.26814C19.4889 6.70227 20.1067 7.34512 20.5576 8.19668C21.0084 9.04825 21.2338 10.0918 21.2338 11.3274C21.2338 12.6799 20.9583 13.8487 20.4073 14.8339C19.873 15.8023 19.1383 16.537 18.2032 17.0379C17.2849 17.5555 16.2747 17.8144 15.1727 17.8144C14.1541 17.8144 13.2441 17.5889 12.4427 17.1381C11.6412 16.704 11.0067 16.0611 10.5392 15.2096C10.0883 14.358 9.86292 13.3311 9.86292 12.1289Z"
        fill={'currentColor'}
      />
      <path
        d="M11.9711 2.18567V5.24128L7.63812 5.54183V16.1112C7.63812 16.5287 7.48784 16.8877 7.18729 17.1882C6.90343 17.4888 6.54444 17.6391 6.11031 17.6391H3.9814V5.84239L-0.000915527 6.11789V3.31274L11.9711 2.18567Z"
        fill={'currentColor'}
      />
    </svg>
  );
}
