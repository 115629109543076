import { createContext, ReactNode, useContext, useState } from 'react';
import { IChattingMessage } from '~kint/models/Chatting.ts';

export type ChatContextType = {
  replyTo: IChattingMessage | null;
  setReplyTo: (replyTo: IChattingMessage) => void;
  clear: () => void;
};

export const ChattingContext = createContext<ChatContextType>({
  replyTo: null,
  setReplyTo: () => {},
  clear: () => {},
});

export const ChattingContextProvider = ({ children }: { children: ReactNode }) => {
  const [replyTo, setReplyTo] = useState<IChattingMessage | null>(null);

  const chatContext = {
    replyTo,
    setReplyTo,
    clear: () => {
      setReplyTo(null);
    },
  };

  return <ChattingContext.Provider value={chatContext}>{children}</ChattingContext.Provider>;
};

export const useChattingContext = () => useContext(ChattingContext);
