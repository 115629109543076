import useKupStore from '~kup/store';
import { ProductOptionSelection } from '~kup/models/Product';

import FillBox from '~/components/FillBox';
import ImageView from '~/components/ImageView';
import Stack from '~/components/Stack';
import IconButton from '~/components/IconButton';
import Typography from '~/components/Typography';
import { useSnackBar } from '~/contexts/SnackBarContext.tsx';
import QuantityController from '~/pages/shop/components/QuantityController.tsx';
import { useTranslation } from 'react-i18next';

export type SelectionItemProps = {
  productSelection: ProductOptionSelection;
  onChangeProductSelection: (changedSelection: ProductOptionSelection) => void;
  onDeleteProductSelection: (deletedSelection: ProductOptionSelection) => void;
};

export default function SelectionItem(props: SelectionItemProps) {
  const { currency } = useKupStore((state) => ({ currency: state.currency }));
  const { onChangeProductSelection, onDeleteProductSelection, productSelection } = props;
  const { i18n } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const { optionSelectionName, stock, quantity, thumbnail } = productSelection;

  const handleQuantity = (quantity: number) => {
    if (quantity < 1) {
      return onDeleteProductSelection(productSelection);
    }

    // 프로모션의 경우, 최대 주문 수량을 1로 제한
    if (stock.isInPromotion) {
      showSnackBar({
        message: i18n.t('shop-product-oneItemPerCustomer'), // Only one item per customer is available
        lift: true,
      });
      return;
    }
    const properQuantity = Math.min(stock.quantity, quantity);
    const newSelection = productSelection.setQuantity(properQuantity);

    onChangeProductSelection(newSelection);
    if (properQuantity !== quantity) {
      showSnackBar({
        message: i18n.t('shop-product-maxOrderLimit', { properQuantity }), // The maximum order limit is {properQuantity}
        lift: true,
      });
    }
  };

  const handleDelete = () => {
    onDeleteProductSelection(productSelection);
  };

  return (
    <FillBox
      color={'fill'}
      layoutBoxProps={{
        px: '20px',
        py: '12px',
      }}
    >
      <Stack column gap={'12px'} width={'100%'}>
        <Stack row alignItems={'center'} gap={'14px'}>
          {thumbnail && (
            <ImageView
              flex={0}
              width={'40px'}
              height={'40px'}
              borderRadius={'6px'}
              src={thumbnail}
            />
          )}
          <Stack column flex={1}>
            <Typography variant={'BaseM_B'}>{optionSelectionName}</Typography>
            {stock.isInPromotion ? (
              <Typography variant={'FootNote'} color={'red'}>
                {
                  i18n.t('shop-product-onlyOneItemAvailable') // Only 1 item available
                }
              </Typography>
            ) : (
              <Typography variant={'FootNote'} color={'red'}>
                {
                  i18n.t('shop-product-itemsLeft', { stockQuantity: stock.quantity }) // {stock.quantity} left
                }
              </Typography>
            )}
          </Stack>
          <IconButton
            sx={{
              flex: 0,
            }}
            iconProps={{
              width: '20px',
            }}
            color={'typography.tertiary'}
            onClick={handleDelete}
          >
            close
          </IconButton>
        </Stack>
        <Stack row width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'BaseM_B'}>
            {(stock.price * quantity).toLocaleString() + ' ' + currency}
          </Typography>
          <QuantityController quantity={quantity} onChange={handleQuantity} />
        </Stack>
      </Stack>
    </FillBox>
  );
}
