import api from '~kup/api';
import { Address } from '~kup/models/types.ts';

const SEARCH_PATH = {
  ADDRESS: () => ['orders', 'address', 'search'].join('/'),
};

export async function searchAddress(search: string): Promise<Address[]> {
  const res = await api.get(SEARCH_PATH.ADDRESS(), { params: { keyword: search } });
  return res.data.data ?? [];
}
