import { IIssuedCoupon } from '~kup/models/Coupon.ts';
import { getUsableCoupons } from '~kup/controllers/coupon.ts';
import { useCallback, useLayoutEffect, useState } from 'react';

type UseUsableCoupons = {
  usableCoupons: IIssuedCoupon[];
  callUsableCoupons: (productIds: string[]) => void;
};

export default function useUsableCoupons(): UseUsableCoupons {
  const [usableCoupons, setUsableCoupons] = useState<IIssuedCoupon[]>([]);

  const callUsableCoupons = useCallback(() => {
    getUsableCoupons().then((ownCoupons) => {
      setUsableCoupons(ownCoupons);
    });
  }, []);

  useLayoutEffect(() => {
    callUsableCoupons();
  }, []);

  return {
    usableCoupons,
    callUsableCoupons,
  };
}
