import { Promotion, PromotionResponse } from '~kup/models/Promotion.ts';
import api from '~kup/api';

type GetPromotionsParams = {
  isHighlight?: boolean;
};

const PROMOTION_PATH = {
  PROMOTIONS: () => ['promotions'].join('/'),
};

export async function getPromotions(params: GetPromotionsParams): Promise<Promotion[]> {
  const resp = await api.get(PROMOTION_PATH.PROMOTIONS(), { params });
  const promotionResponse: PromotionResponse[] = resp.data.data;
  return promotionResponse.map((r) => new Promotion(r));
}
