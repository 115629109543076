export type IconSendProps = {
  width?: string;
  height?: string;
};
export default function IconSend(props: IconSendProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66675 19.5081C4.38892 19.6193 4.125 19.5957 3.875 19.4374C3.625 19.2791 3.5 19.0499 3.5 18.7499V13.9166L11.2 11.9999L3.5 10.0499V5.24988C3.5 4.94988 3.625 4.72072 3.875 4.56238C4.125 4.40405 4.38892 4.38047 4.66675 4.49163L20.6668 11.2249C21.0056 11.3749 21.175 11.6332 21.175 11.9999C21.175 12.3666 21.0056 12.6249 20.6668 12.7749L4.66675 19.5081Z"
        fill="currentColor"
      />
    </svg>
  );
}
