import useKupStore from '~kup/store';
import { useLayoutEffect, useState } from 'react';

export default function useSyncBasket(): boolean {
  const [isSynced, setIsSynced] = useState<boolean>(false);
  const { syncBasket } = useKupStore((state) => ({ syncBasket: state.syncBasket }));
  useLayoutEffect(() => {
    setIsSynced(true);
    syncBasket().finally(() => {
      setIsSynced(true);
    });
  }, [syncBasket]);
  return isSynced;
}
