import Typography from '~/components/Typography.tsx';
import Container from '~/components/Container.tsx';
import { useTranslation } from 'react-i18next';

export default function CommonFooter() {
  const { i18n } = useTranslation();
  return (
    <Container
      background={'paper'}
      px={'20px'}
      py={'16px'}
      stackProps={{
        gap: '4px',
      }}
    >
      <Container
        py={'4px'}
        stackProps={{
          row: true,
          gap: '16px',
        }}
      >
        <a href={'/terms'} target={'_blank'} style={{ textDecoration: 'none' }}>
          <Typography variant={'FootNote_B'} color={'typography.secondary'}>
            {
              i18n.t('component-commonFooter-termsAndConditions') // Terms & Condition
            }
          </Typography>
        </a>
        <a href={'/privacy'} target={'_blank'} style={{ textDecoration: 'none' }}>
          <Typography variant={'FootNote_B'} color={'typography.secondary'}>
            {
              i18n.t('component-commonFooter-privacyPolicy') // Privacy Policy
            }
          </Typography>
        </a>
        <a href={'/orderagreements'} target={'_blank'} style={{ textDecoration: 'none' }}>
          <Typography variant={'FootNote_B'} color={'typography.secondary'}>
            {
              i18n.t('component-commonFooter-orderAgreements') // Order Agreements
            }
          </Typography>
        </a>
      </Container>

      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-companyName') // Company: K Tour Story Co., Ltd.
        }
      </Typography>
      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-address') // Address: 10th Floor, 12 Supyo-ro, Jung-gu, Seoul, Korea
        }
      </Typography>
      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-representative') // Representative: Kang-Hyun Park
        }
      </Typography>
      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-businessRegistrationNumber') // Business Registration Number: 597-81-00842
        }
      </Typography>
      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-onlineSalesRegistrationNumber') // Online Sales Registration Number: 2023-SeoulJunggu-1420
        }
      </Typography>

      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-customerServiceForTour') // Customer Service for Tour:
        }
        <a href="mailto:support@ktourstory.com" style={{ color: 'inherit' }}>
          support@ktourstory.com
        </a>
      </Typography>
      <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
        {
          i18n.t('component-commonFooter-customerService') // Customer Service:
        }
        <a href="mailto:support@kup.travel" style={{ color: 'inherit' }}>
          support@kup.travel
        </a>
      </Typography>
    </Container>
  );
}
