import { styled } from '@mui/material';
import { default as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { default as MuiFormControlLabel } from '@mui/material/FormControlLabel/FormControlLabel';
import Headline, { HeadlineProps } from '~/components/Headline.tsx';

export type RadioProps = Omit<MuiRadioProps, 'value'> &
  HeadlineProps & {
    value: string;
  };

const StyledFormControlLabel = styled(MuiFormControlLabel)({
  margin: 0,
  paddingTop: '12px',
  paddingBottom: '12px',
  '& > .MuiFormControlLabel-label': {
    marginLeft: '14px',
  },
});

export type BaseRadioProps = MuiRadioProps & {
  width?: string;
  height?: string;
};

function BareRadio(props: BaseRadioProps) {
  return (
    <MuiRadio
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#101010"
            opacity={'0.16'}
          />
        </svg>
      }
      checkedIcon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="currentColor"
          />
        </svg>
      }
      {...props}
    />
  );
}

const StyledCheckbox = styled(BareRadio)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
  padding: 0,
}));

export default function Radio(props: RadioProps) {
  const { value, disabled, ...headlineProps } = props;
  const Radio = (
    <StyledCheckbox
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#101010"
            opacity={'0.16'}
          />
        </svg>
      }
      checkedIcon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="currentColor"
          />
        </svg>
      }
    />
  );
  const Label = <Headline {...headlineProps} />;
  return <StyledFormControlLabel disabled={disabled} control={Radio} label={Label} value={value} />;
}
