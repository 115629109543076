export default function Shop(props: { width?: string; height?: string }) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.8183 12.9326C42.808 12.8677 43.53 12.5757 43.9843 12.0565C44.4386 11.5373 44.6658 10.8639 44.6658 10.0365C44.6658 9.2739 44.4711 8.67358 44.0817 8.23551C43.7085 7.78121 43.1163 7.55406 42.3051 7.55406C42.0941 7.55406 41.9319 7.56217 41.8183 7.5784C41.4127 7.61085 41.0233 7.73253 40.6501 7.94346C40.2932 8.13816 39.993 8.39775 39.7496 8.72225V12.1051C39.993 12.381 40.2932 12.5919 40.6501 12.7379C41.0233 12.8839 41.4127 12.9488 41.8183 12.9326ZM36.8292 5.24202L39.7496 4.87696V6.23985C40.0904 5.7531 40.5203 5.33937 41.0395 4.99864C41.5749 4.6417 42.1104 4.43077 42.6458 4.36587C42.9703 4.33342 43.1974 4.3172 43.3272 4.3172C44.2358 4.3172 45.0471 4.55246 45.761 5.02298C46.4748 5.4935 47.0265 6.1425 47.4159 6.96997C47.8053 7.79743 48 8.73036 48 9.76875C48 10.8883 47.7728 11.9186 47.3185 12.8596C46.8805 13.8006 46.2558 14.5551 45.4446 15.123C44.6495 15.6746 43.741 15.9667 42.7188 15.9991C42.232 16.0153 41.7128 15.8855 41.1612 15.6097C40.6258 15.3501 40.1553 15.0013 39.7496 14.5632V17.8731C39.7496 18.2462 39.6198 18.5626 39.3602 18.8222C39.1006 19.0818 38.7843 19.2116 38.4111 19.2116H36.8292V5.24202Z"
        fill={'currentColor'}
      />
      <path
        d="M30.3115 7.43239C29.5814 7.43239 28.9648 7.71633 28.4619 8.2842C27.9751 8.85207 27.7317 9.61464 27.7317 10.5719C27.7317 11.448 27.9346 12.1295 28.3402 12.6162C28.762 13.103 29.2974 13.3464 29.9464 13.3464C30.6766 13.3464 31.285 13.0705 31.7717 12.5189C32.2585 11.9672 32.5019 11.2047 32.5019 10.2312C32.5019 9.33881 32.2909 8.64926 31.8691 8.16251C31.4635 7.67577 30.9443 7.43239 30.3115 7.43239ZM24.8843 10.7666C24.8843 9.59841 25.1033 8.56813 25.5414 7.67576C25.9957 6.7834 26.6122 6.0695 27.391 5.53408C28.1698 4.99866 29.0541 4.65794 30.0438 4.51192C30.3683 4.46324 30.6684 4.4389 30.9443 4.4389C31.8853 4.4389 32.729 4.64983 33.4753 5.07167C34.2379 5.49352 34.8382 6.11818 35.2763 6.94565C35.7144 7.77311 35.9334 8.78717 35.9334 9.98781C35.9334 11.302 35.6657 12.4378 35.1303 13.395C34.6111 14.3361 33.8972 15.05 32.9886 15.5367C32.0962 16.0397 31.1146 16.2912 30.0438 16.2912C29.0541 16.2912 28.1698 16.0721 27.391 15.6341C26.6122 15.2122 25.9957 14.5876 25.5414 13.7601C25.1033 12.9326 24.8843 11.9348 24.8843 10.7666Z"
        fill={'currentColor'}
      />
      <path
        d="M19.1988 4.31718C19.4908 4.2685 19.7747 4.24417 20.0506 4.24417C21.3485 4.24417 22.3302 4.73091 22.9954 5.7044C23.6606 6.6779 23.9932 8.09757 23.9932 9.96343V14.0764C23.9932 14.6443 23.7904 15.1311 23.3848 15.5367C22.9791 15.9423 22.4924 16.1451 21.9245 16.1451H20.6347V10.2068C20.6347 9.29821 20.4562 8.60054 20.0992 8.1138C19.7585 7.62705 19.288 7.38368 18.6877 7.38368C18.0711 7.38368 17.5033 7.61894 16.9841 8.08946C16.4649 8.55998 16.1404 9.18464 16.0106 9.96343V16.1451H13.1874V2.19983L16.0106 1.64008V6.33717C16.7569 5.21766 17.8196 4.54433 19.1988 4.31718Z"
        fill={'currentColor'}
      />
      <path
        d="M11.5602 10.9857C11.6089 12.2674 11.3006 13.4924 10.6354 14.6606C9.97018 15.8288 8.55051 16.4697 6.37637 16.5832C5.30553 16.6481 4.31581 16.5021 3.40722 16.1452C2.51485 15.7882 1.77662 15.3826 1.19253 14.9283C0.624658 14.4578 0.227148 14.0603 0 13.7358L0.973492 12.5432C1.11952 12.3485 1.29799 12.2025 1.50891 12.1052C1.71984 12.0078 1.94698 11.9592 2.19036 11.9592C2.53108 11.9592 2.83124 12.0565 3.09084 12.2512C3.56136 12.5757 3.99132 12.8191 4.38071 12.9813C4.85124 13.1922 5.34609 13.2977 5.86529 13.2977C6.28714 13.2977 6.66031 13.2247 6.98481 13.0787C7.32553 12.9326 7.55268 12.7298 7.66625 12.4702C7.79605 12.2106 7.86095 11.951 7.86095 11.6914C7.86095 11.4318 7.78794 11.2128 7.64191 11.0343C7.51211 10.8559 7.33364 10.6774 7.10649 10.4989C6.91179 10.3853 6.67653 10.288 6.40071 10.2069C6.14111 10.1095 5.76794 10.0122 5.28119 9.91482L4.3077 9.67145C3.46401 9.4443 2.72578 9.11169 2.09301 8.67362C1.59004 8.33289 1.21687 7.91105 0.973492 7.40808C0.746344 6.88888 0.63277 6.32912 0.63277 5.7288C0.63277 4.86889 0.843693 4.13065 1.26554 3.51411C1.78474 2.75154 2.44996 2.14311 3.2612 1.68881C4.08867 1.23451 5.15951 0.942467 6.47372 0.812669C6.5873 0.796443 6.75766 0.78833 6.98481 0.78833C7.81227 0.78833 8.58296 0.966804 9.29685 1.32375C10.0107 1.66447 10.603 2.08632 11.0735 2.58929L8.90745 4.97435C8.09621 4.17933 7.21195 3.78182 6.25469 3.78182C5.81662 3.78182 5.37854 3.87106 4.94047 4.04953C4.68087 4.14688 4.46995 4.32535 4.3077 4.58495C4.16168 4.82832 4.08867 5.08792 4.08867 5.36374C4.08867 5.63957 4.16168 5.85049 4.3077 5.99651C4.46995 6.17499 4.66465 6.29667 4.8918 6.36157C5.13517 6.42647 5.50834 6.49948 6.01131 6.58061C6.80633 6.67796 7.51211 6.81587 8.12866 6.99434C8.84255 7.18904 9.45099 7.50543 9.95396 7.9435C10.4732 8.38157 10.8625 8.87643 11.1221 9.42807C11.398 9.96349 11.544 10.4827 11.5602 10.9857Z"
        fill={'currentColor'}
      />
    </svg>
  );
}
