import Container from '~/components/Container.tsx';
import Stack from '~/components/Stack';
import Checkbox from '~/components/Checkbox.tsx';

import { BasketProductItem } from '~kup/models/Basket.ts';
import ImageView from '~/components/ImageView.tsx';
import Headline from '~/components/Headline.tsx';
import IconButton from '~/components/IconButton.tsx';
import FillBox from '~/components/FillBox.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button';
import useKupStore from '~kup/store';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type BasketItemProps = {
  item: BasketProductItem;
  checked?: boolean;
  onDelete: (item: BasketProductItem) => void;
  onModify: (item: BasketProductItem) => void;
  onOrder: (item: BasketProductItem) => void;
  onCheck: (item: BasketProductItem, checked: boolean) => void;
};

export default function BasketItem(props: BasketItemProps) {
  const { currency } = useKupStore();
  const { item, checked = false, onDelete, onModify, onOrder, onCheck } = props;
  const { i18n } = useTranslation();
  const isOutOfStock = item.stock.quantity < item.quantity;
  const isInsufficientStock =
    item.quantity !== item.availableQuantity && item.availableQuantity > 0;
  const isStockDepleted = item.stock.quantity === 0;
  const isInPromotion = item.isInPromotion;

  const handleDelete = () => {
    onDelete(item);
  };
  const handleOrder = () => {
    if (!isOutOfStock) {
      onOrder(item);
    }
  };
  const handleChange = () => {
    onModify(item);
  };
  const handleCheck = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onCheck(item, checked);
  };

  return (
    <Container py={'8px'}>
      <Container py={'12px'} px={'20px'}>
        <Stack row gap={'14px'} alignItems={'center'}>
          <Checkbox onChange={handleCheck} checked={checked} disabled={isStockDepleted} />
          <ImageView borderRadius={'6px'} width={'60px'} height={'60px'} src={item.thumbnail} />
          <Headline
            sx={{
              flex: 1,
            }}
            headline={item.productName}
            headlineProps={{ sx: { maxLines: 2, textOverflow: 'ellipsis' } }}
            subHeadline={item.productBrand}
            description={
              isInPromotion
                ? i18n.t('shop-basket-onlyOneItemAvailable') // Only 1 item available
                : `${item.stock.quantity} left`
            }
            descriptionProps={{
              color:
                item.stock.quantity < 3 || isOutOfStock || isInPromotion
                  ? 'error'
                  : 'typography.secondary',
            }}
          />
          <IconButton
            size={'small'}
            sx={{
              flex: 0,
            }}
            iconProps={{
              width: '20px',
            }}
            color={'typography.tertiary'}
            onClick={handleDelete}
          >
            close
          </IconButton>
        </Stack>
      </Container>
      <Container px={'20px'}>
        <FillBox
          type={'border'}
          borderRadius={'6px'}
          trail={
            <Container
              stackProps={{
                column: true,
              }}
            >
              <Typography variant={'BaseS_B'} strike={isStockDepleted} whiteSpace={'nowrap'}>
                {item.availableAmount.toLocaleString()} {currency}
              </Typography>

              {isInsufficientStock ? (
                <Typography
                  variant={'Caption'}
                  strike={true}
                  textAlign={'right'}
                  whiteSpace={'nowrap'}
                  lineHeight={1}
                  sx={{
                    opacity: 0.3,
                  }}
                >
                  {item.amount.toLocaleString()} {currency}
                </Typography>
              ) : null}
            </Container>
          }
        >
          <Container
            stackProps={{
              column: true,
              justifyContent: 'stretch',
            }}
          >
            <Stack width={'100%'} row justifyContent={'stretch'}>
              <Typography
                variant={'FootNote'}
                flexGrow={1}
                strike={isStockDepleted}
                color={isOutOfStock ? 'error' : 'typography.secondary'}
                whiteSpace={'pre-wrap'}
              >
                {item.optionSelectionName}
              </Typography>
              <Typography
                variant={'FootNote'}
                strike={isStockDepleted}
                color={isOutOfStock ? 'error' : 'typography.secondary'}
                whiteSpace={'nowrap'}
              >
                X {item.availableQuantity}
              </Typography>
            </Stack>

            {isInsufficientStock ? (
              <Stack row justifyContent={'stretch'}>
                <Typography
                  variant={'Caption'}
                  strike={true}
                  color={'error'}
                  whiteSpace={'pre-wrap'}
                  lineHeight={1}
                  sx={{
                    opacity: 0.3,
                  }}
                >
                  {item.optionSelectionName}
                </Typography>
                <Typography
                  variant={'Caption'}
                  strike={true}
                  color={'error'}
                  whiteSpace={'nowrap'}
                  lineHeight={1}
                  sx={{
                    opacity: 0.3,
                  }}
                >
                  X {item.quantity}
                </Typography>
              </Stack>
            ) : null}
          </Container>
        </FillBox>
      </Container>
      {isStockDepleted ? (
        <Container px={'20px'} py={'8px'}>
          <Button fullWidth size={'small'} color={'error'} onClick={handleDelete}>
            {
              i18n.t('shop-basket-removeItem') // Remove Item
            }
          </Button>
        </Container>
      ) : isOutOfStock ? (
        <Container px={'20px'} py={'8px'}>
          <Button fullWidth size={'small'} color={'error'} onClick={handleChange}>
            {
              i18n.t('shop-basket-modifyOrder') // Modify Order
            }
          </Button>
        </Container>
      ) : isInPromotion ? (
        <Container px={'20px'} py={'8px'}>
          <Stack row gap={'10px'}>
            <Button fullWidth size={'small'} onClick={handleOrder}>
              {
                i18n.t('shop-basket-orderNow') // Order Now
              }
            </Button>
          </Stack>
        </Container>
      ) : (
        <Container px={'20px'} py={'8px'}>
          <Stack row gap={'10px'}>
            <Button fullWidth size={'small'} color={'fill'} onClick={handleChange}>
              {
                i18n.t('shop-basket-modifyOrder') // Modify Order
              }
            </Button>
            <Button fullWidth size={'small'} onClick={handleOrder}>
              {
                i18n.t('shop-basket-orderNow') // Order Now
              }
            </Button>
          </Stack>
        </Container>
      )}
    </Container>
  );
}
