import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en';
import translationZH from './locales/zh';
import translationZHT from './locales/zht';

const resources = {
  en: {
    translation: translationEN,
  },
  'en-US': {
    translation: translationEN,
  },
  'en-AU': {
    translation: translationEN,
  },
  'en-BZ': {
    translation: translationEN,
  },
  'en-CA': {
    translation: translationEN,
  },
  'en-029': {
    translation: translationEN,
  },
  'en-IE': {
    translation: translationEN,
  },
  'en-JM': {
    translation: translationEN,
  },
  'en-NZ': {
    translation: translationEN,
  },
  'en-PH': {
    translation: translationEN,
  },
  'en-ZA': {
    translation: translationEN,
  },
  'en-TT': {
    translation: translationEN,
  },
  'en-GB': {
    translation: translationEN,
  },
  'en-ZW': {
    translation: translationEN,
  },
  english: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  'zh-CN': {
    translation: translationZH,
  },
  'zh-Hans': {
    translation: translationZH,
  },
  'zh-SG': {
    translation: translationZH,
  },
  chinese: {
    translation: translationZH,
  },
  zht: {
    translation: translationZHT,
  },
  'zh-HK': {
    translation: translationZHT,
  },
  'zh-MO': {
    translation: translationZHT,
  },
  'zh-TW': {
    translation: translationZHT,
  },
  'zh-Hant': {
    translation: translationZHT,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
