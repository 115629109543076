export type IconCloseProps = {
  width?: string;
  height?: string;
};
export default function IconClose(props: IconCloseProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 13.1667L6.86682 18.3C6.70572 18.4611 6.51128 18.5417 6.2835 18.5417C6.05573 18.5417 5.86129 18.4611 5.70017 18.3C5.53905 18.1389 5.4585 17.9445 5.4585 17.7167C5.4585 17.4889 5.53905 17.2945 5.70017 17.1334L10.8335 12L5.70017 6.86667C5.53905 6.70557 5.4585 6.51113 5.4585 6.28334C5.4585 6.05558 5.53905 5.86113 5.70017 5.70002C5.86129 5.5389 6.05573 5.45834 6.2835 5.45834C6.51128 5.45834 6.70572 5.5389 6.86682 5.70002L12.0002 10.8334L17.1335 5.70002C17.2946 5.5389 17.4891 5.45834 17.7168 5.45834C17.9446 5.45834 18.1391 5.5389 18.3002 5.70002C18.4613 5.86113 18.5418 6.05558 18.5418 6.28334C18.5418 6.51113 18.4613 6.70557 18.3002 6.86667L13.1668 12L18.3002 17.1334C18.4613 17.2945 18.5418 17.4889 18.5418 17.7167C18.5418 17.9445 18.4613 18.1389 18.3002 18.3C18.1391 18.4611 17.9446 18.5417 17.7168 18.5417C17.4891 18.5417 17.2946 18.4611 17.1335 18.3L12.0002 13.1667Z"
        fill="currentColor"
      />
    </svg>
  );
}
