import { QueryOption } from '~common/firebase/firestore.ts';
import kintFirestore from '~kint/firebase/firestore.ts';

const SYSTEM_MESSAGE_TYPES = ['enter', 'exit', 'date'];

export default async function countMessage(chatId: string, participantId: string) {
  const unreadQuery: QueryOption = { where: [`readStatus.${participantId}`, '==', false] };
  const readQuery: QueryOption = { where: [`readStatus.${participantId}`, '==', true] };
  const systemQuery: QueryOption = { where: ['type', 'in', SYSTEM_MESSAGE_TYPES] };
  const myQuery: QueryOption = { where: [`sender.id`, '==', participantId] };
  const allQuery: QueryOption = {};

  const queries = [unreadQuery, readQuery, allQuery, myQuery, systemQuery];
  const [unread, read, all, my, system] = await Promise.all(
    queries.map((q) => kintFirestore.countDocs(['chats', chatId, 'messages'], q))
  ).catch();

  return {
    all,
    unread,
    read,
    my,
    system,
    notification: read === 0 ? all - system : unread,
  };
}
