export type IconTourProps = {
  width?: string;
  height?: string;
};
export default function IconTour(props: IconTourProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98525 14.1481V21.0742C5.98525 21.3364 5.89617 21.5563 5.71802 21.7339C5.53987 21.9113 5.31923 22 5.05608 22C4.79275 22 4.57312 21.9113 4.39719 21.7339C4.22126 21.5563 4.1333 21.3364 4.1333 21.0742V2.92583C4.1333 2.66361 4.22237 2.4437 4.40052 2.26611C4.57849 2.0887 4.79913 2 5.06247 2C5.3258 2 5.54543 2.0887 5.72136 2.26611C5.89728 2.4437 5.98525 2.66361 5.98525 2.92583V3.29639H20.5314C20.8569 3.29639 21.1127 3.4337 21.2989 3.70833C21.4848 3.98296 21.516 4.27463 21.3925 4.58333L19.6702 8.72222L21.3925 12.8611C21.516 13.1698 21.4848 13.4615 21.2989 13.7361C21.1127 14.0107 20.8569 14.1481 20.5314 14.1481H5.98525Z"
        fill="currentColor"
      />
    </svg>
  );
}
