const replaceAt = <T>(
  prev: T[],
  next: T,
  indexOrFinder: number | ((exists: T, index: number) => boolean)
): T[] => {
  if (typeof indexOrFinder === 'number') {
    return prev.map((p, idx) => (idx === indexOrFinder ? next : p));
  }
  return prev.map((p, idx) => (indexOrFinder(p, idx) ? next : p));
};

export default replaceAt;
