import FillBox from '~/components/FillBox.tsx';
import IconButton from '~/components/IconButton.tsx';
import Paper from '~/components/Paper.tsx';
import Typography from '~/components/Typography.tsx';

export type QuantityControllerProps = {
  quantity: number;
  onChange: (quantity: number) => void;
  disabled?: boolean;
};

export default function QuantityController(props: QuantityControllerProps) {
  const { quantity, onChange } = props;
  const handleQuantityDown = () => {
    onChange(quantity - 1);
  };
  const handleQuantityUp = () => {
    onChange(quantity + 1);
  };
  return (
    <FillBox
      color={'fill'}
      layoutBoxProps={{
        py: '0px',
        px: '4px',
      }}
      lead={
        <IconButton
          disabled={quantity === 1}
          onClick={handleQuantityDown}
          iconProps={{
            size: '1.5rem',
            width: '44px',
          }}
        >
          remove
        </IconButton>
      }
      trail={
        <IconButton
          onClick={handleQuantityUp}
          iconProps={{
            size: '1.5rem',
            width: '44px',
          }}
        >
          add
        </IconButton>
      }
      innerBoxProps={{
        justifyContent: 'center',
      }}
    >
      <Paper
        sx={{
          p: '4px',
          borderRadius: '6px',
        }}
      >
        <Typography
          variant={'BaseS_B'}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '44px',
          }}
        >
          {quantity}
        </Typography>
      </Paper>
    </FillBox>
  );
}
