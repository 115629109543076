import { initializeApp } from 'firebase/app';

const NAME = import.meta.env.VITE_KINT_FIREBASE_APP_NAME;

const firebaseConfig = {
  projectId: import.meta.env.VITE_KINT_FIREBASE_PROJECT_ID,
  apiKey: import.meta.env.VITE_KINT_FIREBASE_KEY,
  authDomain: import.meta.env.VITE_KINT_FIREBASE_DOMAIN,
  databaseURL: import.meta.env.VITE_KINT_FIREBASE_DATABASE,
  storageBucket: import.meta.env.VITE_KINT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_KINT_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_KINT_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_KINT_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig, NAME);
export default app;
