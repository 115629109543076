import useKupStore from '~kup/store';
import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import Typography from '~/components/Typography.tsx';
import Area, { BaseArea } from '~/components/Area';
import styled from '~/components/styled';
import Card from '~/components/Card.tsx';
import IconButton from '~/components/IconButton.tsx';
import Stack from '~/components/Stack.tsx';
import ImageView from '~/components/ImageView.tsx';
import Button from '~/components/Button.tsx';
import Badge from '~/components/Badge.tsx';
import dayjs from 'dayjs';
import useOrderHistories from '~/pages/shop/hooks/useOrderHistory.ts';
import { OrderHistory } from '~kup/models/Order.ts';
import { ORDER_STATUS_GUIDANCE_MAP } from '~/pages/shop/constants';
import { useTranslation } from 'react-i18next';

// import Paper from '~/components/Paper';
// import ButtonBase from '~/components/ButtonBase';

const GradientPage = styled(Page)({
  background: 'linear-gradient(0deg, #FFF -32.32%, #E3EEFF 109.39%)',
});

function OrderHistoryItem({ data }: { data: OrderHistory }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { currency } = useKupStore((state) => ({ currency: state.currency }));
  const orderTitle = data.orderItems.map((item) => item.productName).join(' / ');
  const orderStatusTag = ORDER_STATUS_GUIDANCE_MAP[data.orderStatus]?.tag;
  return (
    <Card sx={{ marginBottom: 3 }}>
      <BaseArea
        bottom
        headerContainerProps={{ py: '0px', px: '0px' }}
        title={
          <Stack row gap={'6px'}>
            <Typography variant={'body2'}>{dayjs(data.orderedAt).format('YYYY.MM.DD')}</Typography>
            <Badge color={orderStatusTag?.includes('CANCEL') ? 'error' : 'primary'} size={'small'}>
              {orderStatusTag ?? '???'}
            </Badge>
          </Stack>
        }
        trail={
          <IconButton
            disableRipple
            color={'typography.tertiary'}
            iconProps={{
              width: '24px',
              size: '1.25em',
            }}
          >
            chevron_right
          </IconButton>
        }
        onClick={() => {
          navigate(data.orderId);
        }}
      >
        <Container pt={'18px'}>
          <Stack row gap={'14px'} alignItems={'center'}>
            <ImageView
              borderRadius={'6px'}
              width={'60px'}
              height={'60px'}
              src={data.orderItems[0].productImageUrls[0]}
            />
            <Headline
              sx={{ flex: 1 }}
              subHeadline={
                i18n.t('shop-myOrder-itemCount', { itemCount: data.orderItems.length }) // {data.orderItems.length} items
              }
              headline={orderTitle}
              headlineProps={{ sx: { maxLines: 2, textOverflow: 'ellipsis' } }}
              description={data.orderAmount.toLocaleString() + ' ' + currency}
            />
          </Stack>
        </Container>
        {/*<Container pt={'6px'}>*/}
        {/*  <Button*/}
        {/*    fullWidth*/}
        {/*    color={'fill'}*/}
        {/*    size={'small'}*/}
        {/*    sx={{ flex: 0 }}*/}
        {/*    onClick={() => {*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Order Cancel*/}
        {/*  </Button>*/}
        {/*</Container>*/}
      </BaseArea>
    </Card>
  );
}

export default function ShopMyOrdersPage() {
  const { orderHistories, hasNextPage, next } = useOrderHistories({
    pageSize: 6,
    continuous: true,
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <GradientPage
      type={'page'}
      navigationBarProps={{
        lead: (
          <>
            <IconButton
              touchArea={'16px'}
              iconProps={{
                width: '20px',
                color: 'typography.tertiary',
              }}
              onClick={() => navigate('/shop')}
            >
              back
            </IconButton>
          </>
        ),
        background: 'transparent',
      }}
    >
      <Container py={'8px'}>
        <Container py={'12px'} px={'20px'}>
          <Headline
            size={'large'}
            headline={
              i18n.t('shop-myOrder-myOrders') // My Orders
            }
          />
        </Container>
        {/*<Container*/}
        {/*  py={'8px'}*/}
        {/*  px={'20px'}*/}
        {/*>*/}
        {/*  <Paper*/}
        {/*    sx={{*/}
        {/*      display: 'flex',*/}
        {/*      flexDirection: 'row',*/}
        {/*      alignItems: 'center',*/}
        {/*      justifyContent: 'space-evenly',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <ButtonBase*/}
        {/*      sx={(theme) => ({*/}
        {/*        display: 'block',*/}
        {/*        width: '100%',*/}
        {/*        flex: 1,*/}
        {/*        paddingY: '12px',*/}
        {/*        '&::after': {*/}
        {/*          right: 0,*/}
        {/*          content: '""',*/}
        {/*          position: 'absolute',*/}
        {/*          height: 'calc(100% - 24px)',*/}
        {/*          width: '1px',*/}
        {/*          backgroundColor: theme.palette.divider,*/}
        {/*        },*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <Typography*/}
        {/*        component={'span'}*/}
        {/*        variant={'BaseS_B'}*/}
        {/*        color={'text.secondary'}*/}
        {/*        fontWeight={'500'}*/}
        {/*      >*/}
        {/*        Review*/}
        {/*      </Typography>*/}
        {/*    </ButtonBase>*/}
        {/*    <ButtonBase*/}
        {/*      sx={(theme) => ({*/}
        {/*        display: 'block',*/}
        {/*        width: '100%',*/}
        {/*        flex: 1,*/}
        {/*        paddingY: '12px',*/}
        {/*        '&::after': {*/}
        {/*          right: 0,*/}
        {/*          content: '""',*/}
        {/*          position: 'absolute',*/}
        {/*          height: 'calc(100% - 24px)',*/}
        {/*          width: '1px',*/}
        {/*          backgroundColor: theme.palette.divider,*/}
        {/*        },*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <Typography*/}
        {/*        component={'span'}*/}
        {/*        variant={'BaseS_B'}*/}
        {/*        color={'text.secondary'}*/}
        {/*        fontWeight={'500'}*/}
        {/*      >*/}
        {/*        QnA*/}
        {/*      </Typography>*/}
        {/*    </ButtonBase>*/}
        {/*    <ButtonBase*/}
        {/*      sx={{*/}
        {/*        display: 'flex',*/}
        {/*        alignItems: 'center',*/}
        {/*        justifyContent: 'center',*/}
        {/*        width: '100%',*/}
        {/*        flex: 1,*/}
        {/*        paddingY: '12px',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Typography*/}
        {/*        component={'span'}*/}
        {/*        variant={'BaseS_B'}*/}
        {/*        color={'text.secondary'}*/}
        {/*        fontWeight={'500'}*/}
        {/*      >*/}
        {/*        My*/}
        {/*      </Typography>*/}
        {/*    </ButtonBase>*/}
        {/*  </Paper>*/}
        {/*</Container>*/}
        <Container>
          <Area
            title={
              i18n.t('shop-myOrder-orderHistory') // Order History
            }
            containerProps={{
              py: '8px',
              px: '20px',
            }}
          >
            {orderHistories.map((orderHistory, idx) => (
              <OrderHistoryItem key={idx} data={orderHistory} />
            ))}
            {hasNextPage && (
              <Container py={'8px'}>
                <Button
                  fullWidth
                  size={'small'}
                  color={'fill'}
                  variant={'contained'}
                  onClick={() => next()}
                >
                  {
                    i18n.t('shop-myOrder-moreProducts') // More Products
                  }
                </Button>
              </Container>
            )}
          </Area>
        </Container>
      </Container>
    </GradientPage>
  );
}
