import { ColorTypography, PaletteColorVariants, SystemColorOptions } from '@mui/material/styles';
import { theme } from '~/theme';

export type Inherit = 'inherit';
export type ColorNames = keyof PaletteColorVariants;
export type Transparent = 'transparent';
export type White = 'white';
export type Black = 'black';
export type Fill = 'fill';
export type Paper = 'paper';
export type Default = 'default';
export type SystemColors = keyof SystemColorOptions;
export type TypographyColorTrails = `${keyof ColorTypography}`;
export type TypographyColors = `typography.${TypographyColorTrails}`;
export type PaletteColors = ColorNames | SystemColors;
export type MajorColors = `${ColorNames}.${'main' | 'light' | 'contrastText'}`;

export type AllPredefinedColors =
  | ColorNames
  | Fill
  | Paper
  | Default
  | SystemColors
  | MajorColors
  | TypographyColors
  | Inherit
  | Transparent
  | White
  | Black;

export function isPaper(color: string): color is Paper {
  return color === 'paper';
}

export function isTransparent(color: string): color is Transparent {
  return color === 'transparent';
}

export function isPaletteColor(color: string): color is PaletteColors {
  return isSystemColor(color) || isColorName(color);
}

export function isSystemColor(color: string): color is SystemColors {
  return color in theme.palette.system;
}

export function isTypographyColors(color: string): color is TypographyColors {
  const colorCode = color.split('.').at(-1);
  return !!colorCode && colorCode in theme.palette.typography;
}

export function isMajorColors(color: string): color is TypographyColors {
  const colorCodes = color.split('.');
  const name = colorCodes[0] as ColorNames;
  const type = colorCodes.at(-1);
  return !!name && !!type && name in theme.palette && type in theme.palette[name];
}

export function isFill(color: string): color is Fill {
  return color === 'fill';
}

export function isDefault(color: string): color is Default {
  return color === 'default';
}

export function isInherit(color: string): color is Inherit {
  return color === 'inherit';
}

export function isColorName(color: string): color is ColorNames {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'error':
    case 'warning':
    case 'info':
    case 'success':
      return true;
    default:
      return false;
  }
}
