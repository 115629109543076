export default function Chat(props: { width?: string; height?: string }) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 16.9183C47.5967 17.1032 46.9162 17.3048 45.9585 17.5232L45.3536 17.6744C45.236 17.708 45.026 17.7248 44.7235 17.7248C44.3539 17.7248 43.9758 17.6744 43.5894 17.5736C43.2197 17.4728 42.9005 17.3216 42.6317 17.12C41.4555 16.1958 40.8506 14.7424 40.817 12.7598V9.65977L38.952 9.83619V7.24024L40.817 7.03862V3.28332L43.9674 2.85486V6.66057L48 6.1817V7.6687C48 8.03835 47.874 8.35759 47.6219 8.62643C47.3867 8.89526 47.0842 9.04649 46.7146 9.08009L43.9674 9.35733V12.3565C43.9674 12.8438 44.043 13.2891 44.1943 13.6923C44.3455 14.0788 44.5555 14.3728 44.8243 14.5744C44.9084 14.6248 45.026 14.65 45.1772 14.65C45.446 14.65 45.7989 14.5996 46.2357 14.4988C46.6894 14.3812 47.0758 14.272 47.3951 14.1712L48 16.9183Z"
        fill={'currentColor'}
      />
      <path
        d="M31.9515 12.8858C30.5737 13.1546 29.8848 13.6167 29.8848 14.272C29.8848 14.3728 29.8932 14.4568 29.91 14.524C30.0948 15.0617 30.5317 15.3305 31.2206 15.3305C31.8759 15.3305 32.6067 15.1121 33.4133 14.6752C33.8165 14.4568 34.2954 14.1628 34.8499 13.7931V12.6338C33.8921 12.6338 32.926 12.7178 31.9515 12.8858ZM27.4905 12.7346C27.8601 12.0289 28.3306 11.5248 28.9019 11.2224C29.4731 10.9199 30.2292 10.6679 31.1702 10.4663C31.7078 10.3319 32.3043 10.2647 32.9596 10.2647C33.5309 10.2647 34.1358 10.3067 34.7742 10.3907C34.6566 9.65137 34.4466 9.0885 34.1442 8.70204C33.8417 8.31559 33.346 8.15597 32.6572 8.22318C32.2035 8.27359 31.7834 8.3996 31.397 8.60123C31.0273 8.78605 30.7081 8.97088 30.4393 9.1557C30.1872 9.32373 30.0108 9.44134 29.91 9.50855C29.6748 9.62617 29.4479 9.68498 29.2295 9.68498C28.8767 9.68498 28.5742 9.55896 28.3222 9.30692L27.4905 8.47521C27.6249 8.29039 27.9105 7.99635 28.3474 7.5931C28.801 7.18984 29.3975 6.78659 30.1368 6.38333C30.8929 5.98008 31.733 5.70284 32.6572 5.55162C32.9932 5.50122 33.3124 5.47601 33.6149 5.47601C34.9927 5.47601 36.0428 6.01369 36.7653 7.08903C37.5046 8.16437 37.8743 9.48335 37.8743 11.046V17.6996L34.8499 17.7248V16.2631C34.5642 16.7335 34.203 17.0864 33.7661 17.3216C33.3629 17.54 32.9932 17.6828 32.6572 17.7501C32.3379 17.8341 31.8759 17.8761 31.271 17.8761C30.5485 17.8761 29.8932 17.8005 29.3051 17.6492C28.7338 17.498 28.3138 17.2796 28.0449 16.9939C27.7593 16.6747 27.5241 16.2967 27.3392 15.8598C27.1544 15.4061 27.062 14.9357 27.062 14.4484C27.062 13.8603 27.2048 13.2891 27.4905 12.7346Z"
        fill={'currentColor'}
      />
      <path
        d="M21.149 5.47598C21.4514 5.42558 21.7455 5.40037 22.0311 5.40037C23.3753 5.40037 24.3918 5.90444 25.0807 6.91257C25.7696 7.92071 26.1141 9.3909 26.1141 11.3232V15.5825C26.1141 16.1706 25.904 16.6747 25.484 17.0947C25.0639 17.5148 24.5599 17.7248 23.9718 17.7248H22.636V11.5752C22.636 10.6343 22.4512 9.91178 22.0815 9.40771C21.7287 8.90364 21.2414 8.65161 20.6197 8.65161C19.9812 8.65161 19.3932 8.89524 18.8555 9.3825C18.3178 9.86977 17.9818 10.5167 17.8474 11.3232V17.7248H14.9238V3.28329L17.8474 2.70361V7.56786C18.6203 6.40851 19.7208 5.71121 21.149 5.47598Z"
        fill={'currentColor'}
      />
      <path
        d="M13.7358 13.4655C13.1142 14.9609 12.1984 16.095 10.9887 16.8679C9.7789 17.6408 8.39272 18.0273 6.83011 18.0273C5.51954 18.0273 4.34338 17.7416 3.30164 17.1703C2.2767 16.6159 1.4702 15.7758 0.882118 14.65C0.294039 13.5243 0 12.1465 0 10.5167C0 8.87004 0.294039 7.44185 0.882118 6.23209C1.4702 5.02232 2.2767 4.073 3.30164 3.38411C4.34338 2.67841 5.51954 2.23315 6.83011 2.04833C7.23337 1.99792 7.61982 1.97272 7.98947 1.97272C9.28324 1.97272 10.4258 2.28356 11.4171 2.90524C12.4253 3.52692 13.1982 4.45945 13.7358 5.70281L10.5098 7.66868C10.2746 6.82856 9.86292 6.20688 9.27484 5.80363C8.70356 5.38357 8.04827 5.17354 7.30898 5.17354C6.16642 5.17354 5.2255 5.6104 4.4862 6.48412C3.7469 7.34103 3.37725 8.6012 3.37725 10.2646C3.37725 11.7936 3.68809 12.953 4.30978 13.7427C4.93146 14.5156 5.73797 14.902 6.7293 14.902C7.36778 14.902 7.98107 14.7592 8.56914 14.4736C9.15722 14.1711 9.62769 13.7343 9.98053 13.163C10.115 12.9446 10.2914 12.7766 10.5098 12.6589C10.745 12.5245 10.9971 12.4573 11.2659 12.4573C11.4843 12.4573 11.7028 12.5077 11.9212 12.6085L13.7358 13.4655Z"
        fill={'currentColor'}
      />
    </svg>
  );
}
