const getUserBrowser = () => {
  const ua = navigator.userAgent;
  // Patterns to identify browser
  const isSafari = /Safari/i.test(ua) && !/Chrome/i.test(ua) && !/CriOS/i.test(ua);
  const isChrome = /Chrome/i.test(ua) || /Chromium/i.test(ua) || /CriOS/i.test(ua);
  const isSamsung = /SamsungBrowser/i.test(ua);
  const isOpera = /Opera|OPR/i.test(ua);

  // Determine the browser
  if (isSafari) {
    return 'safari';
  } else if (isChrome) {
    return 'chrome';
  } else if (isSamsung) {
    return 'samsung';
  } else if (isOpera) {
    return 'opera';
  } else {
    return 'unknown';
  }
};

export default getUserBrowser;
