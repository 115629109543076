import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ErrorFallBackView from '~/components/ErrorFallBack/ErrorFallBackView.tsx';

export default function RouterErrorFallBack() {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return <ErrorFallBackView />;
}
