export function encodeToBase64(str: string) {
  const base64 = btoa(
    new TextEncoder().encode(str).reduce((data, byte) => data + String.fromCharCode(byte), '')
  );
  // Replace "+" with "-", "/" with "_", and remove padding "="
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function decodeFromBase64(encodedStr: string) {
  // Replace "-" with "+" and "_" with "/"
  let base64 = encodedStr.replace(/-/g, '+').replace(/_/g, '/');

  // Add padding "=" if necessary
  while (base64.length % 4) {
    base64 += '=';
  }

  const decodedString = atob(base64);

  // Convert the decoded string to a Uint8Array
  const bytes = new Uint8Array(decodedString.length);
  for (let i = 0; i < decodedString.length; i++) {
    bytes[i] = decodedString.charCodeAt(i);
  }

  // Convert Uint8Array back to a string
  const decoder = new TextDecoder();
  return decoder.decode(bytes);
}
