import { useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { default as MuiBox } from '@mui/material/Box';
import { default as MuiModal } from '@mui/material/Modal';
import IconButton from '~/components/IconButton';
import Typography from '~/components/Typography';
import Box from '~/components/Box';
import Stack from '~/components/Stack.tsx';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  fileUrl: string;
}

const downloadFile = async (fileUrl: string) => {
  const resp = await fetch(fileUrl);
  const blob = await resp.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  saveAs(blobUrl, fileUrl.split('token=')[1]);
};

export default function ImagePreviewer({ open, fileUrl, onClose }: Props) {
  const imageRef = useRef<HTMLImageElement>(null);
  const { i18n } = useTranslation();
  const [failDownload, setFailDownload] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const handleClickDownload = () => {
    downloadFile(fileUrl).catch((e) => {
      console.error(e);
      setFailDownload(true);
    });
  };

  const handleZoomOut = () => {
    setScale((s) => Math.max(s - 0.2, 1));
  };

  const handleZoomIn = () => {
    setScale((s) => Math.max(s + 0.2, 1));
  };

  useEffect(() => {
    setImageHeight(imageRef?.current?.height ?? 0);
  }, [imageRef?.current?.height]);

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0, 0.3)',
      }}
    >
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          color: 'white',
          '&:focus': {
            outline: `0px none`,
            borderColor: `#000000`,
          },
        }}
      >
        <IconButton
          color={'white'}
          iconProps={{
            width: '20px',
          }}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
            p: '2px',
            zIndex: 999,
            transform: 'translate(4px, -4px)',
          }}
        >
          close
        </IconButton>
        <Box
          className="image-container"
          sx={{
            width: '100vw',
            height: imageHeight ? imageHeight * scale + 'px' : 'unset',
            maxHeight: 'calc(100vh - 160px)',
            overflow: 'auto', // overflow를 auto로 설정하여 양방향 스크롤 가능하도록 수정
            position: 'relative',
            cursor: 'grab',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            touchAction: 'pan-x pan-y', // 터치 스크롤 활성화
          }}
        >
          <img
            ref={imageRef}
            loading={'lazy'}
            style={{
              maxWidth: '100vw',
              maxHeight: 'calc(100vh - 180px)',
              width: `100%`, // 100%로 설정
              height: `auto`, // 높이를 자동으로 설정하여 비율 유지
              // transform: `scale(${scale})`,  // 확대/축소를 위한 transform 사용
              transform: `scale(${scale})`,
              transformOrigin: 'top left', // 확대 기준점을 왼쪽 상단으로 설정
              pointerEvents: 'auto',
              objectFit: 'contain',
              display: 'block', // 이미지가 inline 요소로 처리되지 않도록 block 설정
              objectPosition: 'center',
            }}
            src={fileUrl}
            alt="Browser doesn't support this format, but still downloadable."
          />
        </Box>

        <Stack
          row
          gap={'4px'}
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            zIndex: 999,
          }}
        >
          <IconButton
            color={'white'}
            iconProps={{
              width: '24px',
              size: '24px',
            }}
            onClick={handleZoomIn}
          >
            zoom_in
          </IconButton>
          <IconButton
            color={'white'}
            iconProps={{
              width: '24px',
              size: '24px',
            }}
            onClick={handleZoomOut}
          >
            zoom_out
          </IconButton>
        </Stack>

        <Box
          sx={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            zIndex: 999,
          }}
        >
          {failDownload ? (
            <Typography textAlign={'right'} color={'inherit'} whiteSpace={'nowrap'}>
              {i18n.t('component-imagePreviewer-longPressSave')}
            </Typography>
          ) : (
            <IconButton
              onClick={handleClickDownload}
              iconProps={{
                color: 'white',
              }}
            >
              download
            </IconButton>
          )}
        </Box>
      </MuiBox>
    </MuiModal>
  );
}
