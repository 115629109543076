import { kintFirestore } from '~kint/firebase/firestore.ts';
import { IChattingMessage } from '~kint/models/Chatting.ts';

export default function listenChatting(
  chattingId: string,
  callback: (messageList: IChattingMessage[]) => void,
  dateFrom: Date = new Date(0)
) {
  return kintFirestore.listenCollection(callback, ['chats', chattingId, 'messages'], {
    orderBy: ['date', 'asc'],
    where: ['date', '>', dateFrom],
  });
}
