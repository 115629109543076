import { useEffect, useRef } from 'react';
import router from '../router';
import useKintStore from '~kint/store';
import { pushLog } from '~kup/controllers/log.ts';
import { useLocation } from 'react-router-dom';
import useKupStore from '~kup/store';

export default function useListenRouterPath() {
  const location = useLocation();
  const { currentPage, setCurrentPage } = useKupStore();
  const { reservation, participant, team } = useKintStore((state) => ({
    reservation: state.reservation,
    participant: state.participant,
    team: state.team,
  }));

  const currentPageRef = useRef<typeof currentPage>(currentPage);

  useEffect(() => {
    const logLocation = async (
      l: typeof location,
      referrer?: {
        pathname: string;
        query?: string;
        hash?: string;
      } | null
    ) => {
      const pathname = l?.pathname;
      const hash = l?.hash || undefined;
      const query = l?.search || undefined;
      const reservationId = reservation?.id;
      const reservationAgencyCode = reservation?.agencyCode;
      const reservationDate = reservation?.date;
      const participantId = participant?.id;
      const participantName = participant?.name;
      const tourId = reservation?.productId;
      const teamId = reservation?.teamId;
      const guideId = team?.guides?.map((g) => g.id).join('/');
      const guideName = team?.guides?.map((g) => g.name).join('/');
      const referrerPathname = referrer?.pathname;
      const referrerHash = referrer?.hash;
      const referrerQuery = referrer?.query;
      pushLog(pathname, {
        hash,
        query,
        reservationId,
        reservationAgencyCode,
        reservationDate,
        participantId,
        participantName,
        tourId,
        teamId,
        guideId,
        guideName,
        referrerPathname,
        referrerHash,
        referrerQuery,
      }).catch(console.error);
      //현재페이지 남기기
      currentPageRef.current = { pathname, hash: hash, query: query };
      setCurrentPage(
        currentPageRef.current.pathname,
        currentPageRef.current.hash,
        currentPageRef.current.query
      );
    };
    logLocation(location, currentPageRef.current).catch(console.error);
    const unsub = router.subscribe((state) => {
      logLocation(state.location, currentPageRef.current).catch(console.error);
    });
    return () => {
      unsub();
    };
  }, [reservation?.id, participant?.id, team?.guides?.map((g) => g.id).join('/')]);
}
