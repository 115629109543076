import { Product } from '~kup/models/Product.ts';
import { getProduct } from '~kup/controllers/product.ts';
import { useLayoutEffect, useState } from 'react';

export default function useProduct(productId: Product['id']): {
  product: Product | null;
  loading: boolean;
  error: unknown;
} {
  const [product, setProduct] = useState<Product | null>(null);
  const [error, setError] = useState<unknown>();
  const loading = product?.id !== productId;
  useLayoutEffect(() => {
    getProduct(productId).then(setProduct).catch(setError);
  }, [productId]);
  return { product, loading, error };
}
