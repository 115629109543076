export type IconDiscoveryLogoProps = {
  width?: string;
  height?: string;
};
export default function IconDiscoveryLogo(props: IconDiscoveryLogoProps) {
  const { width = '81px', height = '20px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.6838 15.6519L70.3359 6.1084L72.9881 5.84753L75.0533 12.3475L76.8359 6.56492C76.9519 6.2171 77.1475 5.92724 77.4229 5.69536C77.7128 5.46347 78.0388 5.32579 78.4012 5.28231L80.1403 5.1084L74.9229 19.9997H72.1838L73.6838 15.6519Z"
        fill="currentColor"
      />
      <path
        d="M63.8984 6.10853L66.5071 5.56505V6.82592C66.826 6.34766 67.3042 5.92737 67.9419 5.56505C68.5796 5.20273 69.2245 4.9636 69.8767 4.84766V8.02157H69.855C69.6521 7.99259 69.5071 7.9781 69.4202 7.9781C68.855 7.9781 68.2753 8.19549 67.6811 8.63027C67.3477 8.87665 67.0724 9.14476 66.855 9.43462C66.6521 9.72447 66.5361 9.94187 66.5071 10.0868V14.4781C66.5071 14.8549 66.3695 15.1738 66.0941 15.4346C65.8332 15.71 65.5144 15.8477 65.1376 15.8477H63.8984V6.10853Z"
        fill="currentColor"
      />
      <path
        d="M60.7116 9.39174C60.5232 8.91348 60.2261 8.52217 59.8203 8.21782C59.429 7.91347 58.9363 7.7613 58.3421 7.7613C58.1537 7.7613 58.0087 7.76854 57.9073 7.78304C57.1971 7.86999 56.6392 8.09463 56.2334 8.45695C55.8421 8.80478 55.545 9.2613 55.3421 9.82652L60.7116 9.39174ZM52.8203 11.0439C52.8203 9.50768 53.2478 8.21058 54.1029 7.1526C54.958 6.08014 56.1102 5.42796 57.5594 5.19608C57.8638 5.1526 58.1681 5.13086 58.4725 5.13086C59.371 5.13086 60.1609 5.34101 60.8421 5.7613C61.5377 6.16709 62.074 6.7613 62.4508 7.54391C62.8421 8.31202 63.0377 9.21782 63.0377 10.2613C63.0377 10.5946 63.0232 10.8483 62.9942 11.0222V11.1961L55.3638 11.7178C55.4942 12.2106 55.7913 12.6743 56.2551 13.1091C56.7334 13.5294 57.1971 13.7323 57.6464 13.7178H57.7768C57.9942 13.7033 58.2261 13.6309 58.4725 13.5004C58.7334 13.37 59.045 13.1888 59.4073 12.957C59.6247 12.8265 59.8566 12.7613 60.1029 12.7613C60.3783 12.7613 60.6392 12.841 60.8855 13.0004L62.2334 13.7613L62.1247 13.9135C61.5884 14.6236 60.9218 15.1744 60.1247 15.5657C59.3276 15.957 58.4725 16.1526 57.5594 16.1526C56.6029 16.1526 55.7623 15.9425 55.0377 15.5222C54.3276 15.1019 53.7768 14.5077 53.3855 13.7396C53.0087 12.957 52.8203 12.0584 52.8203 11.0439Z"
        fill="currentColor"
      />
      <path
        d="M43.7383 6.10904L46.3905 5.89165L48.5209 12.109L50.4557 6.43512C50.5426 6.15976 50.6948 5.93512 50.9122 5.76121C51.1441 5.5873 51.405 5.48585 51.6948 5.45686L53.7166 5.30469L49.5209 15.8482H47.5426L43.7383 6.10904Z"
        fill="currentColor"
      />
      <path
        d="M38.9299 8.06552C38.2777 8.06552 37.727 8.31914 37.2777 8.82639C36.8429 9.33364 36.6255 10.0148 36.6255 10.8699C36.6255 11.6525 36.8067 12.2612 37.169 12.696C37.5458 13.1307 38.0241 13.3481 38.6038 13.3481C39.256 13.3481 39.7994 13.1018 40.2342 12.609C40.669 12.1162 40.8864 11.4351 40.8864 10.5655C40.8864 9.76842 40.698 9.15248 40.3212 8.71769C39.9589 8.28291 39.4951 8.06552 38.9299 8.06552ZM34.082 11.0438C34.082 10.0003 34.2777 9.08001 34.669 8.28291C35.0748 7.48581 35.6255 6.84813 36.3212 6.36986C37.0168 5.8916 37.8067 5.58725 38.6907 5.45682C38.9806 5.41334 39.2487 5.3916 39.4951 5.3916C40.3357 5.3916 41.0893 5.58001 41.756 5.95682C42.4371 6.33363 42.9733 6.8916 43.3647 7.63073C43.756 8.36987 43.9516 9.27567 43.9516 10.3481C43.9516 11.522 43.7125 12.5365 43.2342 13.3916C42.7704 14.2322 42.1328 14.8699 41.3212 15.3047C40.5241 15.7539 39.6473 15.9786 38.6907 15.9786C37.8067 15.9786 37.0168 15.7829 36.3212 15.3916C35.6255 15.0148 35.0748 14.4568 34.669 13.7177C34.2777 12.9786 34.082 12.0873 34.082 11.0438Z"
        fill="currentColor"
      />
      <path
        d="M29.8935 7.97839C29.2124 7.97839 28.6399 8.23926 28.1761 8.761C27.7268 9.26825 27.5022 9.95666 27.5022 10.8262C27.5022 11.6233 27.6906 12.2393 28.0674 12.6741C28.4442 13.1088 28.9225 13.3262 29.5022 13.3262C29.7921 13.3262 30.0892 13.2683 30.3935 13.1523C30.7124 13.0219 30.9877 12.8407 31.2196 12.6088C31.4515 12.3769 31.7413 12.261 32.0892 12.261C32.2196 12.261 32.35 12.2827 32.4805 12.3262L34.1979 12.9784C33.7486 13.9494 33.1254 14.6885 32.3283 15.1958C31.5312 15.7175 30.6182 15.9784 29.5892 15.9784C28.7051 15.9784 27.9153 15.7828 27.2196 15.3914C26.524 15.0146 25.9732 14.4567 25.5674 13.7175C25.1761 12.9784 24.9805 12.0871 24.9805 11.0436C24.9805 9.49289 25.408 8.21028 26.2631 7.19578C27.1182 6.18129 28.2269 5.5581 29.5892 5.32621C29.879 5.26824 30.1906 5.23926 30.524 5.23926C31.3356 5.23926 32.0529 5.43491 32.6761 5.82622C33.3138 6.21752 33.8211 6.79723 34.1979 7.56535L31.6544 8.93492C31.495 8.61607 31.2486 8.37694 30.9153 8.21752C30.5964 8.0581 30.2558 7.97839 29.8935 7.97839Z"
        fill="currentColor"
      />
      <path
        d="M19.1041 13.2395C19.5824 13.4279 20.0896 13.5221 20.6259 13.5221C20.9592 13.5221 21.2418 13.4786 21.4737 13.3917C21.7201 13.3047 21.8722 13.1743 21.9302 13.0004C21.9737 12.8699 21.9954 12.754 21.9954 12.6525C21.9954 12.4206 21.9157 12.2395 21.7563 12.109C21.5969 11.9786 21.4157 11.8917 21.2128 11.8482C21.1548 11.8192 20.9592 11.7757 20.6259 11.7177C20.307 11.6598 19.9447 11.5801 19.5389 11.4786C18.9592 11.3482 18.4519 11.1308 18.0172 10.8264C17.6693 10.609 17.4085 10.3192 17.2345 9.95687C17.0751 9.58006 16.9954 9.17426 16.9954 8.73948C16.9954 8.14527 17.1403 7.61629 17.4302 7.15252C17.7925 6.5873 18.2708 6.13802 18.865 5.80469C19.4737 5.47135 20.2056 5.30469 21.0606 5.30469C21.7563 5.30469 22.4085 5.45686 23.0172 5.76121C23.6404 6.05107 24.1693 6.41338 24.6041 6.84817L23.0606 8.56556C22.7273 8.21773 22.3505 7.96411 21.9302 7.80469C21.5099 7.63078 21.1114 7.54382 20.7346 7.54382C20.3432 7.54382 20.0389 7.61629 19.8215 7.76121C19.6621 7.86266 19.5389 7.9786 19.4519 8.10904C19.365 8.22498 19.3215 8.34817 19.3215 8.47861C19.3215 8.62353 19.3722 8.74672 19.4737 8.84817C19.6041 8.97861 19.7635 9.08006 19.9519 9.15252C20.1403 9.21049 20.4592 9.30469 20.9085 9.43513C21.2708 9.52208 21.7346 9.65252 22.2998 9.82643C23.1259 10.1453 23.7128 10.5221 24.0606 10.9569C24.4085 11.3772 24.5824 11.8699 24.5824 12.4351C24.5824 12.9134 24.4664 13.4279 24.2346 13.9786C24.0172 14.5293 23.6114 15.0148 23.0172 15.4351C22.4375 15.8554 21.6476 16.0656 20.6476 16.0656C19.7635 16.0656 19.0027 15.9134 18.365 15.609C17.7418 15.3047 17.2708 15.0293 16.9519 14.783C16.6331 14.5366 16.4447 14.3917 16.3867 14.3482L16.8215 13.5656C16.9374 13.3482 17.1041 13.1815 17.3215 13.0656C17.5389 12.9351 17.7708 12.8699 18.0172 12.8699C18.249 12.8699 18.4592 12.9206 18.6476 13.0221L19.1041 13.2395Z"
        fill="currentColor"
      />
      <path
        d="M15.1734 5.52175V14.3478C15.1734 14.7536 15.0285 15.1015 14.7386 15.3913C14.4488 15.6957 14.0937 15.8478 13.6734 15.8478H12.3691V5.93479L15.1734 5.52175ZM12.043 2.17392C12.043 1.62319 12.2531 1.12319 12.6734 0.673914C13.0937 0.224638 13.572 0 14.1082 0C14.514 0 14.8473 0.137681 15.1082 0.413044C15.3836 0.688407 15.5212 1.04348 15.5212 1.47826C15.5212 2.04348 15.3111 2.54348 14.8908 2.97827C14.4705 3.41305 13.985 3.63044 13.4343 3.63044C13.043 3.63044 12.7096 3.49276 12.4343 3.2174C12.1734 2.94203 12.043 2.59421 12.043 2.17392Z"
        fill="currentColor"
      />
      <path
        d="M4.50001 13.2387C5.18117 13.2097 5.77537 12.9996 6.28262 12.6083C6.80436 12.2025 7.20291 11.6663 7.47827 10.9996C7.75363 10.3329 7.89132 9.60828 7.89132 8.82567C7.89132 7.70973 7.63045 6.80393 7.10871 6.10828C6.58697 5.39813 5.88407 5.04306 5.00001 5.04306C4.89856 5.04306 4.73189 5.05755 4.50001 5.08654L2.80435 5.36914V13.3039L4.50001 13.2387ZM0 3.39088L4.50001 2.47784C4.83334 2.40537 5.2174 2.36914 5.65218 2.36914C6.65218 2.36914 7.57247 2.63001 8.41306 3.15175C9.26813 3.67349 9.94204 4.39813 10.4348 5.32567C10.942 6.2532 11.1957 7.30393 11.1957 8.47785C11.1957 9.81118 10.8841 11.0431 10.2609 12.1735C9.6377 13.3039 8.81161 14.2025 7.78262 14.8692C6.75363 15.5213 5.65943 15.8474 4.50001 15.8474H1.52174C1.10145 15.8474 0.739132 15.6952 0.434783 15.3909C0.144928 15.101 0 14.746 0 14.3257V3.39088Z"
        fill="currentColor"
      />
    </svg>
  );
}
