import { useTranslation } from 'react-i18next';
import Box from '~/components/Box.tsx';
import Typography from '~/components/Typography.tsx';
import Badge from '~/components/Badge.tsx';
import usePromotionTimeoutCutOff from '~/hooks/useTimeRemains.ts';
import useKintStore from '~kint/store';

export default function PromotionTimeLimitBanner() {
  const { reservation } = useKintStore((state) => ({ reservation: state.reservation }));
  const reservationDate = reservation?.date;
  const promotionUntil = new Date(new Date(`${reservationDate}T23:59+09:00`).getTime() + 60 * 1000);
  const { remains } = usePromotionTimeoutCutOff({ targetDate: promotionUntil });
  const { i18n } = useTranslation();

  if (!reservationDate) return null;

  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 900,
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.error.light,
          py: '8px',
          gap: '8px',
        })}
      >
        <Typography variant={'BaseS_B'} color={'error'}>
          {
            i18n.t('shop-product-availableDuringTour') // Available only during tour date!
          }
        </Typography>

        <Badge color={'error'}>{remains.text}</Badge>
      </Box>
    </Box>
  );
}
