import { kintFunctions } from '~kint/firebase/functions';
import {
  IChatting,
  IChattingParams,
  IChattingParticipant,
  IChattingTour,
} from '~kint/models/Chatting';
import Reservation from '~kint/models/Reservation.ts';

export default async function recallCSChatting(
  participant: IChattingParticipant,
  reservation: Reservation,
  service: 'KUP' | 'CS'
): Promise<IChatting | null> {
  if (!participant) {
    return null;
  }

  const category = service === 'KUP' ? 'CS-KUP' : 'CS';

  const chattingParams = parseChatMetadata(participant, category, {
    team: reservation.teamId,
    date: reservation.date,
    productId: reservation.productId,
  });

  return await kintFunctions.call<IChatting>('recallComprehensiveTourChat', chattingParams);
}

const parseChatMetadata = (
  participant: IChattingParticipant,
  category: string = 'CS',
  tour: IChattingTour
): IChattingParams => {
  const { productId, date, team } = tour;
  const cId = `${category}:${productId}:${date}:${team}:${participant.id}`;
  const title = `${participant.name}:SUPPORT`;

  return {
    category,
    participant,
    cId,
    title,
    tour,
  };
};
