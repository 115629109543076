import { styled } from '@mui/material/styles';
import {
  default as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';

// eslint-disable-next-line react-refresh/only-export-components
export * from '@mui/material/Typography';

export type TypographyProps = {
  strike?: boolean;
} & MuiTypographyProps;

const CustomTypography = styled((props: TypographyProps) => <MuiTypography {...props} />, {
  shouldForwardProp: (prop) => prop !== 'strike',
})(({ strike }) => {
  //
  // const checkColorValue = (color: AllPredefinedColors) => {
  //   const tailingColor = color.split('.').at(-1) as TypographyColorTrails;
  //   return isTypographyColors(color) && tailingColor ? theme.palette.typography[tailingColor] : isFill(color) ? theme.palette.fill.primary : color
  // };
  // const checkColorPalette = (color: AllPredefinedColors) => isColorName(color) ? theme.palette[color] : isSystemColor(color) ? theme.palette.system[color] : null;
  // const colorVal = color ? checkColorPalette(color)?.main ?? checkColorValue(color) : checkColorValue('typography.primary');
  //
  return {
    ...(strike && {
      textDecoration: 'line-through',
      // color: colorVal
    }),
  };
});

export default CustomTypography;
