import { FirebaseApp } from 'firebase/app';
import {
  FirebaseStorage,
  ref,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

export class FStorage {
  app: FirebaseApp;
  storage: FirebaseStorage;

  constructor(app: FirebaseApp) {
    this.app = app;
    this.storage = getStorage(app);
  }

  async uploadFile(
    path: string,
    file: Blob,
    onProgress: (progress: number, transferredBytes: number, totalBytes: number) => void
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const storageRef = ref(this.storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress, snapshot.bytesTransferred, snapshot.totalBytes);
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadUrl);
        }
      );
    });
  }
}
