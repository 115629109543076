export type LogoPencilProps = {
  width?: string;
  height?: string;
};
export default function IconPencil(props: LogoPencilProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0317 10.0776L29.9224 17.9683L13.4834 34.4074L5.59263 26.5166L22.0317 10.0776Z"
        fill="#FFCD00"
      />
      <path
        d="M27.2922 4.81711C28.3816 3.72763 30.148 3.72763 31.2375 4.81711L35.1829 8.76248C36.2724 9.85196 36.2724 11.6184 35.1829 12.7078L32.5526 15.3381L24.6619 7.44736L27.2922 4.81711Z"
        fill="#EF4444"
      />
      <path
        d="M23.3468 8.76248L31.2375 16.6532L29.9224 17.9683L22.0317 10.0776L23.3468 8.76248Z"
        fill="#E6E8E8"
      />
      <path
        d="M24.6619 7.44736L32.5526 15.3381L31.2375 16.6532L23.3468 8.76248L24.6619 7.44736Z"
        fill="#D4D6D6"
      />
      <path
        d="M5.11043 35.9838L13.4834 34.4074L5.59263 26.5166L4.01618 34.8896C3.89544 35.5309 4.46913 36.1046 5.11043 35.9838Z"
        fill="#FFE4DD"
      />
      <path
        d="M5.11043 35.9838L8.29877 35.3835L4.61643 31.7012L4.01618 34.8896C3.89544 35.5309 4.46913 36.1046 5.11043 35.9838Z"
        fill="#3C475C"
      />
    </svg>
  );
}
