import { setCookie } from '~common/api/cookies.ts';

interface AuthBase {
  accessToken: string;
  role: string;
  name: string;
  locale: string;
}

export default class Auth implements AuthBase {
  accessToken: string;
  role: string;
  name: string;
  locale: string;

  constructor(raw: AuthBase) {
    this.accessToken = raw.accessToken;
    this.name = raw.name;
    this.role = raw.role ?? 'CUSTOMER';
    this.locale = raw.locale ?? 'en-US';
    setCookie('Authorization', this.accessToken);
    setCookie('Kup-Locale', this.locale);
  }

  changeLocale(locale: string): Auth {
    return new Auth({ accessToken: this.accessToken, role: this.role, name: this.name, locale });
  }
}
