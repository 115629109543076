export type IconTranslateProps = {
  width?: string;
  height?: string;
  color?: string;
};
export default function IconTranslate(props: IconTranslateProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82203 3.87538C7.30881 2.70821 5.65288 2.7082 5.13966 3.87538L1.07826 13.1119C0.873192 13.5783 1.08502 14.1226 1.5514 14.3277C2.01777 14.5328 2.56209 14.3209 2.76716 13.8545L4.19327 10.6112C4.27621 10.6357 4.36402 10.6489 4.45489 10.6489H8.50656C8.59751 10.6489 8.68539 10.6357 8.76839 10.6112L10.1945 13.8545C10.3996 14.3209 10.9439 14.5328 11.4103 14.3277C11.8767 14.1226 12.0885 13.5783 11.8834 13.1119L7.82203 3.87538ZM7.97371 8.80391L6.48084 5.40879L4.98797 8.80391H7.97371Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2385 9.36291C18.2385 8.85344 17.8255 8.44043 17.316 8.44043C16.8065 8.44043 16.3935 8.85344 16.3935 9.36291V10.584H12.5543C12.0448 10.584 11.6318 10.997 11.6318 11.5065C11.6318 12.0159 12.0448 12.429 12.5543 12.429H13.4254C13.5219 14.4598 14.5102 16.0481 15.7553 17.2287C14.7355 17.7988 13.6988 18.1669 12.9384 18.3459C12.4425 18.4626 12.135 18.9592 12.2517 19.4551C12.3684 19.951 12.865 20.2585 13.361 20.1418C14.4276 19.8908 15.9195 19.3411 17.3161 18.4429C18.7127 19.3411 20.2046 19.8908 21.2712 20.1418C21.7672 20.2585 22.2638 19.951 22.3805 19.4551C22.4972 18.9592 22.1897 18.4626 21.6938 18.3459C20.9334 18.167 19.8967 17.7988 18.8769 17.2287C20.122 16.0481 21.1103 14.4598 21.2068 12.429H22.0776C22.587 12.429 23 12.0159 23 11.5065C23 10.997 22.587 10.584 22.0776 10.584H18.2385V9.36291ZM17.3161 16.1525C16.187 15.1868 15.3753 13.959 15.2734 12.429H19.3588C19.2569 13.959 18.4452 15.1868 17.3161 16.1525Z"
        fill="currentColor"
      />
    </svg>
  );
}
