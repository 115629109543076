import DOMPurify from 'dompurify';
import Box from '~/components/Box';

export default function HTML({ children }: { children: string }) {
  const sanitized = DOMPurify.sanitize(children);
  return (
    <Box
      sx={(theme) => ({
        '& *': {
          whiteSpace: 'pre-wrap',
        },
        '& h1, h2, h3, h4, h5, h6': {
          display: 'block',
          marginBottom: '16px',
          marginTop: '16px',
        },
        '& h1 ': {
          fontSize: '22px',
        },
        '& h2': {
          fontSize: '18px',
        },
        '& h3, h4, h5, h6': {
          fontSize: '16px',
        },
        '& img': {
          maxWidth: '100%',
          objectFit: 'contain',
          borderRadius: '8px',
          marginTop: '8px',
          marginBottom: '8px',
        },
        '& p': {
          display: 'block',
          fontSize: '14px',
          lineHeight: 1.9,
          whiteSpace: 'pre-wrap',
          marginBottom: '8px',
          color: theme.palette.typography.secondary,
        },
      })}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitized,
        }}
      />
    </Box>
  );
}
