import { useLayoutEffect, useState } from 'react';
import useKupStore from '~kup/store';
import {
  requestTokenForKup,
  getTokenForKup,
  isPermissionGranted,
  isSupported,
} from '~kup/controllers/notification.ts';
import { startNotification, stopNotification } from '~kup/controllers/notification.ts';

export default function useKupNotificationToken() {
  const [isNotificationSupported, setIsNotificationSupported] = useState<boolean>(false);
  const [isNotificationGranted, setIsNotificationGranted] = useState<boolean>(false);
  const {
    setIsNotificationPermissionRequested,
    isNotificationPermissionRequested,
    stopRequestNotificationPermission,
    setStopRequestNotificationPermission,
    token,
    setToken,
    isNotificationDisabled,
    setIsNotificationDisabled,
  } = useKupStore((state) => ({
    setIsNotificationPermissionRequested: state.setIsNotificationPermissionRequested,
    isNotificationPermissionRequested: state.isNotificationPermissionRequested,
    stopRequestNotificationPermission: state.stopRequestNotificationPermission, // request skip한 시간
    setStopRequestNotificationPermission: state.setStopRequestNotificationPermission,
    isNotificationDisabled: state.isNotificationDisabled,
    setIsNotificationDisabled: state.setIsNotificationDisabled,
    token: state.token,
    setToken: state.setToken,
  }));

  const isSnoozed = stopRequestNotificationPermission > Date.now() - 12 * 60 * 60 * 1000;

  const disableRequest = () => {
    const now = Date.now();
    setStopRequestNotificationPermission(now);
    setIsNotificationPermissionRequested(true);
  };
  const disableNotification = () => {
    setIsNotificationDisabled(true);
    stopNotification().catch(console.error);
  };

  const enableNotification = () => {
    setIsNotificationDisabled(false);
    startNotification().catch(console.error);
  };

  const requestToken = () => {
    requestTokenForKup()
      .then((token) => {
        setToken(token);
      })
      .catch(console.error);
    setIsNotificationPermissionRequested(true);
  };

  useLayoutEffect(() => {
    isSupported().then(setIsNotificationSupported);

    if (isNotificationDisabled) {
      //disable 일 경우 토큰 갱신 하지 아니함
      return;
    }

    isPermissionGranted().then((isNotificationGranted) => {
      setIsNotificationGranted(isNotificationGranted);
      setIsNotificationPermissionRequested(true);
      if (isNotificationGranted) {
        getTokenForKup().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  return {
    token,
    requestToken,
    disableRequest,
    disableNotification,
    enableNotification,
    isNotificationPermissionRequested,
    isNotificationSupported,
    isNotificationGranted,
    isNotificationDisabled,
    isSnoozed,
    stopRequestNotificationPermission,
    isNeededToShowDialog:
      isNotificationSupported && !isNotificationGranted && !isSnoozed && !isNotificationDisabled,
  };
}
