import { ReactNode } from 'react';
import { default as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material/Select';
import { default as MuiMenuItem } from '@mui/material/MenuItem';
import { default as MuiIcon } from '@mui/material/Icon';

export type Menu = {
  label: ReactNode;
  value: string | number;
};
export type SelectProps = MuiSelectProps & {
  menus: Menu[];
};

export default function Select(props: SelectProps) {
  const { menus, value, onChange, placeholder, ...restProps } = props;
  return (
    <MuiSelect
      id={'order-types'}
      value={value}
      onChange={onChange}
      IconComponent={(e) => (
        <MuiIcon
          className={e.className}
          sx={{
            fontSize: '1.25rem',
            lineHeight: '100%',
          }}
        >
          expand_more
        </MuiIcon>
      )}
      inputProps={{
        placeholder,
      }}
      placeholder={placeholder}
      displayEmpty={true}
      renderValue={(value): ReactNode => {
        const asValue = value as string | number | undefined | null;
        const isNullish = asValue === undefined || asValue === null || asValue === '';
        if (isNullish) return placeholder ?? '';
        const selectedMenu = menus.find(({ value }) => value === asValue);
        return selectedMenu?.label ?? '';
      }}
      {...restProps}
    >
      {menus.map((menu) => (
        <MuiMenuItem key={menu.value} value={menu.value}>
          {menu.label}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
}

export type FakeSelectButton = {
  onClick: () => void;
  value: string;
};
