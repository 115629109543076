import { useCallback, useLayoutEffect, useState } from 'react';
import { getRelatedProducts } from '~kup/controllers/product.ts';
import { Product } from '~kup/models/Product.ts';

type UseRelatedProducts = {
  relatedProducts: Product[];
};

export default function useRelatedProducts(productId: Product['id']): UseRelatedProducts {
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);

  const callProducts = useCallback((productId: Product['id']) => {
    getRelatedProducts(productId).then((products) => {
      setRelatedProducts(products);
    });
  }, []);

  useLayoutEffect(() => {
    callProducts(productId);
  }, [productId, callProducts]);

  return { relatedProducts };
}
