import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import SnackBar, { SnackBarMessage } from '~/components/SnackBar.tsx';

interface SnackBarContextType {
  showSnackBar: (message: SnackBarMessage) => void;
}

export type SnackBarProviderProps = {
  children: ReactNode;
};

const SnackBarContext = createContext<SnackBarContextType>({ showSnackBar: () => {} });

export function SnackBarProvider(props: SnackBarProviderProps) {
  const { children } = props;
  const [snackBarMessage, setSnackBarMessage] = useState<SnackBarMessage>('');
  const [open, setOpen] = useState<boolean>(false);
  const showSnackBar = useCallback((message: SnackBarMessage) => {
    setSnackBarMessage(message);
    setOpen(true);
  }, []);
  const context = useMemo(() => ({ showSnackBar }), [showSnackBar]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackBarContext.Provider value={context}>
      {children}
      {<SnackBar open={open} message={snackBarMessage} onClose={handleClose} />}
    </SnackBarContext.Provider>
  );
}

export function useSnackBar(): SnackBarContextType {
  return useContext<SnackBarContextType>(SnackBarContext);
}
