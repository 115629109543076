import {
  default as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material/LinearProgress';
import {
  default as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material/CircularProgress';

type Linear = 'linear';
type Circular = 'circular';
type ProgressVariant = Linear | Circular;

type ProgressProps<PV extends ProgressVariant = Circular> = (PV extends Linear
  ? MuiLinearProgressProps
  : MuiCircularProgressProps) & {
  type?: PV;
};

export default function Progress<PV extends ProgressVariant>(props: ProgressProps<PV>) {
  const { type = 'circular', variant, value, ...progressProps } = props;
  if (type === 'linear') {
    return (
      <MuiLinearProgress
        {...(progressProps as MuiLinearProgressProps)}
        value={value}
        variant={value !== undefined ? 'determinate' : variant}
      />
    );
  }

  return <MuiCircularProgress {...(progressProps as MuiCircularProgressProps)} />;
}
