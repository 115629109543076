export type IconHomeProps = {
  width?: string;
  height?: string;
};
export default function IconHome(props: IconHomeProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 20.0159V9.32843C3 9.03161 3.06638 8.75036 3.19913 8.48468C3.33206 8.21918 3.51572 8.00046 3.75009 7.82852L10.875 2.48477C11.2022 2.23483 11.5762 2.10986 11.9969 2.10986C12.4177 2.10986 12.7937 2.23483 13.125 2.48477L20.2499 7.82852C20.4843 8.00046 20.6679 8.21918 20.8009 8.48468C20.9336 8.75036 21 9.03161 21 9.32843V20.0159C21 20.5316 20.8164 20.973 20.4493 21.3403C20.082 21.7075 19.6405 21.891 19.1249 21.891H15.1874C14.9219 21.891 14.6992 21.8012 14.5194 21.6216C14.3398 21.4418 14.25 21.2191 14.25 20.9536V14.9534C14.25 14.6879 14.1602 14.4653 13.9806 14.2855C13.8007 14.1058 13.5781 14.016 13.3126 14.016H10.6874C10.4219 14.016 10.1992 14.1058 10.0194 14.2855C9.83981 14.4653 9.75 14.6879 9.75 14.9534V20.9536C9.75 21.2191 9.66019 21.4418 9.48056 21.6216C9.30075 21.8012 9.07809 21.891 8.81259 21.891H4.87509C4.35947 21.891 3.918 21.7075 3.55069 21.3403C3.18356 20.973 3 20.5316 3 20.0159Z"
        fill="currentColor"
      />
    </svg>
  );
}
